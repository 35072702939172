import './index.scss';
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import {IconAdd, IconClose, IconInvalid, IconPlus, IconTrash} from '../../icons';
import AwesomeDebouncePromise from 'awesome-debounce-promise';

function Seat() {
    const isMobile = useMediaQuery({maxWidth : 760});
    const isDesktop = useMediaQuery({minWidth : 1224});
    const [showAdd, setShowAdd] = useState(false)
    const [showError, setShowError] = useState(false)
    const [embed, setEmbed] = useState(0)
    const [embedEdu, setEmbedEdu] = useState(0)
    // const [token, setToken] =useState('')
    let navigate = useNavigate()
    const [items, setItems] = useState([])
    const [users, setUsers] = useState([
        {
            username: 'Rivan',
            email: '@rivanma'
        },
        {
            username: 'Reva',
            email: '@revani'
        },
        {
            username: 'Rio',
            email: '@alfath'
        },
        {
            username: 'Vinka',
            email: '@vinka'
        },
        {
            username: 'Nur',
            email: '@nuy2nuy'
        },
        {
            username: 'Giri',
            email: '@sunanGiri'
        },
        {
            username: 'Zahra',
            email: '@zahra'
        },
        {
            username: 'Tri',
            email: '@hanyaDiTri'
        },
    ])
    const [typeError, setTypeError] = useState({
        header: 'You have reach Maximum Seats',
        content: 'Please remove some user before you can add new user...'
    })
    const [maxItems, setMaxItems] = useState(2)
    const [filter, setFilter] = useState([])
    const [selected, setSelected] = useState([])
    const [search, setSearch] = useState('')
    const [loading, setLoading] = useState(false)
    const [, updateState] = useState()
    const SearchUser = (e) => {
        let filteredItems = []
        if (e) {
            filteredItems = users.filter(item => item.username.toLowerCase().includes(e.toLowerCase()) || item.email.toLowerCase().includes(e.toLowerCase))
        } else {
            filteredItems = []
        }
        setFilter(filteredItems);
        setTimeout(() => {
            setLoading(false)
        }, 2000)
    }

    const ClosePopup = () => {
        setShowAdd(false)
        setSearch('')
        setFilter([])
        let arr = items
        selected.forEach(item => arr.push(item))
        setItems(arr)
        setSelected([])
        document.getElementById('progress-bar').style.width = `${items.length/maxItems * 100}%`
    }

    const AddUser = (payload) => {
        let findInList = items.find(e => e.email === payload.email)
        if (findInList) {
            setShowError(true)
            setTypeError({
                header: 'Cannot add user',
                content: 'This User has already been added...'
            })
        } else {
            if (selected.length + items.length < maxItems) {
                let find = selected.find(e => e.email === payload.email)
                if (typeof find === 'undefined') {
                    selected.push(payload)
                    setSelected(selected)
                }
            } else {
                setShowError(true)
            }
        }
    }

    const forceUpdate = useCallback(() => updateState({}), [])

    const DeleteUser = (payload) => {
        setSelected(selected.filter(item => item.email !== payload.email))
    }

    const DeleteUserFromList = (payload) => {
        setItems(items.filter(item => item.email !== payload.email))
    } 

    const searchAPIDebounced = AwesomeDebouncePromise(SearchUser, 500);
    
    useEffect(() => {
        document.getElementById('progress-bar').style.width = `${items.length/maxItems * 100}%`
    }, [items, maxItems])

    return(
        <div className={`Main ${!isMobile ? 'tablet-overview ' : ''}${embedEdu ? 'embed' : ''}`}>
            <div className='nav'>
                <div className='header'>
                    {!embed &&
                    <span onClick={() => {
                        navigate('/Overview')
                    }}
                    className="material-icons">
                        arrow_back_ios_new
                    </span>
                    }
                    <p className='headerTitle'>
                        Manage Seat
                    </p>
                </div>
            </div>
            <div className='progress-card'>
                <div className='progress-section'>
                    <div className={`progress-border ${!isMobile ? 'tablet' : ''}`}>
                        <div id='progress-bar' className='progress-bar'></div>
                    </div>
                    <p>{items.length}/{maxItems} people</p>
                </div>
                <IconAdd onClick={() => {
                    items.length < maxItems ? setShowAdd(true) : setShowError(true)
                }}
                className='icon' />
            </div>
            <div className={`class-seats ${isDesktop ? 'desktop' : ''}`}>
                {items.map((item, i) => {
                    return (
                    <div key={i} className='seats-card'>
                        <div className='card-info'>
                            <div className='thumbnail'></div>
                            <div className='detail'>
                                <p>{item.username}</p>
                                <p>{item.email}</p>
                            </div>
                        </div>
                        <IconTrash onClick={() => {
                            DeleteUserFromList(item)
                        }}/>
                    </div>
                )})}
            </div>
            <div className={`class-scrim ${showAdd ? '' : 'hidden'}`} />
            <div className={`class-add ${showAdd ? '' : 'hidden'} ${!isMobile ? 'tablet' : ''}`}>
                <div className='header-container'>
                    {isMobile ?
                    <div className='class-hide'>
                        <span onClick={() => {
                            ClosePopup()
                        }}
                        className="material-icons">
                            expand_more
                        </span>
                    </div> :
                    <div onClick={() => {
                        ClosePopup()
                    }}
                    className='class-close'>
                        <p>
                            Add More User
                        </p>
                        <IconInvalid className='icon' />
                    </div>
                    }
                    <div className='search-bar'>
                        <span className="material-icons">
                            search
                        </span>
                        <input type={'text'} value={search} onChange={e => {
                            setLoading(true)
                            setSearch(e.target.value)
                            searchAPIDebounced(e.target.value)
                        }} placeholder='Write username or email' />
                    </div>
                    {selected.length !== 0 &&
                    <div className='container-list'>
                        <p>
                            Added
                        </p>
                        <div className='user-list'>
                            {selected.map((item, i) => {
                                return (
                                    <div key={i} className='seats-card selected'>
                                        <div className='card-info selected'>
                                            <div className='thumbnail selected'></div>
                                            <div className='detail'>
                                                <p>{item.email}</p>
                                            </div>
                                            <IconClose onClick={() => {
                                                DeleteUser(item)
                                            }}
                                            className='close-icons' />
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    }
                </div>
                {loading ?
                <div className='loader-container'>
                    <div className='loader'></div>
                </div>
                :
                <>
                {filter.length ?
                <>
                {filter.map((item, i) => {
                    return (
                    <div key={i} className='seats-card search'>
                        <div className='card-info'>
                            <div className='thumbnail'></div>
                            <div className='detail'>
                                <p>{item.username}</p>
                                <p>{item.email}</p>
                            </div>
                        </div>
                        <IconPlus onClick={() => {
                            AddUser(item)
                            forceUpdate()
                        }} className='icon'/>
                    </div>
                )})}
                </> :
                <p className='not-found'>
                    User Not Found...
                </p>
                }
                </>
                }
            </div>
            <div className={`popup-container ${showError ? '' : 'hidden'}`}>
                <div className={`class-scrim`} />
                <div className='error-popup'>
                    <IconInvalid onClick={() => {
                        setShowError(false)
                        setTypeError({
                            header: 'You have reach Maximum Seats',
                            content: 'Please remove some user before you can add new user...'
                        })
                    }}
                    className='icon' />
                    <h2>{typeError.header}</h2>
                    <p>{typeError.content}</p>
                </div>
            </div>
        </div>
    )
}

export default Seat;