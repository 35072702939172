import './index.scss';
import '../Payment/index.scss'
import { useState, useEffect } from 'react';
import {IconFree, IconBasic, IconMedium, IconPremium, IconIndividual, IconSchool, IconCustomMarker} from '../../icons';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import PLANS from '../../configs/Plans'
import { useMediaQuery } from 'react-responsive';
import instance from '../../configs/axios';
import { loadStripe } from '@stripe/stripe-js';
import queryString from 'query-string'
import jwt_decode from "jwt-decode";
import * as Airtable from 'airtable'
import { useDebounce } from 'use-debounce'
import Card from '../../components/Card';

function Checkout() {
    const stripePromise = loadStripe((process.env.NODE_ENV==='production' && !window.location.hostname.includes('192.168'))?process.env.REACT_APP_STRIPE_TOKEN:process.env.REACT_APP_STRIPE_TOKEN_DEV);
    const isMobile = useMediaQuery({ maxWidth: 760 })
    const isLandscape = useMediaQuery({ orientation: 'landscape'})
    let params = useParams()
    let location = useLocation()
    let navigate = useNavigate()
    const [token, setToken] = useState('')
    const [planDetail, setPlanDetail] = useState(PLANS[params.id])
    const [period, setPeriod] = useState()
    const [duration, setDuration] = useState()
    const [method, setMethod] = useState()
    const [scheme, setScheme] = useState()
    const [amount, setAmount] = useState(0)
    const [total, setTotal] = useState('0')
    const [customDuration, setCustomDuration] = useState(1)
    const [embed, setEmbed] = useState(false)
    const [uid, setUID] = useState('')
    const [popup, setPopup] = useState('')
    const [term, setTerm] = useState(false)
    const [newsletter, setNewsLetter] = useState(false)
    const [payload, setPayload] = useState({
        cancel_url : '',
        firebase_token : token,
        payments: '',
        plans: '',
        quantity: '',
        success_url: '',
        api_url: ''
    })
    const [loading, setLoading] = useState(false)
    const [voucher, setVoucher] = useState('')
    const [currentVoucher, setCurrentVoucher] = useState('')
    const [voucherDetail, setVoucherDetail] = useState({})
    const [errorVoucher, setErrorVoucher] = useState('')
    const [samePlan, setSamePlan] = useState(false)
    const [sameDuration, setSameDuration] = useState(false)
    const [first, setFirst] = useState(true)
    const [priceTotal, setPriceTotal] = useState('0')
    const [count, setCount] = useState(0)
    const [quantity, setQuantity] = useState(0)
    const [planDuration, setPlanDuration] = useState('')
    const [flow, setFlow] = useState('')
    const [tempAmount] = useDebounce(amount, 1500)
    const [tempVoucher] = useDebounce(voucher, 1000)
    const [firstLoading, setFirstLoading] = useState(false)
    const [close, setClose] = useState(false)

    const changeAmount = (type) => {
        if (type) {
            setAmount(+amount + 1)
        } else if (!type && amount) {
            if (planDetail.name === 'Custom Marker' && amount > 1) {
                setAmount(+amount - 1)
            } else if (planDetail.name === 'School/Institutional' && amount > 10) {
                setAmount(+amount - 1)
            }
        }
    }

    const ViewPlanTracking = (payload) => {
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: "view_item",
            ecommerce: {
                items: [
                    {
                        item_id: planDetail.id,
                        item_name: planDetail.name,
                        currency: "USD",
                        item_category: planDetail.plan,
                        item_period: planDetail.type,
                        item_list_id: "plan_list",
                        item_list_name: "Plan List",
                        price: planDetail.price,
                        price_annually: planDetail.annually_price,
                        quantity: 1
                    }
                ]
            }
        });
    }

    const CheckoutTracking = () => {
        let durationLong = 0
        switch (duration) {
            case 0:
                durationLong = 1
                break;
        
            case 1:
                durationLong = 3
                break;
        
            case 2:
                durationLong = 6
                break;
        
            case 3:
                durationLong = customDuration
                break;
        
            default:
                break;
        }
        let currentPrice = ((!scheme && !period) || scheme) ? planDetail.price : planDetail.annually_price
        let totalPrice = 0
        if (scheme && duration === 3) {
            totalPrice = (+total).toFixed(2)
        } else if (scheme && duration !== 3) {
            totalPrice = (durationLong * planDetail.price).toFixed(2)
        } else if (!scheme) {
            totalPrice = period ? planDetail.annually_price : planDetail.price
        }
        let payment_method = ''
        let payment_service = ''
        switch (method) {
            case 0:
                payment_method = 'credit card'
                payment_service = 'stripe'
                break;
        
            case 1:
                payment_method = 'bank transfer'
                payment_service = 'midtrans'
                break;
        
            case 2:
                payment_method = 'qris'
                payment_service = 'midtrans'
                break;
        
            default:
                break;
        }
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: "begin_checkout",
            ecommerce: {
                items: [
                    {
                        item_id: planDetail.id,
                        item_name: planDetail.name,
                        currency: "USD",
                        discount: Object.keys(voucherDetail).length ? voucherDetail.benefit : 0,
                        item_category: planDetail.plan,
                        item_duration: scheme ? durationLong : 1,
                        item_period: period ? "annualy" : "monthly",
                        item_list_id: "plan_list",
                        item_list_name: "Plan List",
                        price: currentPrice,
                        total: +totalPrice,
                        payment_method,
                        payment_service,
                        quantity: 1
                    }
                ]
            }
        });
    }

    const checkboxInput = (type) => {
        switch (type) {
            case 'term':
                setTerm(!term)
                break;
        
            case 'newsletter':
                setNewsLetter(!newsletter)
                break;
        
            default:
                break;
        }
    }

    const checkVoucher = async (type) => {
        try {
            setLoading(true)
            setCurrentVoucher(voucher)
            if (flow === 'redeem' && type !== 'same' && type !== 'different') {
                setTimeout(() => {
                    setFirstLoading(false)
                }, 2000)
            }
            switch (type) {
                case 'same':
                    setErrorVoucher('')
                    setLoading(false)
                    break;
            
                case 'different':
                    setErrorVoucher(`Voucher is only eligible for ${voucherDetail.quantity} months plan.`)
                    setLoading(false)
                    break;
            
                case 'retry':
                    let result = await instance.post('api/voucher/check', {
                        code_voucher: voucher,
                        firebase_token: token
                    })
                    setTimeout(() => {
                        setLoading(false)
                        setVoucherDetail(result.data)
                        setErrorVoucher('')
                        if (result.data.quantity === 0) {
                            setScheme(0)
                        } else {
                            setScheme(1)
                            setQuantity(result.data.quantity)
                            switch (result.data.quantity) {
                                case 1:
                                    setDuration(0)
                                    break;
                                    
                                case 3:
                                    setDuration(1)
                                    break;
                                    
                                case 6:
                                    setDuration(2)
                                    break;
                            
                                default:
                                    setDuration(3)
                                    setCustomDuration(result.data.quantity)
                                    break;
                            }
                        }
                    }, 1000)
                    break;
            
                default:
                    let {data} = await instance.post('api/voucher/check', {
                        code_voucher: voucher,
                        firebase_token: token
                    })
                    setTimeout(() => {
                        setLoading(false)
                        setVoucherDetail(data)
                        setErrorVoucher('')
                        if (first) {
                            if (data.quantity === 0) {
                                setScheme(0)
                            } else {
                                setScheme(1)
                                setQuantity(data.quantity)
                                switch (data.quantity) {
                                    case 1:
                                        setDuration(0)
                                        break;
                                        
                                    case 3:
                                        setDuration(1)
                                        break;
                                        
                                    case 6:
                                        setDuration(2)
                                        break;
                                
                                    default:
                                        setDuration(3)
                                        setCustomDuration(data.quantity)
                                        break;
                                }
                            }
                            setFirst(false)
                        }
                    }, 1000)
                    break;
            }
        } catch (error) {
            setTimeout(() => {
                setLoading(false)
                let errorMsg = ''
                let errorStatus = error.response.data.error_message
                if (errorStatus.includes("code_voucher")) {
                    if (errorStatus.includes("activated")) {
                        setErrorVoucher("Voucher has been activated")
                    } else {
                        setErrorVoucher("Voucher Not Found")
                    }
                } else {
                    setErrorVoucher("There something error, please refresh the page.")
                }
            }, 1000)
        }
    }

    const sendAPI = () => {
        setLoading(true)
        let {cancel_url, firebase_token, payments, plans, quantity, success_url, api_url} = payload
        if (voucher && samePlan && sameDuration) {
            instance.post(api_url, {
                cancel_url,
                firebase_token,
                payments,
                plans,
                quantity,
                success_url,
                code_voucher: voucher,
                measurement_id: 'G-0H33LJSKQY'
            }).then(async (response) => {
                CheckoutTracking()
                setLoading(false)
                if (voucherDetail.benefit === 100) {
                    navigate('/Success')
                } else {
                    if (payments === '1' || payments === '3') {
                        const stripe = await stripePromise;
                        const sessionId = response.data.id
                        const queryParams = queryString.parse(window.location.search)
                        if (window.closeButton === true) {
                            window.open(`${process.env.REACT_APP_PAYMENT_URL_EDU}/Stripe?sessionId=${sessionId}`, '_blank')
                        } else {
                            await stripe.redirectToCheckout({
                                sessionId
                            })
                        }
                    } else {
                        window.snap.pay(response.data.token,{
                            onSuccess: () => {
                                navigate(`/Success`)
                            },
                            onPending: () => {
                                navigate(`/Waiting`)
                            }
                        })
                    }
                    if (newsletter) {
                        const {REACT_APP_AIRTABLE_API_KEY, REACT_APP_AIRTABLE_BASE} = process.env
                        let base = new Airtable({apiKey: REACT_APP_AIRTABLE_API_KEY}).base(REACT_APP_AIRTABLE_BASE)
                        let {email, user_id} = jwt_decode(token)
                        base('Table 1').create([
                            {
                                "fields": {
                                    "UUID": user_id,
                                    "Notes": email,
                                    "Lang": "ID",
                                    "Name": `User-${user_id}`,
                                    "product": planDetail.name
                                }
                            }
                        ], function(err, records) {
                            if (err) {
                            return;
                            }
                            records.forEach(function (record) {
                            });
                        });
                    }
                }
            }).catch((error) => {
                setLoading(false)
                switch (error.response.data.error_message) {
                    case 'You Have Plans Educations':
                        setPopup(`Checkout failed. You already have Premium Plans!`)
                        break;
                    case 'firebase_token: This field is required.':
                        setPopup(`Checkout failed, invalid token!`)
                        break;
                    default:
                        setPopup(`Checkout failed, please try again later!`)
                        break;
                }
            })
        } else {
            instance.post(api_url, {
                cancel_url,
                firebase_token,
                payments,
                plans,
                quantity,
                success_url,
                measurement_id: 'G-0H33LJSKQY'
            }).then(async (response) => {
                CheckoutTracking()
                setLoading(false)
                if (payments === '1' || payments === '3') {
                    const stripe = await stripePromise;
                    const sessionId = response.data.id
                    const queryParams = queryString.parse(window.location.search)
                    if (window.closeButton === true) {
                        window.open(`${process.env.REACT_APP_PAYMENT_URL_EDU}/Stripe?sessionId=${sessionId}`, '_blank')
                    } else {
                        await stripe.redirectToCheckout({
                            sessionId
                        })
                    }
                } else {
                    window.snap.pay(response.data.token,{
                        onSuccess: () => {
                            navigate(`/Success`)
                        },
                        onPending: () => {
                            navigate(`/Waiting`)
                        }
                    })
                }
                if (newsletter) {
                    const {REACT_APP_AIRTABLE_API_KEY, REACT_APP_AIRTABLE_BASE} = process.env
                    let base = new Airtable({apiKey: REACT_APP_AIRTABLE_API_KEY}).base(REACT_APP_AIRTABLE_BASE)
                    let {email, user_id} = jwt_decode(token)
                    base('Table 1').create([
                        {
                            "fields": {
                                "UUID": user_id,
                                "Notes": email,
                                "Lang": "ID",
                                "Name": `User-${user_id}`,
                                "product": planDetail.name
                            }
                        }
                    ], function(err, records) {
                        if (err) {
                        return;
                        }
                        records.forEach(function (record) {
                        });
                    });
                }
            }).catch((error) => {
                setLoading(false)
                
                switch (error.response.data.error_message) {
                    case 'You Have Plans Educations':
                        setPopup(`Checkout failed. You already have Premium Plans!`)
                        break;
                    case 'firebase_token: This field is required.':
                        setPopup(`Checkout failed, invalid token!`)
                        break;
                    default:
                        setPopup(`Checkout failed, please try again later!`)
                        break;
                }
            })
        }
    }

    useEffect(() => {
        setFirst(true)
        window.dataLayer = window.dataLayer || [];
        window.scrollTo(0, 0)
        const script = document.createElement("script");
        window.parent.postMessage('hide-close', '*')
        script.src = (process.env.NODE_ENV==='production' && !window.location.hostname.includes('192.168'))?process.env.REACT_APP_MIDTRANS_SCRIPT:process.env.REACT_APP_MIDTRANS_SCRIPT_SANDBOX;
        script.async = true;
        script.setAttribute("data-client-key", (process.env.NODE_ENV==='production' && !window.location.hostname.includes('192.168'))?process.env.REACT_APP_MIDTRANS_KEY:process.env.REACT_APP_MIDTRANS_KEY_DEV)
        document.getElementById("checkout").appendChild(script)
        ViewPlanTracking()
        const queryParams = queryString.parse(window.location.search)
        if (queryParams.count && planDetail.name === 'Custom Marker') {
            setAmount(+queryParams.count)
        }
        if (queryParams.embed === 'true') {
            setEmbed(true)
        }
        if (queryParams.flow === 'redeem') {
            setFlow('redeem')
            setFirstLoading(true)
        }
        if (queryParams.voucher && queryParams.voucher !== 'undefined') {
            setVoucher(queryParams.voucher)
            checkVoucher()
        }
        // if ((queryParams.duration !== 'undefined' || queryParams.duration !== '10') && planDetail.name === 'School/Institutional') {
            // setAmount(queryParams.duration)
        if (planDetail.name === 'School/Institutional') {
            // setAmount(+queryParams.duration)
            // setAmount(10)
        }
        window.addEventListener('message', function (e) {
            if (e.data === 'checkout') {
                setClose(true)
            }
        });
    }, [])

    useEffect(() => {
        const queryParams = queryString.parse(window.location.search)
        if (queryParams.embed === 'true') {
            setEmbed(true)
        }
        if (queryParams.voucher && queryParams.voucher !== 'undefined') {
            checkVoucher()
        }
        if (queryParams.count && queryParams.count !== 'undefined' && first) {
            setCount(+queryParams.count)
            setAmount(+queryParams.count)
            setFirst(false)
        }
        if (queryParams.token) {
            setToken(queryParams.token)
            setUID(jwt_decode(queryParams.token).user_id)
            localStorage.setItem("UUID", jwt_decode(queryParams.token).user_id)
        }
        let newUrl = ''
        let durationUrl = ''
        let methodUrl = ''
        let schemeUrl = ''
        let type = ''
        let code_voucher = queryParams.voucher
        let tempPayload = {
            cancel_url : '',
            firebase_token : token,
            payments: '',
            plans: '',
            quantity: '',
            success_url: '',
            api_url: ''
        }
        if (typeof period === 'undefined' || period === 0) {
            newUrl = `${planDetail.month_id}`
            if (period === 0) {
                durationUrl = `&duration=1`
                tempPayload.quantity = '1'
                tempPayload.plans = planDetail.month_id
                tempPayload.api_url = 'api/plans/request'
                tempPayload.success_url = "https://payment.edu.assemblrworld.com" + '/Success'
            }
        } else {
            newUrl = `${planDetail.year_id}`
            durationUrl = `&duration=12`
            tempPayload.quantity = '1'
            tempPayload.plans = planDetail.year_id
            tempPayload.api_url = 'api/plans/request'
            tempPayload.success_url = "https://payment.edu.assemblrworld.com" + '/Success'
        }
        
        if (scheme === 0) {
            schemeUrl +='&scheme=subscription'
        } else if (scheme === 1) {
            schemeUrl +='&scheme=onetime'
        }

        if (method === 0) {
            methodUrl = `&method=credit_card`
            tempPayload.payments = !scheme ? '1' : '3'
        } else if (method === 1) {
            methodUrl = `&method=bank_transfer`
            tempPayload.payments = '2'
        } else if (method === 2) {
            methodUrl = `&method=qris`
            tempPayload.payments = '2'
        }

        if (planDetail.month_id === 100) {
            durationUrl = `&duration=${amount}`
            tempPayload.quantity = `${amount}`
            tempPayload.plans = +amount >= 60 ? 102 : +amount >= 30 && +amount < 60 ? 101 : planDetail.month_id
            tempPayload.api_url = 'api/plans/request'
            tempPayload.success_url = "https://payment.edu.assemblrworld.com" + `/Success`
        } else {
            if (!scheme) {
                setCustomDuration(1)
            }
            if (duration === 0) {
                durationUrl = `&duration=1`
                tempPayload.quantity = '1'
                tempPayload.plans = planDetail.month_id
                tempPayload.api_url = 'api/plans/request'
                tempPayload.success_url = "https://payment.edu.assemblrworld.com" + '/Success'
            } else if (duration === 1) {
                durationUrl = `&duration=3`
                tempPayload.quantity = '3'
                tempPayload.plans = planDetail.month_id
                tempPayload.api_url = 'api/plans/request'
                tempPayload.success_url = "https://payment.edu.assemblrworld.com" + `/Success`
            } else if (duration === 2) {
                durationUrl = `&duration=6`
                tempPayload.quantity = '6'
                tempPayload.plans = planDetail.month_id
                tempPayload.api_url = 'api/plans/request'
                tempPayload.success_url = "https://payment.edu.assemblrworld.com" + `/Success`
            } else if (duration === 3) {
                durationUrl = `&duration=${customDuration}`
                tempPayload.quantity = `${customDuration}`
                tempPayload.plans = planDetail.month_id
                tempPayload.api_url = 'api/plans/request'
                tempPayload.success_url = "https://payment.edu.assemblrworld.com" + `/Success`
            }
        }

        if (queryParams.type === 'astu') {
            window.dataLayer.push({
                open_from: "https://studio.assemblrworld.com"
            })
            type = 'astu'
            tempPayload.cancel_url = 'https://studio.assemblrworld.com/Profile?status=failed'
            tempPayload.success_url = 'https://studio.assemblrworld.com/Profile?status=success'
        } else if (queryParams.type === 'edu') {
            window.dataLayer.push({
                open_from: "https://app-edu.assemblrworld.com"
            })
            type = 'edu'
            tempPayload.cancel_url = 'https://app-edu.assemblrworld.com/Account?status=failed'
            tempPayload.success_url = 'https://app-edu.assemblrworld.com/Account?status=success'
        } else {
            type = 'default'
            tempPayload.cancel_url = 'https://payment.edu.assemblrworld.com' + `/Failed`
        }
        if (queryParams.edu) {
            newUrl += count ? `?count=${count}&embed=true&edu=true&token=${token}&type=${type}&voucher=${code_voucher}` + durationUrl + methodUrl + schemeUrl : `?embed=true&edu=true&token=${token}&type=${type}&voucher=${code_voucher}` + durationUrl + methodUrl + schemeUrl
        } else {
            newUrl += count ? `?count=${count}&embed=true&token=${token}&type=${type}&voucher=${code_voucher}` + durationUrl + methodUrl + schemeUrl : `?embed=true&token=${token}&type=${type}&voucher=${code_voucher}` + durationUrl + methodUrl + schemeUrl
        }
        if (queryParams.flow === 'redeem') {
            newUrl += '&flow=redeem'
        }
        if (planDetail.name === 'Custom Marker') {
            tempPayload.quantity = `${amount}`
            tempPayload.api_url = `api/plans/request`
        }
        window.history.pushState({}, '', `/Checkout/${newUrl}`)
        setPayload(tempPayload)
    }, [period, duration, scheme, method, customDuration, token, amount])
    
    useEffect(() => {
        switch (planDetail.name) {
            case 'Custom Marker':
                if (+amount === 1) {
                    setTotal(3)
                } else if (+amount === 5) {
                    setTotal(15)
                } else if (+amount === 10){
                    setTotal(30)
                } else {
                    setTotal(( (3) * amount))
                }
                break;
        
            default:
                if (+amount >= 10 && +amount < 30) {
                    setTotal(`${+amount * planDetail.price.tier1}`)
                } else if (+amount >= 30 && +amount < 60) {
                    setTotal(`${+amount * planDetail.price.tier2}`)
                } else if (+amount >= 60){
                    setTotal(`${+amount * planDetail.price.tier3}`)
                } else {
                    setTotal(0)
                }
                break;
        }
    }, [amount])

    useEffect(() => {
        if (planDetail.name === 'Custom Marker' && amount < 1 && !first) {
            setAmount(1)
        } else if (planDetail.name === 'School/Institutional' && amount < 10) {
            setAmount(10)
        }
        if (planDetail.plan === 'marker' && amount === voucherDetail.quantity) {
            // setAmount(voucherDetail.quantity)
            // checkVoucher('same')
        }
    }, [tempAmount])

    useEffect(() => {
        const queryParams = queryString.parse(window.location.search)
        if (!tempVoucher && (!queryParams.voucher || typeof queryParams.voucher === 'undefined') && samePlan) {
            setVoucher('')
            setErrorVoucher('')
        } else if (!samePlan) {
            setErrorVoucher('Voucher is not eligible for this plan')
        }
    }, [tempVoucher, samePlan])

    useEffect(() => {
        setTotal(`${( (planDetail.price) * customDuration) - (2 * Math.floor(customDuration / 12) * (planDetail.price))}`)
    }, [customDuration])

    useEffect(() => {
        if (params.id === '20') {
            setScheme(1)
            setDuration(0)
        }
    }, [params, location.search])

    useEffect(() => {
        if (!voucher) {
            setVoucherDetail({})
        }

        if (voucher === 'undefined') {
            setVoucher('')
        }

        if (voucher === currentVoucher && voucher !== 'undefined' && voucher) {
            checkVoucher()
        } else {
            setVoucherDetail({})
        }
    }, [voucher, currentVoucher])

    useEffect(() => {
        if (Object.keys(voucherDetail).length) {
            let planId = 0
            if (!scheme) {
                planId = (period === 0 || period === undefined) ? planDetail.month_id : planDetail.year_id
            } else {
                planId = planDetail.month_id
            }
            if (planId === voucherDetail.plans[0].id) {
                setSamePlan(true)
                setErrorVoucher('')
            } else {
                setSamePlan(false)
                setErrorVoucher('Voucher is not eligible for this plan.')
            }
            if (voucherDetail.quantity) {
                let planDuration = 0
                switch (duration) {
                    case 0:
                        planDuration = 1
                        break;
                        
                    case 1:
                        planDuration = 3
                        break;
                        
                    case 2:
                        planDuration = 6
                        break;
                
                    case 3:
                        planDuration = +customDuration
                        break;
                
                    default:
                        break;
                }
                if (planDetail.plan !== 'marker' && samePlan) {
                    if ((scheme && planDuration === voucherDetail.quantity)) {
                        setSameDuration(true)
                        setErrorVoucher('')
                        checkVoucher('same')
                    } else if (!scheme && period === 0) {
                        setSameDuration(true)
                        setErrorVoucher('')
                        checkVoucher('same')
                    } else {
                        setSameDuration(false)
                        checkVoucher('different')
                        setErrorVoucher(`Voucher is only eligible for ${voucherDetail.quantity} months plan.`)
                    }
                } else if (planDetail.plan === 'marker' && samePlan) {
                    if (amount === voucherDetail.quantity) {
                        setSameDuration(true)
                        setErrorVoucher('')
                        checkVoucher('same')
                    } else {
                        setSameDuration(false)
                        checkVoucher('different')
                        setErrorVoucher(`Voucher is only eligible for ${voucherDetail.quantity} custom marker.`)
                    }
                }
            }
        }
    }, [voucherDetail, scheme, period, duration, customDuration, samePlan])

    useEffect(() => {
        switch (duration) {
            case 0:
                (Object.keys(voucherDetail).length && samePlan && sameDuration) ? setPriceTotal((planDetail.price * 1) - ((planDetail.price * 1) * (voucherDetail.benefit / 100))) : setPriceTotal(planDetail.price * 1)
                break;
                
            case 1:
                (Object.keys(voucherDetail).length && samePlan && sameDuration) ? setPriceTotal((planDetail.price * 3) - ((planDetail.price * 3) * (voucherDetail.benefit / 100))) : setPriceTotal(planDetail.price * 3)
                break;
                
            case 2:
                (Object.keys(voucherDetail).length && samePlan && sameDuration) ? setPriceTotal((planDetail.price * 6) - ((planDetail.price * 6) * (voucherDetail.benefit / 100))) : setPriceTotal(planDetail.price * 6)
                break;
        
            default:
                (Object.keys(voucherDetail).length && samePlan && sameDuration) ? setPriceTotal((planDetail.price * customDuration) - ((planDetail.price * customDuration) * (voucherDetail.benefit / 100))) : setPriceTotal(planDetail.price * customDuration)
                break;
        }
        switch (duration) {
            case 0:
                setPlanDuration(`1 Month`)
                break;
                
            case 1:
                setPlanDuration(`3 Months`)
                break;
                
            case 2:
                setPlanDuration(`6 Months`)
                break;
        
            default:
                if (customDuration === 1) {
                    setPlanDuration(`${customDuration} Month`)
                } else {
                    !customDuration ? setPlanDuration(``) : setPlanDuration(`${customDuration} Months`)
                }
                break;
        }
    }, [duration, customDuration, samePlan, sameDuration])

    useEffect(() => {
        // let qty = quantity ? 
    }, [quantity, amount])

    return (
        <>
        {firstLoading &&
        <div className='loader-container checkout-loading'>
            <div className='loader2'></div>
        </div>
        }
        <div id='checkout' className={`Main checkout ${!isMobile ? 'tablet' : ''}${isLandscape ? ' landscape' : ''}${planDetail.id === 20 ? ' marker' : ''}`}>
            <div className='nav'>
                <div className='header checkout'>
                    {window.closeButton !== true &&
                    <>
                    {!close ? 
                    <span onClick={() => {
                        flow === 'redeem' ? navigate(`/Redeem?embed=true&token=${token}`) : window.parent.postMessage("close popup", "*")
                    }}
                    className='material-icons back'>
                        {flow === 'redeem' ? 'arrow_back_ios_new' : 'close'}
                    </span> :
                    <span onClick={() => {
                        window.parent.postMessage("close popup", "*")
                    }}
                    className='material-icons back'>
                        close
                    </span>
                    }
                    </>
                    }
                    <p className='headerTitle'>
                        Checkout
                    </p>
                </div>
            </div>
            {!isMobile &&
            <Card scheme={scheme} first={first} planId={(period === 1 && typeof period !== 'undefined') ? planDetail.year_id : planDetail.month_id} item={planDetail} quantity={quantity} voucherDetail={voucherDetail} voucher={Object.keys(voucherDetail).length} period={planDuration} subscribePeriod={period} discountBenefit={voucherDetail.benefit} checkout={true} isMobile={isMobile} error={errorVoucher} amount={amount} totalCustomMarker={total} samePlan={samePlan}/>
            }
            <div className={`contentContainer ${!isMobile ? 'tablet' : ''}`}>
                {isMobile ?
                <Card scheme={scheme} first={first} planId={(period === 1 && typeof period !== 'undefined') ? planDetail.year_id : planDetail.month_id} item={planDetail} quantity={quantity} voucherDetail={voucherDetail} voucher={Object.keys(voucherDetail).length} period={planDuration} subscribePeriod={period} discountBenefit={voucherDetail.benefit} checkout={true} isMobile={isMobile} error={errorVoucher} amount={amount} totalCustomMarker={total} samePlan={samePlan}/>:
                <div></div>
                }
                <div>
                    {/* Payment Scheme */}
                    {planDetail.name !== 'Custom Marker' &&
                    <div className={`paymentScheme ${!isMobile ? 'tablet' : ''}`}>
                        <p>
                            Your Billing Models
                        </p>
                        <div className='paymentWrapper'>
                            <div onClick={() => {
                                setScheme(0)
                                setMethod()
                                setPeriod(0)
                                setDuration()
                                }} className={`${scheme === 0 ? 'active' : ''}`}>
                                <p>
                                    <input type="radio" readOnly checked={scheme === 0}/> Subscription
                                </p>
                                <p className='desc'>
                                    Pay monthly or yearly depending on the period you choose.
                                </p>
                            </div>
                            <div onClick={() => {
                                setScheme(1)
                                setMethod()
                                setPeriod()
                                setDuration(0)
                                }} className={`${scheme === 1 ? 'active' : ''}`}>
                                <p>
                                    <input type="radio" readOnly checked={scheme === 1}/> One-Time
                                </p>
                                <p className='desc'>
                                    Pay only once for the duration you choose.
                                </p>
                            </div>
                        </div>
                    </div>
                    }
                    {/* Duration / Amount / Seats */}
                    {planDetail.name === 'School/Institutional' || planDetail.name === 'Custom Marker'?
                    <div className={`paymentScheme ${!isMobile ? 'tablet' : ''}`}>
                        {planDetail.name !== 'Custom Marker' ?
                        <p>
                            Seats
                        </p> :
                        <p>
                            Amount
                        </p>
                        }
                        <div className='paymentWrapper seats'>
                            <div className={`period seats ${period === 0 ? 'active' : ''}`}>
                                <span onClick={() => changeAmount(0)} className="material-icons">
                                    remove
                                </span>
                                <input value={amount} placeholder='0' onChange={e => setAmount(e.target.value)} min={planDetail.name === 'Custom Marker' ? 1 : 10} type="number" name="amount" />
                                <span onClick={() => changeAmount(1)} className="material-icons">
                                    add
                                </span>
                                {planDetail.name !== 'Custom Marker' ?
                                <>
                                    <p>
                                        Seats
                                    </p>
                                    <div>
                                        {amount < 10 ?
                                        <p>
                                            0 USD
                                        </p> :
                                        <p>
                                            {total} USD
                                        </p>
                                        }
                                        <p className='desc'>
                                            / year
                                        </p>
                                    </div>
                                </> :
                                <div>
                                    <p>
                                        ${total}
                                    </p>
                                </div>
                                }
                            </div>
                        </div>
                        {planDetail.name !== 'Custom Marker' &&
                        <>
                            <div className='prices'>
                                <p className={`${amount >= 10 && amount < 30 ? 'active' : ''}`}>
                                    10 - 29 seats
                                </p>
                                <p className={`${amount >= 10 && amount < 30 ? 'active' : ''}`}>
                                    ${planDetail.price.tier1} / year / seats
                                </p>
                            </div>
                            <div className='prices'>
                                <p className={`${amount >= 30 && amount < 60 ? 'active' : ''}`}>
                                    30 - 59 seats
                                </p>
                                <p className={`${amount >= 30 && amount < 60 ? 'active' : ''}`}>
                                    ${planDetail.price.tier2} / year / seats
                                </p>
                            </div>
                            <div className='prices'>
                                <p className={`${amount >= 60 ? 'active' : ''}`}>
                                    &gt; 60 seats
                                </p>
                                <p className={`${amount >= 60 ? 'active' : ''}`}>
                                    ${planDetail.price.tier3} / year / seats
                                </p>
                            </div>
                        </>
                        }
                    </div> :
                    <div className={`paymentScheme ${!isMobile ? 'tablet' : ''}`}>
                        { scheme === 0 || typeof scheme === 'undefined' ?
                        <>
                            <p>
                                Your Subscription Period
                            </p>
                            <div className='paymentWrapper'>
                                { typeof scheme !== 'undefined' ?
                                <>
                                    <div onClick={() => setPeriod(0) } className={`period ${period === 0 ? 'active' : ''}`}>
                                        <p>
                                            Monthly
                                        </p>
                                        {(Object.keys(voucherDetail).length) ?
                                            <>
                                                <p className="final-price free checkout-price">{((planDetail.price * quantity) - (planDetail.price * quantity) * (voucherDetail.benefit / 100)).toFixed(2)} USD</p>
                                                <p className='start-price checkout-layout'>{(planDetail.price * quantity).toFixed(2)} USD</p>
                                            </> :
                                            <p className='desc duration'>
                                                {planDetail.price} USD
                                            </p>
                                        }
                                    </div>
                                    <div onClick={() => setPeriod(1) } className={`period ${period === 1 ? 'active' : ''}`}>
                                        <p>
                                            Yearly
                                        </p>
                                        <p className='desc'>
                                            {(planDetail.price * 10).toFixed(2)} USD
                                        </p>
                                    </div> 
                                </> :
                                <>
                                    <div className={`period disable ${period === 0 ? 'active' : ''}`}>
                                        <p>
                                            Monthly
                                        </p>
                                        {((Object.keys(voucherDetail).length && samePlan)) ?
                                            <>
                                                <p className="final-price free checkout-price">{((planDetail.price * quantity) - (planDetail.price * quantity) * (voucherDetail.benefit / 100)).toFixed(2)} USD</p>
                                                <p className='start-price checkout-layout'>{(planDetail.price * quantity).toFixed(2)} USD</p>
                                            </> :
                                            <p className='desc duration'>
                                                {planDetail.price} USD
                                            </p>
                                        }
                                    </div>
                                    <div className={`period disable ${period === 1 ? 'active' : ''}`}>
                                        <p>
                                            Yearly
                                        </p>
                                        <p className='desc'>
                                            {(planDetail.price * 10).toFixed(2)} USD
                                        </p>
                                    </div> 
                                </>
                                }
                            </div>
                        </> :
                        <>
                            <p>
                                Duration
                            </p>
                            <div className='paymentWrapper test'>
                                <div onClick={() => setDuration(0)} className={`period ${duration === 0 ? 'active' : ''}`}>
                                    <p>
                                        1 Month
                                    </p>
                                    {(Object.keys(voucherDetail).length && samePlan && voucherDetail.quantity === 1) ?
                                        <>
                                            <p className="final-price free checkout-price">{((planDetail.price * quantity) - (planDetail.price * quantity) * (voucherDetail.benefit / 100)).toFixed(2)} USD</p>
                                            <p className='start-price checkout-layout'>{(planDetail.price * quantity).toFixed(2)} USD</p>
                                        </> :
                                        <p className='desc duration'>
                                            {planDetail.price} USD
                                        </p>
                                    }
                                </div>
                                <div onClick={() => setDuration(1)} className={`period ${duration === 1 ? 'active' : ''}`}>
                                    <p>
                                        3 Month
                                    </p>
                                    {(Object.keys(voucherDetail).length && samePlan && voucherDetail.quantity === 3) ?
                                        <>
                                            <p className="final-price free checkout-price">{((planDetail.price * quantity) - (planDetail.price * quantity) * (voucherDetail.benefit / 100)).toFixed(2)} USD</p>
                                            <p className='start-price checkout-layout'>{(planDetail.price * quantity).toFixed(2)} USD</p>
                                        </> :
                                        <p className='desc duration'>
                                            {(planDetail.price * 3).toFixed(2)} USD
                                        </p>
                                    }
                                </div>
                                <div onClick={() => setDuration(2)} className={`period ${duration === 2 ? 'active' : ''}`}>
                                    <p>
                                        6 Month
                                    </p>
                                    {(Object.keys(voucherDetail).length && samePlan && voucherDetail.quantity === 6) ?
                                        <>
                                            <p className="final-price free checkout-price">{((planDetail.price * quantity) - (planDetail.price * quantity) * (voucherDetail.benefit / 100)).toFixed(2)} USD</p>
                                            <p className='start-price checkout-layout'>{(planDetail.price * quantity).toFixed(2)} USD</p>
                                        </> :
                                        <p className='desc duration'>
                                            {(planDetail.price * 6).toFixed(2)} USD
                                        </p>
                                    }
                                </div>
                                <div onClick={() => setDuration(3)} className={`period-custom ${duration === 3 ? 'active' : ''}`}>
                                    <p>
                                        Custom Duration
                                    </p>
                                    <div className='custom'>
                                        <div>
                                            <input value={customDuration} placeholder='1' onChange={e => setCustomDuration(e.target.value)} min={1} type="number" name="amount" />
                                        </div>
                                        {(Object.keys(voucherDetail).length && samePlan && voucherDetail.quantity === +customDuration) ?
                                            <div className='custom-price-discount'>
                                                <p className="final-price free checkout-price custom-voucher">{((planDetail.price * quantity) - (planDetail.price * quantity) * (voucherDetail.benefit / 100)).toFixed(2)} USD</p>
                                                <p className='start-price checkout-layout'>{(planDetail.price * quantity).toFixed(2)} USD</p>
                                            </div> :
                                            <p>
                                                {`${(+total).toFixed(2)}`} USD
                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                        }
                    </div>
                    }
                    {/* Payment Method */}
                    <div className={`paymentScheme ${!isMobile ? 'tablet' : ''}`}>
                        <p>
                            Your Payment Method
                        </p>
                        {planDetail.name !== 'School/Institutional' ?
                        <div className='paymentWrapper paymentMethod'>
                            {scheme ?
                            <>
                                {typeof duration === 'undefined' ?
                                <>
                                    <div className={`method ${typeof duration === 'undefined' ? 'disable' : ''} ${method === 0 ? 'active' : ''}`}>
                                        <p>
                                            <input type="radio" readOnly checked={method === 0}/> Credit/Debit Card
                                        </p>
                                        <p className='desc method'>
                                            Mastercard, Visa, & JBC
                                        </p>
                                    </div>
                                    <div className={`method ${typeof duration === 'undefined' ? 'disable' : ''} ${method === 1 ? 'active' : ''}`}>
                                        <p>
                                            <input type="radio" readOnly checked={method === 1}/> Bank Transfer
                                        </p>
                                        <p className='desc method'>
                                            BNI, Permata, & etc.
                                        </p>
                                    </div>
                                    <div className={`method ${typeof duration === 'undefined' ? 'disable' : ''} ${method === 2 ? 'active' : ''}`}>
                                        <p>
                                            <input type="radio" readOnly checked={method === 2}/> QRIS
                                        </p>
                                        <p className='desc method'>
                                            GoPay, DANA, & LinkAja
                                        </p>
                                    </div>
                                </> : 
                                <>
                                    <div onClick={() => setMethod(0)} className={`method ${typeof duration === 'undefined' ? 'disable' : ''} ${method === 0 ? 'active' : ''}`}>
                                        <p>
                                            <input type="radio" readOnly checked={method === 0}/> Credit/Debit Card
                                        </p>
                                        <p className='desc method'>
                                            Mastercard, Visa, & JBC
                                        </p>
                                    </div>
                                    <div onClick={() => setMethod(1)} className={`method ${typeof duration === 'undefined' ? 'disable' : ''} ${method === 1 ? 'active' : ''}`}>
                                        <p>
                                            <input type="radio" readOnly checked={method === 1}/> Bank Transfer
                                        </p>
                                        <p className='desc method'>
                                            BNI, Permata, & etc.
                                        </p>
                                    </div>
                                    <div onClick={() => setMethod(2)} className={`method ${typeof duration === 'undefined' ? 'disable' : ''} ${method === 2 ? 'active' : ''}`}>
                                        <p>
                                            <input type="radio" readOnly checked={method === 2}/> QRIS
                                        </p>
                                        <p className='desc method'>
                                            GoPay, DANA, & LinkAja
                                        </p>
                                    </div>
                                </>
                                }
                            </> :
                            <>
                            {typeof period === 'undefined' ?
                            <div className={`method ${typeof period === 'undefined' ? 'disable' : ''}`}>
                                <p>
                                    <input type="radio" readOnly checked={method === 0}/> Credit/Debit Card
                                </p>
                                <p className='desc method'>
                                    Mastercard, Visa, & JBC
                                </p>
                            </div> :
                            <div onClick={() => setMethod(0)} className={`method ${typeof period === 'undefined' ? 'disable' : ''}`}>
                                <p>
                                    <input type="radio" readOnly checked={method === 0}/> Credit/Debit Card
                                </p>
                                <p className='desc method'>
                                    Mastercard, Visa, & JBC
                                </p>
                            </div>
                            }
                            <div className={`method disable`}>
                                <p>
                                    <input type="radio" readOnly checked={method === 1}/> Bank Transfer
                                </p>
                                <p className='desc method'>
                                    BNI, Permata, & etc.
                                </p>
                            </div>
                            <div className={`method disable`}>
                                <p>
                                    <input type="radio" readOnly checked={method === 2}/> QRIS
                                </p>
                                <p className='desc method'>
                                    GoPay, DANA, & LinkAja
                                </p>
                            </div> 
                            </>
                            }
                        </div> :
                        <div className='paymentWrapper paymentMethod'>
                            {scheme ?
                            <>
                                <div onClick={() => setMethod(0)} className={`method ${scheme === 0 ? 'disable' : ''} ${method === 0 ? 'active' : ''}`}>
                                    <p>
                                        <input type="radio" readOnly checked={method === 0}/> Credit/Debit Card
                                    </p>
                                    <p className='desc method'>
                                        Mastercard, Visa, & JBC
                                    </p>
                                </div>
                                <div onClick={() => setMethod(1)} className={`method ${scheme === 0 ? 'disable' : ''} ${method === 1 ? 'active' : ''}`}>
                                    <p>
                                        <input type="radio" readOnly checked={method === 1}/> Bank Transfer
                                    </p>
                                    <p className='desc method'>
                                        BNI, Permata, & etc.
                                    </p>
                                </div>
                                <div onClick={() => setMethod(2)} className={`method ${scheme === 0 ? 'disable' : ''} ${method === 2 ? 'active' : ''}`}>
                                    <p>
                                        <input type="radio" readOnly checked={method === 2}/> QRIS
                                    </p>
                                    <p className='desc method'>
                                        GoPay, DANA, & LinkAja
                                    </p>
                                </div>
                            </> :
                            <>
                            <div onClick={() => setMethod(0)} className={`method ${scheme === 1 ? 'disable' : ''}`}>
                                <p>
                                    <input type="radio" readOnly checked={method === 0}/> Credit/Debit Card
                                </p>
                                <p className='desc method'>
                                    Mastercard, Visa, & JBC
                                </p>
                            </div>
                            <div className={`method disable`}>
                                <p>
                                    <input type="radio" readOnly checked={method === 1}/> Bank Transfer
                                </p>
                                <p className='desc method'>
                                    BNI, Permata, & etc.
                                </p>
                            </div>
                            <div className={`method disable`}>
                                <p>
                                    <input type="radio" readOnly checked={method === 2}/> QRIS
                                </p>
                                <p className='desc method'>
                                    GoPay, DANA, & LinkAja
                                </p>
                            </div> 
                            </>
                            }
                        </div>
                        }
                    </div>
                    <div className='paymentScheme'>
                        <p>
                            Insert your voucher code to get discount...
                        </p>
                        <div className='voucher-container'>
                            <input className='voucher-input' value={voucher} type={'text'} name='voucher' placeholder='' onChange={e => setVoucher(e.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault()
                                    checkVoucher()
                                }
                            }}/>
                            {errorVoucher ?
                            <div className={`voucher-view ${!voucher ? 'search-button-blue' : 'search-button-red'}`}>
                            { (Object.keys(voucherDetail).length && samePlan) ?
                                <>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="10" fill="#AA0101"/>
                                        <path d="M14.6517 14.6191C14.7827 14.4911 14.858 14.3166 14.861 14.1334C14.8641 13.9503 14.7948 13.7733 14.6681 13.641L10.9946 9.96745L14.5635 6.39854C14.6915 6.26547 14.7626 6.08766 14.7616 5.90298C14.7605 5.71829 14.6874 5.54132 14.5578 5.40972C14.4266 5.28089 14.2501 5.20871 14.0662 5.20871C13.8824 5.20871 13.7058 5.28089 13.5747 5.40972L10.0057 8.97863L6.32879 5.30167C6.19667 5.17402 6.0194 5.10386 5.8357 5.10651C5.65201 5.10916 5.47684 5.18441 5.34845 5.31582C5.21836 5.44349 5.14418 5.61753 5.14217 5.7998C5.14015 5.98207 5.21047 6.15771 5.33771 6.28823L9.01466 9.96518L5.44066 13.5392C5.31183 13.6704 5.23965 13.8469 5.23965 14.0308C5.23965 14.2146 5.31183 14.3912 5.44066 14.5223C5.57226 14.6519 5.74924 14.725 5.93392 14.7261C6.11861 14.7271 6.29641 14.6561 6.42948 14.528L10.0035 10.954L13.6793 14.6298C13.8098 14.7571 13.9855 14.8274 14.1677 14.8254C14.35 14.8234 14.524 14.7492 14.6517 14.6191Z" fill="white"/>
                                    </svg>
                                    <p>Invalid</p>
                                </> :
                                <>
                                {loading ?
                                    <div className='disable button-disable'>
                                        <div className='loader'></div>
                                    </div>  :
                                    <>
                                        {!voucher ?
                                        <span class="material-icons disable button-disable">
                                            search
                                        </span> :
                                        <div className='error-status-voucher'>
                                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect width="20" height="20" rx="10" fill="#AA0101"/>
                                                <path d="M14.6517 14.6191C14.7827 14.4911 14.858 14.3166 14.861 14.1334C14.8641 13.9503 14.7948 13.7733 14.6681 13.641L10.9946 9.96745L14.5635 6.39854C14.6915 6.26547 14.7626 6.08766 14.7616 5.90298C14.7605 5.71829 14.6874 5.54132 14.5578 5.40972C14.4266 5.28089 14.2501 5.20871 14.0662 5.20871C13.8824 5.20871 13.7058 5.28089 13.5747 5.40972L10.0057 8.97863L6.32879 5.30167C6.19667 5.17402 6.0194 5.10386 5.8357 5.10651C5.65201 5.10916 5.47684 5.18441 5.34845 5.31582C5.21836 5.44349 5.14418 5.61753 5.14217 5.7998C5.14015 5.98207 5.21047 6.15771 5.33771 6.28823L9.01466 9.96518L5.44066 13.5392C5.31183 13.6704 5.23965 13.8469 5.23965 14.0308C5.23965 14.2146 5.31183 14.3912 5.44066 14.5223C5.57226 14.6519 5.74924 14.725 5.93392 14.7261C6.11861 14.7271 6.29641 14.6561 6.42948 14.528L10.0035 10.954L13.6793 14.6298C13.8098 14.7571 13.9855 14.8274 14.1677 14.8254C14.35 14.8234 14.524 14.7492 14.6517 14.6191Z" fill="white"/>
                                            </svg>
                                            <p>Invalid</p>
                                        </div>
                                        }
                                    </>
                                }
                                </>
                            }
                            </div> :
                            <div onClick={(voucher && !Object.keys(voucherDetail).length) ? () => checkVoucher() : null} className={`${voucher === currentVoucher ? 'voucher-view ' : 'voucher-view search-button-blue '}${voucher ? ' button-search' : ' button-disable'}`}>
                            { (Object.keys(voucherDetail).length && (samePlan)) ?
                                <>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect width="20" height="20" rx="10" fill="#0583A8"/>
                                        <path d="M15.6 5.99997L8.60002 14L4.40002 10.3636" stroke="white" stroke-width="1.14039" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    <p>{voucherDetail.benefit}% Off</p>
                                </> :
                                <>
                                {loading ?
                                    <div className='disable button-disable'>
                                        <div className='loader'></div>
                                    </div>  :
                                    <>
                                        {!voucher ?
                                        <span class="material-icons disable button-disable">
                                            search
                                        </span> :
                                        <span onClick={() => checkVoucher()} class="material-icons">
                                            search
                                        </span>
                                        }
                                    </>
                                }
                                </>
                            }
                            </div>
                            }
                        </div>
                        {(errorVoucher && voucher) &&
                        <p className='error-voucher'>{errorVoucher}</p>
                        }
                    </div>
                    <div className={`paymentScheme`}>
                        <p>
                            End-User Agreements
                        </p>
                        <div className='desc-end-user-container'>
                            <input onClick={() => checkboxInput('term')} type="checkbox" id='terms'/>
                            <label className='desc-end-user' for='terms'>
                                I agree to the Assemblr's terms and conditions
                            </label>
                        </div>
                        <br></br>
                        <div className='desc-end-user-container'>
                            <input onClick={() => checkboxInput('newsletter')} type="checkbox" id='subcribe'/>
                            <label className='desc-end-user' for='subcribe'>
                                Send me e-mails about product updates, surveys, and offers
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            {isMobile &&
            <>
            { typeof scheme === 'undefined' ?
            <div
            className='button disable'>
                Purchase
            </div> :
            <>
                { typeof method === 'undefined'?
                    <div
                    className='button disable'>
                        Purchase
                    </div> :
                    <>
                    {loading ?
                        <div className='button disable'>
                            <div className='loader'></div>
                        </div> :
                        <>
                            {term === false ?
                            <div
                            className='button disable'>
                                Purchase
                            </div> :
                            <div onClick={() => sendAPI()}
                            className='button'>
                                Purchase
                            </div>
                            }
                        </>
                    }
                    </>
                }
            </>
            }
            </>
            }
            {!isMobile && 
            <div className='checkout-button right-button'>
            {typeof scheme === 'undefined' ?
                <div
                className='button disable'>
                    Purchase
                </div> :
                <>
                    { typeof method === 'undefined'?
                        <div
                        className='button disable'>
                            Purchase
                        </div> :
                        <>
                        {loading ?
                        <div className='button disable'>
                            <div className='loader'></div>
                        </div> :
                        <>
                            {term === false ?
                            <div
                            className='button disable'>
                                Purchase
                            </div> :
                            <div onClick={() => sendAPI()}
                            className='button'>
                                Purchase
                            </div>
                            }
                        </>
                        }
                        </>
                    }
                </>
            }
            </div>
            }
            {popup &&
            <div className='popup'>
                <div className='popupContent'>
                    <div className='popupHeader'>
                        <span className="material-icons">
                            info
                        </span>
                        <div className='error'>
                            <p>
                            {popup}
                            </p>
                        </div>
                    </div>
                    <div className='switch'>
                        <p className='confirm' onClick={() => {
                            setPopup('')
                            }}>
                            Try Again
                        </p>
                    </div>
                </div>
            </div>
            }
        </div>
        </>
    )
}

export default Checkout;