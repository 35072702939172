import { useMediaQuery } from 'react-responsive';
import { useNavigate, useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';
import {isMobileOnly} from 'react-device-detect';
import { IconValid, IconInvalid, IconSuccessBundle, IconPartyPopper } from '../../icons';
// import PLANS from '../../configs/Plans'
import './index.scss';
import instance from '../../configs/axios';
import gtag from 'ga-gtag'
import queryString from 'query-string'
import jwt_decode from "jwt-decode";
import axios from 'axios';
import Card from '../../components/Card';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

function Redeem(params) {
    let navigate = useNavigate();
    const isMobile = useMediaQuery({ maxWidth: 760})
    const [code, setCode] = useState('')
    const [status, setStatus] = useState(0)
    const [item, setItem] = useState()
    const [firebase_token, setFirebase_token] = useState('')
    const [loading, setLoading] = useState(false)
    const [showPopUp, setShowPopUp] = useState(false)
    let location = useLocation()
    const [embed, setEmbed] = useState(false)
    const [period, setPeriod] = useState('')
    const [quantity, setQuantity] = useState(0)
    const [uid, setUID] = useState('')
    const [errorRedeem, setErrorRedeem] = useState('')
    const [activePlan, setActivePlan] = useState('')
    const [discount, setDiscount] = useState('')
    const [discountBenefit, setDiscountBenefit] = useState(0)
    const [term, setTerm] = useState(false)
    const [newsletter, setNewsLetter] = useState(false)
    const [voucher, setVoucher] = useState({})
    const [flow, setFlow] = useState('')
    const [PLANS, setPLANS] = useState([])
    const [ bundle, setBundle ] = useState([])
    const [ showConfirmation, setShowConfirmation ] = useState(false)
    const [ showSuccess, setShowSuccess ] = useState(false)
    const [ close, setClose ] = useState(false)
    const [ bundleDetail, setBundleDetail ] = useState({
        banner_img: "",
        owner_url: ""
    })
    const RedeemCodeTracking = (payload) => {
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: "select_promotion",
            ecommerce: {
                items: [
                    {
                        item_id: payload.id,
                        item_name: payload.name,
                        coupon: voucher.name,
                        benefit: voucher.benefit,
                        affiliation: payload.plan,
                        currency: "USD",
                        item_list_id: payload.plan === 'marker' ? 'plan_list' : 'marker_list',
                        item_list_name: payload.plan === 'marker' ? 'Plan List' : 'Marker List',
                        item_duration: quantity + ' Month',
                        quantity
                    }
                ]
            }
        });
    }
    const handlerSubmit = (payload) => {
        setLoading(true)
        const queryParams = queryString.parse(window.location.search)
        if (queryParams.token === 'undefined') {
            window.parent.postMessage("show popup login", "*")
            return
        }
        instance.post('api/voucher/check', {
            code_voucher: code,
            firebase_token
        }).then((response) => {
            if (typeof response.data.plans !== 'undefined') {
                const { id } = response.data.plans[0]
                const {duration_qty, product_qty, plans, benefit} = response.data
                const planName = (plans?.length > 0 ? ((plans[0]?.plans) ?? '') : '')
                let itemData = planName.includes('BLR') ? { ...PLANS[id], name: planName } : PLANS[id]
                console.log(PLANS, itemData, planName, 'responseresponse');
                // if (typeof itemData === 'undefined') {
                    let itemDataCheck = PLANS.filter((plan) => plan.name === planName)
                    let content = []
                    if (planName.toLowerCase().includes("blr")) {
                        content = ['Can be used to purchase various items in Assemblr ecosystem ', ' ']
                    } else {
                        for (const key in itemDataCheck[0].feature) {
                            if (Object.hasOwnProperty.call(itemDataCheck[0].feature, key)) {
                                const element = itemDataCheck[0].feature[key];
                                console.log(element, key, 'responseresponse');
                                switch (key) {
                                    case 'educational_contents':
                                        if (element === true) content.push(['Unlimited ', 'Educational Contents'])
                                        break;
                                    case 'virtual_class':
                                        if (element === true) content.push(['Unlimited ', 'Virtual Class'])
                                        break;
                                    case 'library_access':
                                        if (element === true) content.push(['Unlimited ', '2D, 3D, AR Library'])
                                        break;
                                    case 'qr_marker':
                                        if (element === true) content.push(['Unlimited ', 'QR Markers'])
                                        break;
                                    case 'offline_access':
                                        if (element === true) content.push(['Offline ', 'Access'])
                                        break;
                                    case 'quota':
                                        if (itemDataCheck[0].plans_type.toLowerCase() === "personal") {
                                            content.push([`${element/1000000} MB `, 'Storage'])
                                        } else if (itemDataCheck[0].plans_type.toLowerCase() === "education") {
                                            content.push([`${element/1000000} MB `, 'Storage Per Account'])
                                        }
                                        break;
                                    case 'marker':
                                        if (itemDataCheck[0].plans_type.toLowerCase() === "personal") {
                                            content.push([`${element} `, 'Custom Markers'])
                                        } else if (itemDataCheck[0].plans_type.toLowerCase() === "education") {
                                            content.push([`${element} `, 'Custom Markers Per Account'])
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }
                        if (planName.includes("School Premium")) {
                            content.push(['1 ', 'Member Seats'])
                        }
                    }
                    content = content.reverse()
                    // if (itemDataCheck[0].plans_type.toLowerCase() === "personal") {
                    //     content = [...content,
                    //         ['Unlimited ', 'Projects'],
                    //         ['Unlimited ', 'QR Markers'],
                    //         ['Unlimited ', '2D, 3D, AR Library']
                    //     ]
                    // }
                    console.log(content, 'responseresponse');
                    itemData = {
                        ...itemDataCheck[0],
                        contents: content
                    }
                // }
                setVoucher(response.data)
                setItem(itemData)
                setQuantity(product_qty)
                console.log(itemData, id, 'responseresponse');
                RedeemCodeTracking(itemData)
                if (id === 109) {
                    setPeriod('Three Months')
                    console.log(PLANS, itemData, 'responseresponse');
                } else if (id === 119) {
                    setPeriod('Lifetime')
                } else if (id === 122) {
                    setPeriod('2 Months')
                } else if (id !== 20) {
                    if (plans[0].duration === 30) {
                        switch (duration_qty) {
                            case 1:
                                setPeriod(`${duration_qty} Month`)
                                break;
                                
                            case 2:
                                setPeriod(`${duration_qty} Months`)
                                break;
                        
                            case 3:
                                setPeriod(`${duration_qty} Months`)
                                break;
                        
                            case 4:
                                setPeriod(`${duration_qty} Months`)
                                break;
                        
                            case 5:
                                setPeriod(`${duration_qty} Months`)
                                break;
                        
                            case 6:
                                setPeriod(`${duration_qty} Months`)
                                break;
                        
                            case 7:
                                setPeriod(`${duration_qty} Months`)
                                break;
                        
                            case 8:
                                setPeriod(`${duration_qty} Months`)
                                break;
                        
                            case 9:
                                setPeriod(`${duration_qty} Months`)
                                break;
                        
                            case 10:
                                setPeriod(`${duration_qty} Months`)
                                break;
                        
                            case 11:
                                setPeriod(`${duration_qty} Months`)
                                break;
                        
                            case 12:
                                setPeriod(`${duration_qty} Months`)
                                break;
                        
                            default:
                                break;
                        }
                    } else {
                        setPeriod('One Year Period')
                    }
                }
                switch (benefit) {
                    case 100:
                        setDiscount('Get Free Voucher')
                        setDiscountBenefit(benefit)
                        setTerm(false)
                        setNewsLetter(false)
                        break;
                
                    default:
                        setDiscount(`Get ${benefit}% Discount`)
                        setDiscountBenefit(benefit)
                        setTerm(true)
                        setNewsLetter(true)
                        break;
                }
                setStatus(1)
            }
            if (typeof response.data.bundles !== 'undefined') {
                setStatus(7)
                setBundle(response.data.bundles)
            }
            setTimeout(() => {
                setLoading(false)
            }, 2000)
        }).catch(async (error) => {
            console.log(error);
            const { code_voucher } = error.response.data.errors
            if (code_voucher.includes((process.env.NODE_ENV==='production' && !window.location.hostname.includes('192.168'))?process.env.REACT_APP_API_PROD + "/api/features/active":process.env.REACT_APP_API_PROD + "/api/features/active")) {
                let {data} = await axios({
                    method: 'GET',
                    url: `${error.response.data.errors.code_voucher}`
                })
                setActivePlan(data.active_plan.plans_name)
                setStatus(6)
            } else {
                switch (code_voucher) {
                    case 'Voucher Not Found':
                        setStatus(2)
                        break;
                
                    case 'Voucher Expire':
                        setStatus(3)
                        break;
                
                    case 'Voucher Exceeds The Quota Limit':
                        setStatus(4)
                        break;
                
                    case 'Voucher has been activated':
                        setStatus(5)
                        break;
                
                    default:
                        break;
                }
            }
            setTimeout(() => {
                setBundle([])
                setLoading(false)
            }, 2000)
        })
    }
    const redeemVoucher = (payload) => {
        setLoading(true)
        instance.post('api/voucher/redeem', {
            code_voucher: code,
            firebase_token
        }).then((response) => {
            if (bundle.length) {
                // setShowConfirmation(false)
                setShowConfirmation(false)
                setShowSuccess(true)
                setTimeout(() => {
                    setLoading(false)

                }, 2000)
            } else {
                window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                window.dataLayer.push({
                    event: "begin_checkout",
                    ecommerce: {
                        items: [
                            {
                                item_id: item.id,
                                item_name: item.name,
                                currency: "USD",
                                discount: Object.keys(voucher).length ? discountBenefit : 0,
                                item_category: item.plan,
                                item_duration: 1,
                                item_period: "monthly",
                                item_list_id: item.plan === 'marker' ? 'plan_list' : 'marker_list',
                                item_list_name: item.plan === 'marker' ? 'Plan List' : 'Marker List',
                                quantity
                            }
                        ]
                    }
                });
                if (location.search.includes("embed")) {
                    navigate('/SuccessRedeem')
                } else {
                    navigate('/Success')
                }
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.data.error_message === 'You Have Plans Educations') {
                setErrorRedeem('You already have plans educations, please wait till you plans is expired.')
            } else {
                setErrorRedeem(error.response.data.error_message)
            }
        })
    }
    const proceedVoucher = (payload) => {
        switch (discountBenefit) {
            case 100:
                setShowPopUp(true)
                break;
                
            default:
                item.plan === 'marker' ? navigate(`/NewCheckout/${item.id}?embed=true&edu=true&token=${firebase_token}&type=edu&voucher=${code}&flow=redeem&count=${quantity}`) : navigate(`/NewCheckout/${item.id}?embed=true&edu=true&token=${firebase_token}&type=${queryString.parse(window.location.search).close_placement ? 'astu' : 'edu'}&voucher=${code}&flow=redeem&close=true`)
                break;
        }
    }
    const checkboxInput = (type) => {
        switch (type) {
            case 'term':
                setTerm(!term)
                break;
        
            case 'newsletter':
                setNewsLetter(!newsletter)
                break;
        
            default:
                break;
        }
    }

    const getBundleDetail = async (id) => {
        try {
            let { data } = await instance.get(`/api/library/banner-bundle/${id}`)
            const { banner_img, owner_url } = data
            setBundleDetail({
                banner_img,
                owner_url
            })
        } catch (error) {
            console.log(error);
        }
    }

    const getAllPlan = async () => {
        const publicPlan = await instance.get(`/api/plans/filter/public`, {})
        console.log(publicPlan.data, 'responseresponse');
        const privatePlan = await instance.get(`/api/plans/filter/private`, {})
        console.log(privatePlan.data, 'responseresponse');
        setPLANS([...publicPlan.data.plans, ...privatePlan.data.plans])
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        window.addEventListener('message', function (e) {
            if (e.data === 'redeem') {
                setFlow(e.data)
            }
        });
        const queryParams = queryString.parse(window.location.search)
        if (queryParams.type === 'astu') {
            window.dataLayer.push({
                open_from: "https://studio.assemblrworld.com"
            })
        } else {
            window.dataLayer.push({
                open_from: "https://app-edu.assemblrworld.com"
            })
        }
        if (queryParams.token !== 'undefined') {
            setFirebase_token(queryParams.token)
            setUID(jwt_decode(queryParams.token).user_id)
            localStorage.setItem("UUID", jwt_decode(queryParams.token).user_id)
            getAllPlan(queryParams.token)
        }
        if (queryParams.bundleID) {
            getBundleDetail(queryParams.bundleID)
        }
        if (queryParams.embed) setEmbed(true)
        if (queryParams.hide_close) setClose(true)
        console.log(isMobileOnly, 'isAndroidisAndroid');
    }, [])

    useEffect(() => {
        console.log(PLANS, 'responseresponse');
    }, [PLANS])

    return (
        <div className='Main marketplace redeem scroll-hidden'>
            <div className='nav nav-redeem'>
                <div className='header'>
                    { !close ?
                    <>
                        {!embed ?
                        <span onClick={() => {
                            navigate(`/Overview?edu=true&uid=${uid}&token=${firebase_token}`)
                        }} className="material-icons">
                            arrow_back_ios_new
                        </span> :
                        <span onClick={() => {
                            window.parent.postMessage("close popup", "*")
                        }} className={`material-icons ${queryString.parse(window.location.search).close_placement ? 'right' : ''}`}>
                            close
                        </span>
                        }
                    </> : null
                    }
                    <p className='headerTitle'>
                    {!showSuccess ?
                        <>Redeem Code</>
                        : null
                    }
                    </p>
                    {showSuccess ?
                    <div className='icon_party_popper_container'>
                        <IconPartyPopper className='icon_party_popper' />
                    </div> : null
                    }
                </div>
            </div>
            <div className={`contentRedeem scroll-hidden ${!isMobile ? 'tablet' : ''} ${isMobileOnly ? 'mobile-only' : ''}`}>
                {showSuccess ?
                <>
                <div className='redeemCode bundle_success'>
                    {/* <IconSuccessBundle /> */}
                    <p className='titleInput bundle_success'>
                        3D Bundles Redeemed!
                    </p>
                </div>
                <>
                {status === 1 &&
                <>
                    <div className='validStatus'>
                        <IconValid />
                        <p>Voucher's correct. You're all set!</p>
                    </div>
                    <div className='content title_redeem_voucher_bundle'>
                            What you’ll get :
                    </div>
                    <Card voucher={voucher} item={item} quantity={quantity} discount={discount} discountBenefit={discountBenefit} checkboxInput={checkboxInput} period={period} checkout={false} isMobile={isMobile}/>
                </>
                }
                {status === 2 &&
                <>
                    <div className='validStatus'>
                        <IconInvalid />
                        <p className='titleInput invalid'>Invalid Voucher</p>
                    </div>
                    <p className='validStatus invalid'>
                        We can't find the code. Please check again—might be typos?
                    </p>
                </>
                }
                {status === 3 &&
                <>
                    <div className='validStatus'>
                        <IconInvalid />
                        <p className='titleInput invalid'>Voucher Expired</p>
                    </div>
                    <p className='validStatus invalid'>
                        You've surpassed the time limit to redeem the voucher. A tip: next time, try to redeem it on time.
                    </p>
                </>
                }
                {status === 4 &&
                <>
                    <div className='validStatus'>
                        <IconInvalid />
                        <p className='titleInput invalid'>Maximum Redemption Reached</p>
                    </div>
                    <p className='validStatus invalid'>
                        This voucher has reached its maximum redemption limit. Try again next time.
                    </p>
                </>
                }
                {status === 5 &&
                <>
                    <div className='validStatus'>
                        <IconInvalid />
                        <p className='titleInput invalid'>Voucher Redeemed</p>
                    </div>
                    <p className='validStatus invalid'>
                        You have already redeemed this voucher. Try using another one, shall we?
                    </p>
                </>
                }
                {status === 6 &&
                <>
                    <div className='validStatus'>
                        <IconInvalid />
                        <p className='titleInput invalid'>You have already active plan</p>
                    </div>
                    <p className='validStatus invalid'>
                        You already have an "{activePlan}" Plan, please wait for the plan to expired or you can cancel current plan.
                    </p>
                </>
                }
                {status === 7 &&
                <>
                    <div className='validStatus bundle_success'>
                        <p className='desc_redeem_voucher_bundle'>Go find them on your 3D library, and get ready to create stunning creations!</p>
                        <p className='desc_redeem_voucher_bundle'>Please reload this page to continue!</p>
                    </div>
                    <Carousel
                        additionalTransfrom={0}
                        arrows
                        autoPlay={false}
                        autoPlaySpeed={1000000}
                        centerMode={false}
                        className="bundle_list"
                        containerClass="container-with-dots"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                        responsive={{
                          desktop: {
                            breakpoint: {
                              max: 3000,
                              min: 1024
                            },
                            items: 3,
                            partialVisibilityGutter: 40
                          },
                          mobile: {
                            breakpoint: {
                              max: 464,
                              min: 0
                            },
                            items: 2,
                            partialVisibilityGutter: 30
                          },
                          tablet: {
                            breakpoint: {
                              max: 1024,
                              min: 464
                            },
                            items: 2,
                            partialVisibilityGutter: 30
                          }
                        }}
                        showDots={false}
                        sliderClass=""
                        slidesToSlide={1}
                        swipeable
                    >
                        { bundle.map((item, i) => (
                        <div key={i}>
                            <div className='bundle_item'>
                                <img className='bundle_img' src={item.image} />
                            </div>
                            <div className='name_count_container'>
                                <h3 className='desc_redeem_voucher_bundle desc_bundle'>{item.bundles}</h3>
                                <h3 className='desc_redeem_voucher_bundle desc_bundle'>{item.jumlah_item + " Items"}</h3>
                            </div>
                        </div>
                        ))}
                    </Carousel>
                    {/* {!showSuccess ?
                    <div className={`paymentScheme ${!isMobile ? 'bundle_success' : ''} `}>
                        <p>
                            End-User Agreements
                        </p>
                        <div className='desc-end-user-container'>
                            <input onClick={() => checkboxInput('term')} type="checkbox" id='terms'/>
                            <label className='desc_redeem_voucher_bundle' for='terms'>
                                I agree to the Assemblr's terms and conditions
                            </label>
                        </div>
                        <br></br>
                        <div className='desc-end-user-container'>
                            <input onClick={() => checkboxInput('newsletter')} type="checkbox" id='subcribe'/>
                            <label className='desc_redeem_voucher_bundle' for='subcribe'>
                                Send me e-mails about product updates, surveys, and offers
                            </label>
                        </div>
                    </div> : null
                    } */}
                </>
                }
                </>
                </> :
                <>
                    <div className='redeemCode'>
                        {!bundle.length ?
                        <>
                        {bundleDetail.banner_img.length ?
                            <div className='banner_container_bundle'>
                                <img src={bundleDetail.banner_img} className='banner_image_bundle' />
                                <div className='banner_text_container_bundle'>
                                    <h2 className='banner_text'>Whoops! You haven't redeemed the bundle</h2>
                                    <div 
                                        onClick={() => window.open(bundleDetail.owner_url)}
                                        className='banner_external_url'>
                                        <h6 className='url_text'>Find the Code</h6>
                                    </div>
                                </div>
                            </div>
                            : null
                        }
                        </> : null
                        }
                        <p className={`titleInput ${!isMobile ? 'redeem_input' : ''} title_redeem_voucher_bundle`}>
                            Redeem Voucher Code
                        </p>
                        <form className='formRedeem' onSubmit={handlerSubmit}>
                            <input className='inputRedeem' value={code} placeholder='Enter your code here..' onChange={e => setCode(e.target.value)}
                            onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    event.preventDefault()
                                    handlerSubmit(code)
                                }
                            }} 
                            type="text" name="code" />
                            <div onClick={() => {
                                handlerSubmit(code)
                                }} className='submitRedeem'>
                                <span className="material-icons">
                                    arrow_forward_ios
                                </span>
                            </div>
                        </form>
                    </div>
                    {!status ?
                    <p className='titleInput desc'>
                        Input your voucher code to activate your plan. Make sure to check out any typos.
                    </p> :
                    <>
                    {loading ?
                    <div className='loader-container'>
                        <div className='loader2'></div>
                    </div> :
                    <>
                    {status === 1 &&
                    <>
                        <div className='validStatus'>
                            <IconValid />
                            <p>Voucher's correct. You're all set!</p>
                        </div>
                        <div className='content title_redeem_voucher_bundle'>
                                What you’ll get :
                        </div>
                        <Card voucher={voucher} item={item} quantity={quantity} discount={discount} discountBenefit={discountBenefit} checkboxInput={checkboxInput} period={period} checkout={false} isMobile={isMobile}/>
                    </>
                    }
                    {status === 2 &&
                    <>
                        <div className='validStatus'>
                            <IconInvalid />
                            <p className='titleInput invalid'>Invalid Voucher</p>
                        </div>
                        <p className='validStatus invalid'>
                            We can't find the code. Please check again—might be typos?
                        </p>
                    </>
                    }
                    {status === 3 &&
                    <>
                        <div className='validStatus'>
                            <IconInvalid />
                            <p className='titleInput invalid'>Voucher Expired</p>
                        </div>
                        <p className='validStatus invalid'>
                            You've surpassed the time limit to redeem the voucher. A tip: next time, try to redeem it on time.
                        </p>
                    </>
                    }
                    {status === 4 &&
                    <>
                        <div className='validStatus'>
                            <IconInvalid />
                            <p className='titleInput invalid'>Maximum Redemption Reached</p>
                        </div>
                        <p className='validStatus invalid'>
                            This voucher has reached its maximum redemption limit. Try again next time.
                        </p>
                    </>
                    }
                    {status === 5 &&
                    <>
                        <div className='validStatus'>
                            <IconInvalid />
                            <p className='titleInput invalid'>Voucher Redeemed</p>
                        </div>
                        <p className='validStatus invalid'>
                            You have already redeemed this voucher. Try using another one, shall we?
                        </p>
                    </>
                    }
                    {status === 6 &&
                    <>
                        <div className='validStatus'>
                            <IconInvalid />
                            <p className='titleInput invalid'>You have already active plan</p>
                        </div>
                        <p className='validStatus invalid'>
                            You already have an "{activePlan}" Plan, please wait for the plan to expired or you can cancel current plan.
                        </p>
                    </>
                    }
                    {status === 7 &&
                    <>
                        <div className='validStatus'>
                            <IconValid />
                            <p className='desc_redeem_voucher_bundle'>Voucher's correct. You're all set!</p>
                        </div>
                        <div className='content title_redeem_voucher_bundle'>
                                What you’ll get :
                        </div>
                        {/* <div className='bundle_list'>
                        </div> */}
                        <Carousel
                            additionalTransfrom={0}
                            arrows
                            autoPlay={false}
                            autoPlaySpeed={1000000}
                            centerMode={false}
                            className="bundle_list"
                            containerClass="container-with-dots"
                            dotListClass=""
                            draggable
                            focusOnSelect={false}
                            itemClass=""
                            keyBoardControl
                            minimumTouchDrag={80}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            responsive={{
                            desktop: {
                                breakpoint: {
                                max: 3000,
                                min: 1024
                                },
                                items: 3,
                                partialVisibilityGutter: 40
                            },
                            mobile: {
                                breakpoint: {
                                max: 464,
                                min: 0
                                },
                                items: 2,
                                partialVisibilityGutter: 30
                            },
                            tablet: {
                                breakpoint: {
                                max: 1024,
                                min: 464
                                },
                                items: 2,
                                partialVisibilityGutter: 30
                            }
                            }}
                            showDots={false}
                            sliderClass=""
                            slidesToSlide={1}
                            swipeable
                        >
                            { bundle.map((item, i) => (
                            <div key={i}>
                                <div className='bundle_item'>
                                    <img className='bundle_img' src={item.image} />
                                </div>
                                <div className='name_count_container'>
                                    <h3 className='desc_redeem_voucher_bundle desc_bundle'>{item.bundles}</h3>
                                    <h3 className='desc_redeem_voucher_bundle desc_bundle'>{item.jumlah_item + " Objects"}</h3>
                                </div>
                            </div>
                            ))}
                        </Carousel>
                        {/* <div className={`paymentScheme ${!isMobile ? 'bundle_success' : ''} `}>
                            <p>
                                End-User Agreements
                            </p>
                            <div className='desc-end-user-container'>
                                <input onClick={() => checkboxInput('term')} type="checkbox" id='terms'/>
                                <label className='desc_redeem_voucher_bundle' for='terms'>
                                    I agree to the Assemblr's terms and conditions
                                </label>
                            </div>
                            <br></br>
                            <div className='desc-end-user-container'>
                                <input onClick={() => checkboxInput('newsletter')} type="checkbox" id='subcribe'/>
                                <label className='desc_redeem_voucher_bundle' for='subcribe'>
                                    Send me e-mails about product updates, surveys, and offers
                                </label>
                            </div>
                        </div> */}
                    </>
                    }
                    </>
                    }
                    </>
                    }
                </>
                }
            </div>
            {showPopUp &&
            <div className='popup'>
                <div className='popupContent'>
                    <div className='popupHeader'>
                        <span className="material-icons">
                            info
                        </span>
                        {errorRedeem ?
                        <p className='error'>
                            {errorRedeem}
                        </p> :
                        <>
                        {item.plan !== 'marker' ?
                            <p>
                                Redeem {period} "{item.name} Plan" ?
                            </p> :
                            <p>
                                {item.name === "Blr Coin" ? `Redeem Blr Coin Voucher` : `Redeem ${quantity} Custom Marker`} ?
                            </p>
                        }
                        </>
                        }
                    </div>
                    {errorRedeem ?
                    <div className='switch'>
                        <p className='confirm' onClick={() => {
                            setShowPopUp(false)
                            setErrorRedeem('')
                            }}>
                            Try Again
                        </p>
                    </div> :
                    <div className='switch'>
                        {loading ?
                        <div className='confirm disable'>
                            <div className='loader2'></div>
                        </div> :
                        <p className='confirm' onClick={() => redeemVoucher()}>
                            Yes
                        </p>
                        }
                        <p onClick={() => setShowPopUp(false)}>
                            No
                        </p>
                    </div>
                    }
                </div>
            </div>
            }
            {showConfirmation &&
            <div className='popup'>
                <div className='popupContent'>
                    <div className='popupHeader'>
                        <span className="material-icons">
                            info
                        </span>
                        {errorRedeem ?
                        <p className='error'>
                            {errorRedeem}
                        </p> :
                        <>
                            <h2 className='text_header_content'>
                                Redeem This Bundle?
                            </h2>
                            <p className='text_conetent_header'>You won't be able to refund or cancel it.</p>
                        </>
                        }
                    </div>
                    {errorRedeem ?
                    <div className='switch'>
                        <p className='confirm' onClick={() => {
                            setShowConfirmation(false)
                            setErrorRedeem('')
                            }}>
                            Try Again
                        </p>
                    </div> :
                    <div className='switch'>
                        {loading ?
                        <div className='confirm disable'>
                            <div className='loader2'></div>
                        </div> :
                        <p className='confirm' onClick={() => redeemVoucher()}>
                            Redeem
                        </p>
                        }
                        <p onClick={() => setShowConfirmation(false)}>
                            Cancel
                        </p>
                    </div>
                    }
                </div>
            </div>
            }
            {!bundle.length ?
            <>
                {!showSuccess ?
                <div className='redeem-voucher-proceed'>
                    {isMobile &&
                    <>
                        {!showSuccess ?
                        <div className={`end-user-container`}>
                            <div className="desc-end-user-container">
                                <input
                                onClick={() => checkboxInput("newsletter")}
                                type="checkbox"
                                id="subcribe"
                                />
                                <label className="desc-end-user" for="subcribe">
                                    Send me e-mails about product updates, surveys, and offers
                                </label>
                            </div>
                        </div> : null
                        }
                        { typeof item === 'undefined'?
                            <div
                            className='button disable'>
                                Proceed
                            </div> :
                            <>
                            {loading ?
                                <div className='button disable'>
                                    <div className='loader'></div>
                                </div> :
                                <>
                                    {status !== 1 ?
                                    <div
                                    className='button disable'>
                                        <>
                                        Proceed
                                        </> 
                                    </div> :
                                    <div className={`button proceed ${status !== 1 ? 'hidden' : ''}`}
                                        onClick={() => proceedVoucher()}>
                                            Proceed
                                    </div>
                                    }
                                </>
                            }
                            </>
                        }
                        <p className="default-button-desc">By pressing the button above, you’re agree to <a href="https://www.assemblrworld.com/pages/eula" target={"_blank"}>Assemblr's terms and conditions</a></p>
                    </>
                    }
                    {!isMobile && 
                    <div className='checkout-button'>
                        {!showSuccess ?
                        <div className={`end-user-container`}>
                            <div className="desc-end-user-container">
                                <input
                                onClick={() => checkboxInput("newsletter")}
                                type="checkbox"
                                id="subcribe"
                                />
                                <label className="desc-end-user" for="subcribe">
                                    Send me e-mails about product updates, surveys, and offers
                                </label>
                            </div>
                        </div> : null
                        }
                        { typeof item === 'undefined' ?
                            <div
                            className='button disable'>
                                Proceed
                            </div> :
                            <>
                            {loading ?
                            <div className='button disable'>
                                <div className='loader'></div>
                            </div> :
                            <>
                                {status !== 1 ?
                                <div
                                className='button disable'>
                                    Proceed
                                </div> :
                                <div className={`button proceed ${status !== 1 ? 'hidden' : ''}`}
                                    onClick={() => proceedVoucher()}>
                                        Proceed
                                </div>
                                }
                            </>
                            }
                            </>
                        }
                        <p className="default-button-desc">By pressing the button above, you’re agree to <a href="https://www.assemblrworld.com/pages/eula" target={"_blank"}>Assemblr's terms and conditions</a></p>
                    </div>
                    }
                </div> : null
                }
            </> :
            <>
                {!showSuccess ?
                <div className='redeem-voucher-proceed'>
                    {isMobile &&
                    <>
                        {!showSuccess ?
                        <div className={`end-user-container`}>
                            <div className="desc-end-user-container">
                                <input
                                onClick={() => checkboxInput("newsletter")}
                                type="checkbox"
                                id="subcribe"
                                />
                                <label className="desc-end-user" for="subcribe">
                                    Send me e-mails about product updates, surveys, and offers
                                </label>
                            </div>
                        </div> : null
                        }
                        { !bundle.length ?
                            <div
                            className='button disable'>
                                Proceed
                            </div> :
                            <>
                            {loading ?
                                <div className='button disable'>
                                    <div className='loader'></div>
                                </div> :
                                <>
                                    {status !== 7 ?
                                    <div
                                    className='button disable'>
                                        <>
                                        Proceed
                                        </> 
                                    </div> :
                                    <div className={`button proceed ${status !== 7 ? 'hidden' : ''}`}
                                        onClick={() => setShowConfirmation(true)}>
                                            Proceed
                                    </div>
                                    }
                                </>
                            }
                            </>
                        }
                        <p className="default-button-desc">By pressing the button above, you’re agree to <a href="https://www.assemblrworld.com/pages/eula" target={"_blank"}>Assemblr's terms and conditions</a></p>
                    </>
                    }
                    {!isMobile && 
                    <div className='checkout-button'>
                        { !bundle.length ?
                            <div
                            className='button disable'>
                                Proceed
                            </div> :
                            <>
                            {loading ?
                            <div className='button disable'>
                                <div className='loader'></div>
                            </div> :
                            <>
                                {status !== 7 ?
                                <div
                                className='button disable'>
                                    Proceed
                                </div> :
                                <div className={`button proceed ${status !== 7 ? 'hidden' : ''}`}
                                    onClick={() => setShowConfirmation(true)}>
                                        Proceed
                                </div>
                                }
                            </>
                            }
                            </>
                        }
                    </div>
                    }
                </div> :
                <>
                {status !== 7 ?
                    <div className='redeem-voucher-proceed'>
                        {isMobile &&
                        <>
                            { !bundle.length ?
                                <div
                                className='button disable'>
                                    Done
                                </div> :
                                <>
                                {loading ?
                                    <div className='button disable'>
                                        <div className='loader'></div>
                                    </div> :
                                    <>
                                        {status !== 7 ?
                                        <div
                                        className='button disable'>
                                            <>
                                            Done
                                            </> 
                                        </div> :
                                        <div className={`button proceed ${status !== 7 ? 'hidden' : ''}`}
                                            onClick={() => {
                                                window.parent.postMessage(  {
                                                id: 'close_voucher_popup',
                                                }, 
                                                "*")
                                                window.parent.postMessage("close", "*")
                                            }}>
                                                Done
                                        </div>
                                        }
                                    </>
                                }
                                </>
                            }
                        </>
                        }
                        {!isMobile && 
                        <div className='checkout-button'>
                            { !bundle.length ?
                                <div
                                className='button disable'>
                                    Done
                                </div> :
                                <>
                                {loading ?
                                <div className='button disable'>
                                    <div className='loader'></div>
                                </div> :
                                <>
                                    {status !== 7 ?
                                    <div
                                    className='button disable'>
                                        Done
                                    </div> :
                                    <div className={`button proceed ${status !== 7 ? 'hidden' : ''}`}
                                        onClick={() => {
                                            // window.reload()
                                            window.parent.postMessage(  {
                                            id: 'close_voucher_popup',
                                            }, 
                                            "*")
                                            window.parent.postMessage("close", "*")
                                        }}>
                                            Done
                                    </div>
                                    }
                                </>
                                }
                                </>
                            }
                        </div>
                        }
                    </div> : null
                }
                </>
                }
            </>
            }
        </div>
    )
}

export default Redeem;