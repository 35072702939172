import React, { useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js';
import queryString from 'query-string'

export default function NewTab() {
  const stripePromise = loadStripe((process.env.NODE_ENV==='production' && !window.location.hostname.includes('192.168'))?process.env.REACT_APP_STRIPE_TOKEN:process.env.REACT_APP_STRIPE_TOKEN_DEV);

  const openStripe = async () => {
    const stripe = await stripePromise;
    const queryParams = queryString.parse(window.location.search)
    const sessionId = queryParams.sessionId
    await stripe.redirectToCheckout({
        sessionId
    })
  }

  useEffect(() => {
    openStripe()
  }, [])
  
  return (
    <div>

    </div>
  )
}
