const PLANS = {
    1:{ // PERSONAL PLAN
        id: 1,
        name:'FREE',
        plan: 'personal',
        type: 'monthly',
        month_id: 1,
        year_id: 5,
        price: 0,
        currency: 'USD',
        annually_price: 0,
        contents: [
            ['8 MB ', 'Storage'],
            ['Unlimited ', 'Projects'],
            ['Unlimited ', 'QR Markers'],
            ['Limited ', '2D, 3D, AR Library'],
        ]
    },
    2:{
        id: 2,
        name:'BASIC',
        plan: 'personal',
        type: 'monthly',
        month_id: 2,
        year_id: 6,
        price: 9.90,
        currency: 'USD',
        annually_price: 99.00,
        contents: [
            ['300 MB ', 'Storage'],
            ['5 ', 'Custom Markers'],
            ['Unlimited ', 'Projects'],
            ['Unlimited ', 'QR Markers'],
            ['Unlimited ', '2D, 3D, AR Library'],
        ]
    },
    3:{
        id: 3,
        name:'MEDIUM',
        plan: 'personal',
        type: 'monthly',
        month_id: 3,
        year_id: 7,
        price: 24.90,
        currency: 'USD',
        annually_price: 249.00,
        contents: [
            ['500 MB ', 'Storage'],
            ['7 ', 'Custom Markers'],
            ['Unlimited ', 'Projects'],
            ['Unlimited ', 'QR Markers'],
            ['Unlimited ', '2D, 3D, AR Library'],
        ]
    },
    4:{
        id: 4,
        name:'PREMIUM',
        plan: 'personal',
        type: 'monthly',
        month_id: 4,
        year_id: 8,
        price: 44.90,
        currency: 'USD',
        annually_price: 449.00,
        contents: [
            ['1,000 MB ', 'Storage'],
            ['25 ', 'Custom Markers'],
            ['Unlimited ', 'Projects'],
            ['Unlimited ', 'QR Markers'],
            ['Unlimited ', '2D, 3D, AR Library'],
        ]
    },
    5:{
        id: 5,
        name:'FREE',
        plan: 'personal',
        type: 'annually',
        month_id: 1,
        year_id: 5,
        price: 0,
        currency: 'USD',
        annually_price: 0,
        contents: [
            ['8 MB ', 'Storage'],
            ['Unlimited ', 'Projects'],
            ['Unlimited ', 'QR Markers'],
            ['Limited ', '2D, 3D, AR Library'],
        ]
    },
    6:{
        id: 6,
        name:'BASIC',
        plan: 'personal',
        type: 'annually',
        month_id: 2,
        year_id: 6,
        price: 9.90,
        currency: 'USD',
        annually_price: 99.0,
        contents: [
            ['300 MB ', 'Storage'],
            ['5 ', 'Custom Markers'],
            ['Unlimited ', 'Projects'],
            ['Unlimited ', 'QR Markers'],
            ['Unlimited ', '2D, 3D, AR Library'],
        ]
    },
    7:{
        id: 7,
        name:'MEDIUM',
        plan: 'personal',
        type: 'annually',
        month_id: 3,
        year_id: 7,
        price: 24.90,
        currency: 'USD',
        annually_price: 249.0,
        contents: [
            ['500 MB ', 'Storage'],
            ['7 ', 'Custom Markers'],
            ['Unlimited ', 'Projects'],
            ['Unlimited ', 'QR Markers'],
            ['Unlimited ', '2D, 3D, AR Library'],
        ]
    },
    8:{
        id: 8,
        name:'PREMIUM',
        plan: 'personal',
        type: 'annually',
        month_id: 4,
        year_id: 8,
        price: 44.90,
        currency: 'USD',
        annually_price: 449.0,
        contents: [
            ['1,000 MB ', 'Storage'],
            ['25 ', 'Custom Markers'],
            ['Unlimited ', 'Projects'],
            ['Unlimited ', 'QR Markers'],
            ['Unlimited ', '2D, 3D, AR Library'],
        ]
    },
    17:{ // EDU PLAN
        id: 17,
        name: 'Individual',
        plan: 'edu',
        type: 'monthly',
        priceEachMonth: 4.99,
        price: 4.99,
        currency: 'USD',
        annually_price: 49.90,
        month_id: 17,
        year_id: 21,
        contents: [
            ['3 ', 'Member Accounts'],
            ['Unlimited ', 'Virtual Classes'],
            ['Unlimited ', 'Access to Educational Topics'],
            ['Unlimited ', '3D & 2D Library'],
            ['300 MB ', 'Storage per Account'],
            ['5 ', ' Custom Markers per Account'],
            ['Unlimited ', 'QR Markers'],
            ['Offline Access ', '(Coming Soon)'],
        ]
    },
    127:{ // EDU PLAN
        id: 127,
        name: 'Individual',
        plan: 'edu',
        type: 'monthly',
        priceEachMonth: 4.50,
        price: 4.50,
        currency: 'USD',
        annually_price: 45.00,
        month_id: 127,
        year_id: 21,
        contents: [
            ['3 ', 'Member Accounts'],
            ['Unlimited ', 'Virtual Classes'],
            ['Unlimited ', 'Access to Educational Topics'],
            ['Unlimited ', '3D & 2D Library'],
            ['300 MB ', 'Storage per Account'],
            ['5 ', ' Custom Markers per Account'],
            ['Unlimited ', 'QR Markers'],
            ['Offline Access ', '(Coming Soon)'],
        ]
    },
    128:{ // EDU PLAN
        id: 128,
        name: 'Individual',
        plan: 'edu',
        type: 'monthly',
        priceEachMonth: 4.25,
        price: 4.25,
        currency: 'USD',
        annually_price: 42.50,
        month_id: 128,
        year_id: 21,
        contents: [
            ['3 ', 'Member Accounts'],
            ['Unlimited ', 'Virtual Classes'],
            ['Unlimited ', 'Access to Educational Topics'],
            ['Unlimited ', '3D & 2D Library'],
            ['300 MB ', 'Storage per Account'],
            ['5 ', ' Custom Markers per Account'],
            ['Unlimited ', 'QR Markers'],
            ['Offline Access ', '(Coming Soon)'],
        ]
    },
    129:{ // EDU PLAN
        id: 129,
        name: 'Individual',
        plan: 'edu',
        type: 'monthly',
        priceEachMonth: 4.00,
        price: 4.00,
        currency: 'USD',
        annually_price: 40.00,
        month_id: 129,
        year_id: 21,
        contents: [
            ['3 ', 'Member Accounts'],
            ['Unlimited ', 'Virtual Classes'],
            ['Unlimited ', 'Access to Educational Topics'],
            ['Unlimited ', '3D & 2D Library'],
            ['300 MB ', 'Storage per Account'],
            ['5 ', ' Custom Markers per Account'],
            ['Unlimited ', 'QR Markers'],
            ['Offline Access ', '(Coming Soon)'],
        ]
    },
    130:{ // EDU PLAN
        id: 130,
        name: 'Individual',
        plan: 'edu',
        type: 'monthly',
        priceEachMonth: 3.75,
        price: 3.75,
        currency: 'USD',
        annually_price: 37.50,
        month_id: 130,
        year_id: 21,
        contents: [
            ['3 ', 'Member Accounts'],
            ['Unlimited ', 'Virtual Classes'],
            ['Unlimited ', 'Access to Educational Topics'],
            ['Unlimited ', '3D & 2D Library'],
            ['300 MB ', 'Storage per Account'],
            ['5 ', ' Custom Markers per Account'],
            ['Unlimited ', 'QR Markers'],
            ['Offline Access ', '(Coming Soon)'],
        ]
    },
    131:{ // EDU PLAN
        id: 131,
        name: 'Individual',
        plan: 'edu',
        type: 'monthly',
        priceEachMonth: 3.50,
        price: 3.50,
        currency: 'USD',
        annually_price: 35.00,
        month_id: 131,
        year_id: 21,
        contents: [
            ['3 ', 'Member Accounts'],
            ['Unlimited ', 'Virtual Classes'],
            ['Unlimited ', 'Access to Educational Topics'],
            ['Unlimited ', '3D & 2D Library'],
            ['300 MB ', 'Storage per Account'],
            ['5 ', ' Custom Markers per Account'],
            ['Unlimited ', 'QR Markers'],
            ['Offline Access ', '(Coming Soon)'],
        ]
    },
    132:{ // EDU PLAN
        id: 132,
        name: 'Individual',
        plan: 'edu',
        type: 'monthly',
        priceEachMonth: 4.50,
        price: 4.50,
        currency: 'USD',
        annually_price: 45.00,
        month_id: 132,
        year_id: 21,
        contents: [
            ['3 ', 'Member Accounts'],
            ['Unlimited ', 'Virtual Classes'],
            ['Unlimited ', 'Access to Educational Topics'],
            ['Unlimited ', '3D & 2D Library'],
            ['300 MB ', 'Storage per Account'],
            ['5 ', ' Custom Markers per Account'],
            ['Unlimited ', 'QR Markers'],
            ['Offline Access ', '(Coming Soon)'],
        ]
    },
    133:{ // EDU PLAN
        id: 133,
        name: 'Individual',
        plan: 'edu',
        type: 'monthly',
        priceEachMonth: 4.25,
        price: 4.25,
        currency: 'USD',
        annually_price: 42.50,
        month_id: 133,
        year_id: 21,
        contents: [
            ['3 ', 'Member Accounts'],
            ['Unlimited ', 'Virtual Classes'],
            ['Unlimited ', 'Access to Educational Topics'],
            ['Unlimited ', '3D & 2D Library'],
            ['300 MB ', 'Storage per Account'],
            ['5 ', ' Custom Markers per Account'],
            ['Unlimited ', 'QR Markers'],
            ['Offline Access ', '(Coming Soon)'],
        ]
    },
    134:{ // EDU PLAN
        id: 134,
        name: 'Individual',
        plan: 'edu',
        type: 'monthly',
        priceEachMonth: 4.00,
        price: 4.00,
        currency: 'USD',
        annually_price: 40.00,
        month_id: 134,
        year_id: 21,
        contents: [
            ['3 ', 'Member Accounts'],
            ['Unlimited ', 'Virtual Classes'],
            ['Unlimited ', 'Access to Educational Topics'],
            ['Unlimited ', '3D & 2D Library'],
            ['300 MB ', 'Storage per Account'],
            ['5 ', ' Custom Markers per Account'],
            ['Unlimited ', 'QR Markers'],
            ['Offline Access ', '(Coming Soon)'],
        ]
    },
    135:{ // EDU PLAN
        id: 135,
        name: 'Individual',
        plan: 'edu',
        type: 'monthly',
        priceEachMonth: 3.75,
        price: 3.75,
        currency: 'USD',
        annually_price: 37.50,
        month_id: 135,
        year_id: 21,
        contents: [
            ['3 ', 'Member Accounts'],
            ['Unlimited ', 'Virtual Classes'],
            ['Unlimited ', 'Access to Educational Topics'],
            ['Unlimited ', '3D & 2D Library'],
            ['300 MB ', 'Storage per Account'],
            ['5 ', ' Custom Markers per Account'],
            ['Unlimited ', 'QR Markers'],
            ['Offline Access ', '(Coming Soon)'],
        ]
    },
    136:{ // EDU PLAN
        id: 136,
        name: 'Individual',
        plan: 'edu',
        type: 'monthly',
        priceEachMonth: 3.50,
        price: 3.50,
        currency: 'USD',
        annually_price: 35.00,
        month_id: 136,
        year_id: 21,
        contents: [
            ['3 ', 'Member Accounts'],
            ['Unlimited ', 'Virtual Classes'],
            ['Unlimited ', 'Access to Educational Topics'],
            ['Unlimited ', '3D & 2D Library'],
            ['300 MB ', 'Storage per Account'],
            ['5 ', ' Custom Markers per Account'],
            ['Unlimited ', 'QR Markers'],
            ['Offline Access ', '(Coming Soon)'],
        ]
    },
    145:{ // EDU PLAN
        id: 145,
        name: 'Single Plan',
        plan: 'edu',
        type: 'monthly',
        priceEachMonth: 35000,
        price: 35000,
        currency: 'IDR',
        annually_price: 35000,
        month_id: 145,
        year_id: 21,
        contents: [
            ['Unlimited ', 'Virtual Classes'],
            ['Unlimited ', 'Access to Educational Topics'],
            ['Unlimited ', '3D & 2D Library'],
            ['300 MB ', 'Storage per Account'],
            ['5 ', ' Custom Markers per Account'],
            ['Unlimited ', 'QR Markers'],
            ['Offline Access ', '(Coming Soon)'],
        ]
    },
    146:{ // EDU PLAN
        id: 146,
        name: 'Single Plan',
        plan: 'edu',
        type: 'yearly',
        priceEachMonth: 35000,
        price: 350000,
        currency: 'IDR',
        annually_price: 350000,
        month_id: 146,
        year_id: 21,
        contents: [
            ['Unlimited ', 'Virtual Classes'],
            ['Unlimited ', 'Access to Educational Topics'],
            ['Unlimited ', '3D & 2D Library'],
            ['300 MB ', 'Storage per Account'],
            ['5 ', ' Custom Markers per Account'],
            ['Unlimited ', 'QR Markers'],
            ['Offline Access ', '(Coming Soon)'],
        ]
    },
    147:{ // EDU PLAN
        id: 147,
        name: 'Single Plan',
        plan: 'edu',
        type: 'monthly',
        priceEachMonth: 25000,
        price: 25000,
        currency: 'IDR',
        annually_price: 250000,
        month_id: 147,
        year_id: 21,
        contents: [
            ['Unlimited ', 'Virtual Classes'],
            ['Unlimited ', 'Access to Educational Topics'],
            ['Unlimited ', '3D & 2D Library'],
            ['300 MB ', 'Storage per Account'],
            ['5 ', ' Custom Markers per Account'],
            ['Unlimited ', 'QR Markers'],
            ['Offline Access ', '(Coming Soon)'],
        ]
    },
    21:{
        id: 21,
        name: 'Individual',
        plan: 'edu',
        type: 'annually',
        priceEachMonth: 4.99,
        price: 4.99,
        currency: 'USD',
        annually_price: 49.90,
        month_id: 17,
        year_id: 21,
        contents: [
            ['3 ', 'Member Accounts'],
            ['Unlimited ', 'Virtual Classes'],
            ['Unlimited ', 'Access to Educational Topics'],
            ['Unlimited ', '3D & 2D Library'],
            ['300 MB ', 'Storage per Account'],
            ['5 ', ' Custom Markers per Account'],
            ['Unlimited ', 'QR Markers'],
            ['Offline Access ', '(Coming Soon)'],
        ]
    },
    100:{
        id: 100,
        name: 'School/Institutional',
        plan: 'edu',
        type: 'annually',
        month_id: 100,
        year_id: 100,
        currency: 'USD',
        priceEachMonth: 10,
        annually_price: 10,
        price: {
            tier1: 15,
            tier2: 12,
            tier3: 10,
        },
        contents: [
            ['1 ', 'Member Seats'],
            ['5 ', 'Custom Markers Per Account'],
            ['300 MB ', 'Storage Per Account'],
            ['Offline ', 'Access'],
            ['Unlimited ', 'QR Markers'],
            ['Unlimited ', '2D, 3D, AR Library'],
            ['Unlimited ', 'Virtual Class'],
            ['Unlimited ', 'Educational Contents'],
        ],
    },
    109:{
        id: 109,
        name: 'Individual three month (ACE Reward)',
        plan: 'edu',
        type: 'monthly',
        month_id: 109,
        year_id: 109,
        currency: 'USD',
        priceEachMonth: 1,
        price: {
            tier1: 15,
            tier2: 12,
            tier3: 10,
        },
        contents: [
            ['1 ', 'Member Seats'],
            ['5 ', 'Custom Markers Per Account'],
            ['300 MB ', 'Storage Per Account'],
            ['Offline ', 'Access'],
            ['Unlimited ', 'QR Markers'],
            ['Unlimited ', '2D, 3D, AR Library'],
            ['Unlimited ', 'Virtual Class'],
            ['Unlimited ', 'Educational Contents'],
        ],
    },
    111:{
        id: 111,
        name: 'School Premium (with 5 markers)',
        plan: 'edu',
        type: 'annually',
        month_id: 111,
        year_id: 111,
        currency: 'USD',
        priceEachMonth: 1,
        price: {
            tier1: 15,
            tier2: 12,
            tier3: 10,
        },
        contents: [
            ['1 ', 'Member Seats'],
            ['5 ', 'Custom Markers Per Account'],
            ['300 MB ', 'Storage Per Account'],
            ['Offline ', 'Access'],
            ['Unlimited ', 'QR Markers'],
            ['Unlimited ', '2D, 3D, AR Library'],
            ['Unlimited ', 'Virtual Class'],
            ['Unlimited ', 'Educational Contents'],
        ],
    },
    113:{
        id: 113,
        name: 'School Premium (with 10 markers)',
        plan: 'edu',
        type: 'annually',
        month_id: 113,
        year_id: 113,
        currency: 'USD',
        priceEachMonth: 1,
        price: {
            tier1: 15,
            tier2: 12,
            tier3: 10,
        },
        contents: [
            ['1 ', 'Member Seats'],
            ['10 ', 'Custom Markers Per Account'],
            ['300 MB ', 'Storage Per Account'],
            ['Offline ', 'Access'],
            ['Unlimited ', 'QR Markers'],
            ['Unlimited ', '2D, 3D, AR Library'],
            ['Unlimited ', 'Virtual Class'],
            ['Unlimited ', 'Educational Contents'],
        ],
    },
    119:{
        id: 119,
        name: 'Individual Lifetime (ACE Reward)',
        plan: 'edu',
        type: 'annually',
        month_id: 119,
        year_id: 119,
        currency: 'USD',
        priceEachMonth: 1,
        price: {
            tier1: 15,
            tier2: 12,
            tier3: 10,
        },
        contents: [
            ['1 ', 'Member Seats'],
            ['5 ', 'Custom Markers Per Account'],
            ['300 MB ', 'Storage Per Account'],
            ['Offline ', 'Access'],
            ['Unlimited ', 'QR Markers'],
            ['Unlimited ', '2D, 3D, AR Library'],
            ['Unlimited ', 'Virtual Class'],
            ['Unlimited ', 'Educational Contents'],
        ],
    },
    122:{ // EDU PLAN
        id: 122,
        name: 'Individual 2 Months',
        plan: 'edu',
        type: 'monthly',
        priceEachMonth: 4.99,
        price: 4.99,
        currency: 'USD',
        annually_price: 49.90,
        month_id: 122,
        year_id: 21,
        contents: [
            ['Get 3 ', 'Member Seats'],
            ['Unlimited ', 'Virtual Class'],
            ['Unlimited ', 'Educational Contents'],
            ['Unlimited ', '3D & 2D Library'],
            ['300 MB ', 'Storage per Account'],
            ['5 ', 'Custom Markers for each seats'],
            ['Unlimited ', 'QR Markers'],
            ['Offline Access ', '(Coming Soon)'],
        ]
    },
    20:{ // AR MARKER
        id: 20,
        month_id: 20,
        name: 'Custom Marker',
        plan: 'marker',
        type: 'one-time',
        currency: 'USD',
        priceEachMonth: 3,
        price: {
            tier1: 2.99,
            tier2: 13.99,
            tier3: 24.99,
        },
        contents: [
            ['One-Time ', 'Purchase'],
            ['No ', 'Expiration Date'],
        ],
    },
    137:{ // AR MARKER
        id: 137,
        month_id: 137,
        name: 'Custom Marker',
        plan: 'marker',
        type: 'one-time',
        currency: 'USD',
        priceEachMonth: 3.25,
        price: {
            tier1: 2.99,
            tier2: 13.99,
            tier3: 24.99,
        },
        contents: [
            ['One-Time ', 'Purchase'],
            ['No ', 'Expiration Date'],
        ],
    },
    138:{ // AR MARKER
        id: 138,
        month_id: 138,
        name: 'Custom Marker',
        plan: 'marker',
        type: 'one-time',
        currency: 'USD',
        priceEachMonth: 3.00,
        price: {
            tier1: 2.99,
            tier2: 13.99,
            tier3: 24.99,
        },
        contents: [
            ['One-Time ', 'Purchase'],
            ['No ', 'Expiration Date'],
        ],
    },
    139:{ // AR MARKER
        id: 139,
        month_id: 139,
        name: 'Custom Marker',
        plan: 'marker',
        type: 'one-time',
        currency: 'USD',
        priceEachMonth: 2.75,
        price: {
            tier1: 2.99,
            tier2: 13.99,
            tier3: 24.99,
        },
        contents: [
            ['One-Time ', 'Purchase'],
            ['No ', 'Expiration Date'],
        ],
    },
    140:{ // AR MARKER
        id: 140,
        month_id: 140,
        name: 'Custom Marker',
        plan: 'marker',
        type: 'one-time',
        currency: 'USD',
        priceEachMonth: 2.50,
        price: {
            tier1: 2.99,
            tier2: 13.99,
            tier3: 24.99,
        },
        contents: [
            ['One-Time ', 'Purchase'],
            ['No ', 'Expiration Date'],
        ],
    },
    141:{ // AR MARKER
        id: 141,
        month_id: 141,
        name: 'Custom Marker',
        plan: 'marker',
        type: 'one-time',
        currency: 'USD',
        priceEachMonth: 3.25,
        price: {
            tier1: 2.99,
            tier2: 13.99,
            tier3: 24.99,
        },
        contents: [
            ['One-Time ', 'Purchase'],
            ['No ', 'Expiration Date'],
        ],
    },
    142:{ // AR MARKER
        id: 142,
        month_id: 142,
        name: 'Custom Marker',
        plan: 'marker',
        type: 'one-time',
        currency: 'USD',
        priceEachMonth: 3.00,
        price: {
            tier1: 2.99,
            tier2: 13.99,
            tier3: 24.99,
        },
        contents: [
            ['One-Time ', 'Purchase'],
            ['No ', 'Expiration Date'],
        ],
    },
    143:{ // AR MARKER
        id: 143,
        month_id: 143,
        name: 'Custom Marker',
        plan: 'marker',
        type: 'one-time',
        currency: 'USD',
        priceEachMonth: 2.75,
        price: {
            tier1: 2.99,
            tier2: 13.99,
            tier3: 24.99,
        },
        contents: [
            ['One-Time ', 'Purchase'],
            ['No ', 'Expiration Date'],
        ],
    },
    144:{ // AR MARKER
        id: 144,
        month_id: 144,
        name: 'Custom Marker',
        plan: 'marker',
        type: 'one-time',
        currency: 'USD',
        priceEachMonth: 2.50,
        price: {
            tier1: 2.99,
            tier2: 13.99,
            tier3: 24.99,
        },
        contents: [
            ['One-Time ', 'Purchase'],
            ['No ', 'Expiration Date'],
        ],
    },
    118:{
        id: 118,
        month_id: 118,
        name: 'Custom Marker (ACE Reward)',
        plan: 'marker',
        type: 'one-time',
        currency: 'USD',
        priceEachMonth: 3,
        price: {
            tier1: 2.99,
            tier2: 13.99,
            tier3: 24.99,
        },
        contents: [
            ['5 ', 'Custom Marker Per Account'],
            ['One-Time ', 'Purchase'],
            ['No ', 'Expiration Date'],
        ],
    },
    120:{
        id: 120,
        name: 'School Premium (with 5 markers)',
        plan: 'edu',
        type: 'annually',
        month_id: 120,
        year_id: 120,
        currency: 'USD',
        priceEachMonth: 1,
        price: {
            tier1: 15,
            tier2: 12,
            tier3: 10,
        },
        contents: [
            ['1 ', 'Member Seats'],
            ['5 ', 'Custom Markers Per Account'],
            ['300 MB ', 'Storage Per Account'],
            ['Offline ', 'Access'],
            ['Unlimited ', 'QR Markers'],
            ['Unlimited ', '2D, 3D, AR Library'],
            ['Unlimited ', 'Virtual Class'],
            ['Unlimited ', 'Educational Contents'],
        ],
    },
    123:{
        id: 123,
        name: 'School Premium (with 7 markers)',
        plan: 'edu',
        type: 'annually',
        month_id: 123,
        year_id: 123,
        currency: 'USD',
        priceEachMonth: 1,
        price: {
            tier1: 15,
            tier2: 12,
            tier3: 10,
        },
        contents: [
            ['1 ', 'Member Seats'],
            ['7 ', 'Custom Markers Per Account'],
            ['300 MB ', 'Storage Per Account'],
            ['Offline ', 'Access'],
            ['Unlimited ', 'QR Markers'],
            ['Unlimited ', '2D, 3D, AR Library'],
            ['Unlimited ', 'Virtual Class'],
            ['Unlimited ', 'Educational Contents'],
        ],
    },
    124:{
        id: 124,
        name: 'School Premium (with 10 markers)',
        plan: 'edu',
        type: 'annually',
        month_id: 124,
        year_id: 124,
        currency: 'USD',
        priceEachMonth: 1,
        price: {
            tier1: 15,
            tier2: 12,
            tier3: 10,
        },
        contents: [
            ['1 ', 'Member Seats'],
            ['10 ', 'Custom Markers Per Account'],
            ['300 MB ', 'Storage Per Account'],
            ['Offline ', 'Access'],
            ['Unlimited ', 'QR Markers'],
            ['Unlimited ', '2D, 3D, AR Library'],
            ['Unlimited ', 'Virtual Class'],
            ['Unlimited ', 'Educational Contents'],
        ],
    },
    103:{
        id: 103,
        month_id: 103,
        name: 'Blr Coin',
        plan: 'marker',
        type: 'one-time',
        currency: 'USD',
        price: 0.99,
        priceEachMonth: 0.99,
        idIAP: 103,
        idMidtrans: 150,
        idStripe: 153,
        month_id: 103,
        priceIAP: 0.99,
        priceMidtrans: 14000,
        priceStripe: 1,
        contents: [
            ['Can be used to purchase various items in Assemblr ecosystem'],
        ],
    },
    104:{
        id: 104,
        month_id: 104,
        name: 'Blr Coin',
        plan: 'marker',
        type: 'one-time',
        currency: 'USD',
        price: 0.99,
        priceEachMonth: 0.99,
        contents: [
            ['Can be used to purchase various items in Assemblr ecosystem'],
        ],
    },
    105:{
        id: 105,
        month_id: 105,
        name: 'Blr Coin',
        plan: 'marker',
        type: 'one-time',
        currency: 'USD',
        price: 0.99,
        priceEachMonth: 0.99,
        contents: [
            ['Can be used to purchase various items in Assemblr ecosystem'],
        ],
    },
    150:{
        id: 150,
        month_id: 150,
        name: 'Blr Coin',
        plan: 'marker',
        type: 'one-time',
        currency: 'IDR',
        price: 140,
        priceEachMonth: 140,
        priceUSD: 0.01,
        contents: [
            ['Can be used to purchase various items in Assemblr ecosystem'],
        ],
    },
    151:{
        id: 151,
        month_id: 151,
        name: 'Blr Coin',
        plan: 'marker',
        type: 'one-time',
        currency: 'IDR',
        price: 140,
        priceEachMonth: 140,
        priceUSD: 0.01,
        contents: [
            ['Can be used to purchase various items in Assemblr ecosystem'],
        ],
    },
    152:{
        id: 152,
        month_id: 152,
        name: 'Blr Coin',
        plan: 'marker',
        type: 'one-time',
        currency: 'IDR',
        price: 140,
        priceEachMonth: 140,
        priceUSD: 0.01,
        contents: [
            ['Can be used to purchase various items in Assemblr ecosystem'],
        ],
    },
    153:{
        id: 150,
        month_id: 150,
        name: 'Blr Coin',
        plan: 'marker',
        type: 'one-time',
        currency: 'USD',
        price: 140,
        priceEachMonth: 140,
        priceUSD: 0.01,
        contents: [
            ['Can be used to purchase various items in Assemblr ecosystem'],
        ],
    },
    154:{
        id: 151,
        month_id: 151,
        name: 'Blr Coin',
        plan: 'marker',
        type: 'one-time',
        currency: 'USD',
        price: 140,
        priceEachMonth: 140,
        priceUSD: 0.01,
        contents: [
            ['Can be used to purchase various items in Assemblr ecosystem'],
        ],
    },
    155:{
        id: 152,
        month_id: 152,
        name: 'Blr Coin',
        plan: 'marker',
        type: 'one-time',
        currency: 'USD',
        price: 140,
        priceEachMonth: 140,
        priceUSD: 0.01,
        contents: [
            ['Can be used to purchase various items in Assemblr ecosystem'],
        ],
    },
}

export default PLANS;