import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import './index.css';
import Payment from './views/Payment/index.js'
import Checkout from './views/Checkout';
import Redeem from "./views/Redeem";
import Seat from "./views/Seat";
import PaymentStatus from "./views/PaymentStatus";
import { useEffect, useState } from "react";
import { Provider } from 'react-redux'
import store from './store'
import NewTab from "./views/Checkout/newTab";
import queryString from 'query-string'
import NewCheckout from "./views/NewCheckout";
import CheckoutBundle from "./views/CheckoutBundle";
import CheckoutItem from "./views/CheckoutItem";

function App() {

  function getToken() {
    document.getElementById('get-token').click();
    console.log(document.getElementById('get-token').click());
    return document.getElementById('get-token').value;
  }

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search)
    if (queryParams.closeButton === 'true') {
      window.closeButton = true
    } else {
      window.closeButton = false
    }
  }, [])

  useEffect(()=> {
    window.addEventListener('resize', ()=> {
        console.log(window.innerHeight, window.innerWidth, 'window.innerWidthwindow.innerWidth')
    })
		console.log(window.innerHeight, window.innerWidth, 'window.innerWidthwindow.innerWidth')
	}, [])

  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Navigate to="/Plan"/>} />
            <Route path='/Overview' element={<Payment />} />
            <Route path='/Plan' element={<Payment />} />
            <Route path='/Marker' element={<Payment />} />
            <Route path='/Redeem' element={<Redeem />} />
            <Route path='/Seat' element={<Seat />} />
            <Route path='/Checkout/:id' element={<Checkout />} />
            <Route path='/NewCheckout/:id' element={<NewCheckout />} />
            <Route path='/CheckoutBundle' element={<CheckoutBundle />} />
            <Route path='/CheckoutItem' element={<CheckoutItem />} />
            <Route path='/Stripe' element={<NewTab />} />
            <Route path='/Success' element={<PaymentStatus />} />
            <Route path='/SuccessRedeem' element={<PaymentStatus />} />
            <Route path='/SuccessBundle' element={<PaymentStatus />} />
            <Route path='/SuccessCheckoutBundle' element={<PaymentStatus />} />
            <Route path='/SuccessCheckoutItem' element={<PaymentStatus />} />
            <Route path='/Failed' element={<PaymentStatus />} />
            <Route path='/Waiting' element={<PaymentStatus />} />
            <Route path='/WaitingPlan' element={<PaymentStatus />} />
            <Route path='/SuccessTopupBlr' element={<PaymentStatus />} />
            <Route path='/WaitingTopup' element={<PaymentStatus />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
