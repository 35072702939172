import { Fragment, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation, useNavigate } from 'react-router-dom';
import { IconSuccess } from '../../icons';
import './index.scss';
import queryString from 'query-string'
import instance from '../../configs/axios';

function PaymentStatus(params) {
    const navigate = useNavigate()
    const isMobile = useMediaQuery({ maxWidth: 760 })
    const type = useLocation()
    const [purchaseData, setPurchaseData] = useState({})
    const [loading, setLoading] = useState(false)
    const [text, setText] = useState({
        header: '',
        body: ''
    })
    const [paymentStatus, setPaymentStatus] = useState(false)
    const queryParams = queryString.parse(window.location.search)
    const successPathname = ['/SuccessCheckoutBundle', '/SuccessTopupBlr', '/SuccessBundle', '/Success']

    useEffect(() => {
        switch (type.pathname) {
            case '/SuccessRedeem':
                window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                window.dataLayer.push({
                event: "purchase",
                ecommerce: {
                    status: "Item Purchased"
                }
                });
                setText({
                    header: 'Redeem Voucher Success',
                    body: 'We received your voucher code, check your profile to see if that your voucher is activated'
                })
                break;
            case '/Failed':
                setText({
                    header: "We Can't Proceed Your Purchase...",
                    body: 'Make sure the data that you fill in earlier are correct and try again later.'
                })
                break;
            case '/Waiting':
                window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                window.dataLayer.push({
                event: "waiting_payment",
                ecommerce: {
                    status: "Waiting Payment"
                }
                });
                setText({
                    header: 'Tick-tock... Still Waiting for Your Payment',
                    body: "You're one step closer! Go proceed with your payment to unlock the pack wink"
                })
                break;
            case '/WaitingPlan':
                window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                window.dataLayer.push({
                event: "waiting_payment",
                ecommerce: {
                    status: "Waiting Payment"
                }
                });
                setText({
                    header: 'Tick-tock... Still Waiting for Your Payment',
                    body: "You're one step closer! Go proceed with your payment to unlock the pack wink"
                })
                break;
                case '/WaitingTopup':
                    window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                    window.dataLayer.push({
                    event: "waiting_payment",
                    ecommerce: {
                        status: "Waiting Payment"
                    }
                    });
                    setText({
                        header: 'Tick-tock... Still Waiting for Your Payment',
                        body: "You're one step closer! Go proceed with your payment to unlock the pack wink"
                    })
                    break;
            default:
                break;
        }
    }, [type.pathname])

    useEffect(() => {
        if (paymentStatus) {
            navigate('/SuccessBundle?checkout=library')
        }
    }, [paymentStatus])

    const checkStatus = async () => {
        if (type.pathname === '/WaitingPlan') {
            const {data} = await instance.get(`/api/v2/features/personal/`, {
                headers: {
                    Authorization: queryParams.token
                }
            })
            if (data.plans_active.length) {
                let item = data.plans_active.filter((e) => e.invoice === localStorage.getItem("order_id"))
                if (item.length) {
                    setPaymentStatus(true)
                } else {
                    setText({
                        header: 'Payment in the Queue...',
                        body: "Your payment has been processed, and we're currently on it. Just sit tight and relax!"
                    })
                }
            } else {
                setText({
                    header: 'Payment in the Queue...',
                    body: "Your payment has been processed, and we're currently on it. Just sit tight and relax!"
                })
            }
        }
        if (type.pathname === '/Waiting') {
            const {data} = await instance.get(`/api/v2/transactions/library/status?order_id=${localStorage.getItem("order_id")}`, {
                headers: {
                    Authorization: queryParams.token
                }
            })
            let status = data.status
            if (status.transaction_status.toLowerCase() === 'settlement' && typeof status.transaction_status !== 'undefined') {
                setPaymentStatus(true)
            } else {
                setText({
                    header: 'Payment in the Queue...',
                    body: "Your payment has been processed, and we're currently on it. Just sit tight and relax!"
                })
            }
        }
        if (type.pathname === '/WaitingTopup') {
            setLoading(true)
            const {data, status} = await instance.get(`api/plans/status/stripe/${localStorage.getItem('session-id')}`, {
                headers: {
                    Authorization: queryParams.token
                }
            })
            if (status === 200) {
                setLoading(false)
                if (data.payment_status === 'paid') {
                    setPaymentStatus(true)
                } else {
                    setText({
                        header: 'Payment in the Queue...',
                        body: "Your payment has been processed, and we're currently on it. Just sit tight and relax!"
                    })
                }
            } else {
                setLoading(false)
                setText({
                    header: 'Error' + status,
                    body: "Try again"
                })
            }
        }
    }

    const getItem = async () => {
        let {data} = await instance.get(`/api/v2/transactions/library/list-payment`, {
            headers: {
                'Authorization' : queryParams.token
            }
        })
        let itemList = data.status.library
        console.log(itemList);
        let bundle = itemList.filter((item) => item.id === +queryParams.id_bundle)[0]
        let item = bundle.item.filter((item) => item.id_market === +queryParams.item_id)[0]
        console.log(bundle, item, queryParams.id_bundle, 'queryParamsqueryParams');
        let user = await getUser(item.owner)
        const bundleData = {
            name: item.name,
            owner: user,
            thumbnail: item.thumbnail,
            bundleName: item.name_bundle,
            bundleType: item.type_bundle
        }
        setPurchaseData(bundleData)
    }

    const getUser = async (uid) => {
        let {data} = await instance.get(`/api/user-profile/${uid}`)
        console.log(data);
        return data.data.Name
    }
    
    useEffect(() => {
        if (queryParams?.item_id) {
            console.log(queryParams);
            getItem()
            // let {data} = await instance.get(`/api/v2/transactions/library/list-payment`, {
            //     headers: {
            //         'Authorization' : queryParams.token
            //     }
            // })
            // let itemList = data.status.library
            // console.log(itemList);
            // let item = itemList.filter((item) => item.id === +queryParams.item_id)[0]
            // console.log(item);
            // const bundleData = {
            //     name: item.item3D.name,
            //     thumbnail: item.item3D.thumbnail
            //     // owner: item.item3D.owner
            // }
            // localStorage.setItem('purchase-data', JSON.stringify(bundleData))
            // setPurchaseData(bundleData)
        } else setPurchaseData(JSON.parse(localStorage.getItem('purchase-data')))
    }, [])

    const SuccessPurchaseBundle = () => (
        <div className='top-banner'>
            {type.pathname === '/SuccessCheckoutItem' ?
            <section className='checkout-item'>
                <img className='image right' src={process.env.PUBLIC_URL+'/assets/SuccessItemCheckout.svg'} alt="" />
                <img className='image left' src={process.env.PUBLIC_URL+'/assets/SuccessItemCheckout.svg'} alt="" />
                <img className='image' src={process.env.PUBLIC_URL+'/assets/SuccessItem.png'} alt="" />
                <h1 className='checkout-name'>3D Object Purchased</h1>
                <p className='checkout-desc'>Enjoy the packs and get ready to create stunning creation!</p>
                <section>
                    <img className='checkout-img' src={purchaseData.thumbnail} alt="" />
                    <section>
                        <h4>{purchaseData.name}</h4>
                        {purchaseData.owner ?
                        <h5>from {purchaseData.bundleName} - by {purchaseData.owner}</h5> : null
                        }
                    </section>
                    {purchaseData.bundleType === "Premium" && (
                    <div className="pro-signed pro-emblem">
                        <img src={process.env.PUBLIC_URL + `${purchaseData.bundleType === "Exclusive" ? '/assets/icon_star.svg' : '/assets/ic_crown2.svg'}`} alt="pro packs"/>
                        Pro
                    </div>
                    )}

                    {purchaseData.bundleType === "Exclusive" && (
                    <div className="pro-signed special header-emblem">
                        <img src={process.env.PUBLIC_URL + `${purchaseData.bundleType === "Exclusive" ? '/assets/icon_star.svg' : '/assets/ic_crown2.svg'}`} alt="pro packs"/>
                        Special
                    </div>
                    )}
                </section>
                <button onClick={() => {
                    window.parent.postMessage({
                        id: "close success payment"
                    }, "*")
                }} className='button-view'>
                    View Item
                </button>
            </section>
            :
            <>
                <img src={process.env.PUBLIC_URL+'/assets/success_banner.png'} alt="" />
                <h1>{type.pathname === '/SuccessTopupBlr' ? 'Successfull Coin Purchase' : 'Successfull Bundle Purchase'}</h1>
                <h3>You earned</h3>
                {type.pathname === '/SuccessTopupBlr' ? (
                    <section className='blr-desc'>
                        <img className='blr-icon' src={process.env.PUBLIC_URL+'/assets/blr_coin.png'} alt="" />
                        <span>{localStorage.getItem('coin')}</span>
                        <span>BLR Coin</span>
                    </section>
                ) : (
                    <section className='banner-bundle'>
                        <div className='banner-image'><img src={purchaseData?.thumbnail} alt='' /></div>
                        <div>
                            <span>{purchaseData?.name}</span>
                            <span><b>{purchaseData?.item} Object{purchaseData?.item > 1 ? 's' : null}</b></span>
                        </div>
                    </section> 
                )}
                {((window === window.parent)) ? (
                    <div className="success-button-container">
                        <div 
                            className={`success-button ${(type.pathname === '/SuccessTopupBlr') ? 'green-button' : ''}`}
                            onClick={() => {
                                if (type.pathname === '/SuccessTopupBlr') window.close()
                                else {
                                    localStorage.removeItem('purchase-data')
                                    window.parent.postMessage("close-checkout-iframe", "*")
                                    window.parent.postMessage({ id: "close-iframe" }, "*")
                                    window.parent.postMessage("close popup", "*")
                                }
                            }}
                        >
                            {type.pathname === '/SuccessTopupBlr' ? 'Close and return to your previous page' : 'View Bundle'}
                        </div>
                    </div>
                ) : null}
            </>
            }
            
        </div>
    )

    return (
        <div className='Main status'>
            <div className='nav'>
                {queryParams.checkout === "library" && !queryParams.no_close_button ?
                <div className='header'>
                    <span onClick={() => {
                    window.parent.postMessage("close popup redeem", "*")
                    }} className="material-icons close-popup right">
                        close
                    </span>
                </div>
                : null
                }
            </div>
            <div 
                className={`contents ${(successPathname.includes(type.pathname)) ? 'new': null}`}>
                {(successPathname.includes(type.pathname) || (type.pathname === '/SuccessCheckoutItem')) && <SuccessPurchaseBundle />}
                {(type.pathname === '/SuccessRedeem') &&
                <IconSuccess className='image'/>
                }
                {type.pathname === '/Failed' &&
                <img src={process.env.PUBLIC_URL+'/assets/Failedblr.png'} className='image-retina' alt=""/>
                }
                {(type.pathname === '/Waiting' || type.pathname === '/WaitingPlan' || type.pathname === '/WaitingTopup') &&
                <img src={process.env.PUBLIC_URL+'/assets/Waitingblr.png'} className='image-retina' alt=""/>
                }
                {(!successPathname.includes(type.pathname)) &&
                    <Fragment>
                        <p className='header'>{text.header}</p>
                        <p className={`header desc ${!isMobile ? 'tablet' : ''}`}>{text.body}</p>
                    </Fragment>
                }
                {/* {(type.pathname === '/Success' && !queryParams.embed) &&
                <div onClick={() => {
                    navigate('/Overview')
                }}
                className={`button ${!isMobile ? 'tablet' : ''}`}>
                    Check Your Plan
                </div>
                } */}
                {/* {(type.pathname === '/Failed' && !queryParams.embed)&&
                <div onClick={() => {
                    navigate('/Plan')
                }}
                className={`button failed ${!isMobile ? 'tablet' : ''}`}>
                    Try Again
                </div>
                } */}
                {type.pathname === '/Waiting' &&
                <>
                    <div onClick={() => {
                        checkStatus()
                    }} className={`button waiting ${!isMobile ? 'tablet' : ''}`}>
                        Check Payment Status
                    </div>
                </>
                }
                {type.pathname === '/WaitingPlan' &&
                <>
                    <div onClick={() => {
                        checkStatus()
                    }} className={`button waiting ${!isMobile ? 'tablet' : ''}`}>
                        Check Payment Status
                    </div>
                </>
                }
                {type.pathname === '/WaitingTopup' &&
                    <div onClick={() => {
                        checkStatus()
                    }} className={`button waiting ${!isMobile ? 'tablet' : ''}`}>
                        Check Payment Status
                    </div>
                }
                {loading && 
                    <div className='loader-container'>
                        <div className='loader'></div>
                    </div>
                }
            </div>
        </div>
    )
}

export default PaymentStatus