import '../NewCheckout/index.scss';
import '../Payment/index.scss'
import { useState, useEffect } from 'react';
import {IconFree, IconBasic, IconMedium, IconPremium, IconIndividual, IconSchool, IconCustomMarker} from '../../icons';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import PLANS from '../../configs/Plans'
import { useMediaQuery } from 'react-responsive';
import instance from '../../configs/axios';
import { loadStripe } from '@stripe/stripe-js';
import queryString from 'query-string'
import jwt_decode from "jwt-decode";
import * as Airtable from 'airtable'
import { useDebounce } from 'use-debounce'
import Card from '../../components/Card';
import { ReactComponent as StarIcon } from "../../icons/icon_star.svg";

function CheckoutBundle() {
    const stripePromise = loadStripe((process.env.NODE_ENV==='production' && !window.location.hostname.includes('192.168'))?process.env.REACT_APP_STRIPE_TOKEN:process.env.REACT_APP_STRIPE_TOKEN_DEV);
    const isMobile = useMediaQuery({ maxWidth: 760 })
    const isLandscape = useMediaQuery({ orientation: 'landscape'})
    let params = useParams()
    let location = useLocation()
    let navigate = useNavigate()
    const [token, setToken] = useState('')
    // const [planDetail, setPlanDetail] = useState(PLANS[params.id])
    const [period, setPeriod] = useState()
    const [duration, setDuration] = useState()
    const [method, setMethod] = useState()
    const [scheme, setScheme] = useState()
    const [amount, setAmount] = useState(0)
    const [total, setTotal] = useState(`0`)
    const [customDuration, setCustomDuration] = useState(1)
    const [embed, setEmbed] = useState(false)
    const [uid, setUID] = useState('')
    const [popup, setPopup] = useState('')
    const [term, setTerm] = useState(false)
    const [newsletter, setNewsLetter] = useState(false)
    const [payload, setPayload] = useState({
        cancel_url : '',
        firebase_token : token,
        payments: '',
        plans: '',
        quantity: '',
        success_url: '',
        api_url: ''
    })
    const [loading, setLoading] = useState(false)
    const [voucher, setVoucher] = useState('')
    const [currentVoucher, setCurrentVoucher] = useState('')
    const [voucherDetail, setVoucherDetail] = useState({})
    const [errorVoucher, setErrorVoucher] = useState('')
    const [samePlan, setSamePlan] = useState(false)
    const [sameDuration, setSameDuration] = useState(false)
    const [first, setFirst] = useState(true)
    const [priceTotal, setPriceTotal] = useState('0')
    const [planPrice, setPlanPrice] = useState('0')
    const [count, setCount] = useState(0)
    const [quantity, setQuantity] = useState(0)
    const [planDuration, setPlanDuration] = useState('')
    const [flow, setFlow] = useState('')
    const [tempAmount] = useDebounce(amount, 1500)
    const [tempVoucher] = useDebounce(voucher, 1000)
    const [firstLoading, setFirstLoading] = useState(false)
    const [close, setClose] = useState(false)
    const [isBundle, setIsBundle] = useState(false);
    const [idBundle, setIdBundle] = useState("");
    const [ownerBundle, setOwnerBundle] = useState("");
    const [bundleDetail, setBundleDetail] = useState({
        name: "",
        thumbnail: "",
        price: "0",
        bundle: 0
    })
    const [summaryDropdown, setSummaryDropdown] = useState(true)

    const ViewPlanTracking = (payload) => {
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: "view_item",
            ecommerce: {
                items: [
                    {
                        item_id: payload.id,
                        item_name: payload.name,
                        item_category: payload.type,
                        item_list_id: `bundle_item_${payload.id}`,
                        item_list_name: `${payload.name}`,
                        currency: "USD",
                        price: payload.price_in_usd
                    }
                ]
            }
        });
    }

    const CheckoutTracking = () => {
        let payment_method = ''
        let payment_service = ''
        switch (method) {
            case 0:
                payment_method = 'credit card'
                payment_service = 'stripe'
                break;
        
            case 1:
                payment_method = 'bank transfer'
                payment_service = 'midtrans'
                break;
        
            case 2:
                payment_method = 'qris'
                payment_service = 'midtrans'
                break;
        
            default:
                break;
        }
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: "begin_checkout",
            ecommerce: {
                items: [
                    {
                        item_id: bundleDetail.bundle,
                        item_name: bundleDetail.name,
                        discount: Object.keys(voucherDetail).length ? voucherDetail.benefit : 0,
                        item_category: bundleDetail.type,
                        item_list_id: `bundle_item_${bundleDetail.id}`,
                        item_list_name: `${bundleDetail.name}`,
                        currency: "USD",
                        price: bundleDetail.price,
                        payment_method,
                        payment_service,
                    }
                ]
            }
        });
    }

    const checkboxInput = (type) => {
        switch (type) {
            case 'term':
                setTerm(!term)
                break;
        
            case 'newsletter':
                setNewsLetter(!newsletter)
                break;
        
            default:
                break;
        }
    }

    const checkVoucher = async (type) => {
        try {
            setLoading(true)
            setCurrentVoucher(voucher)
            if (flow === 'redeem' && type !== 'same' && type !== 'different') {
                setTimeout(() => {
                    setFirstLoading(false)
                }, 2000)
            }
            switch (type) {
                case 'same':
                    setErrorVoucher('')
                    setLoading(false)
                    break;
            
                case 'different':
                    setErrorVoucher(`Voucher is only eligible for ${voucherDetail.quantity} months plan.`)
                    setLoading(false)
                    break;
            
                case 'retry':
                    let result = await instance.post('api/voucher/check', {
                        code_voucher: voucher,
                        firebase_token: token
                    })
                    setTimeout(() => {
                        setLoading(false)
                        setVoucherDetail(result.data)
                        setErrorVoucher('')
                        if (result.data.quantity === 0) {
                            setScheme(0)
                        } else {
                            setScheme(1)
                            setQuantity(result.data.quantity)
                            switch (result.data.quantity) {
                                case 1:
                                    setDuration(0)
                                    break;
                                    
                                case 3:
                                    setDuration(1)
                                    break;
                                    
                                case 6:
                                    setDuration(2)
                                    break;
                            
                                default:
                                    setDuration(3)
                                    setCustomDuration(result.data.quantity)
                                    break;
                            }
                        }
                    }, 1000)
                    break;
            
                default:
                    let {data} = await instance.post('api/voucher/check', {
                        code_voucher: voucher,
                        firebase_token: token
                    })
                    setTimeout(() => {
                        setLoading(false)
                        setVoucherDetail(data)
                        setErrorVoucher('')
                        if (first) {
                            if (data.quantity === 0) {
                                setScheme(0)
                            } else {
                                setScheme(1)
                                setQuantity(data.quantity)
                                switch (data.quantity) {
                                    case 1:
                                        setDuration(0)
                                        break;
                                        
                                    case 3:
                                        setDuration(1)
                                        break;
                                        
                                    case 6:
                                        setDuration(2)
                                        break;
                                
                                    default:
                                        setDuration(3)
                                        setCustomDuration(data.quantity)
                                        break;
                                }
                            }
                            setFirst(false)
                        }
                    }, 1000)
                    break;
            }
        } catch (error) {
            setTimeout(() => {
                setLoading(false)
                let errorMsg = ''
                let errorStatus = error.response.data.error_message
                if (errorStatus.includes("code_voucher")) {
                    if (errorStatus.includes("activated")) {
                        setErrorVoucher("Voucher has been activated")
                    } else {
                        setErrorVoucher("Voucher Not Found")
                    }
                } else {
                    setErrorVoucher("There something error, please refresh the page.")
                }
            }, 1000)
        }
    }

    const sendAPI = () => {
        setLoading(true)
        CheckoutTracking()
        if (isBundle) {
            let payments_method = ''
            switch (method) {
            case 0:
                payments_method = 'credit_card'
                break;
                
            case 1:
                payments_method = 'bank_transfer'
                break;
                
            case 2:
                payments_method = 'gopay'
                break;
            
            default:
                break;
            }
            let {firebase_token, payments, api_url, bundle} = payload
            instance.post(api_url, {
                payments,
                library: bundle,
                bundle,
                success_url: "https://payment.app.assemblrworld.com/SuccessCheckoutBundle?checkout=library",
                cancel_url: "https://payment.app.assemblrworld.com/Failed?checkout=library",
                measurement_id: 'G-XWBC98KSXY',
                payments_method
            }, {
                headers: {
                    'Authorization' : firebase_token
                }
            }).then(async (response) => {
                setLoading(false)
                    localStorage.setItem("order_id", response.data.order_id)
                    const queryParams = queryString.parse(location.search)
                    if (payments === 4) {
                        const stripe = await stripePromise;
                        const sessionId = response.data.transactions.id
                        localStorage.setItem("session-id", sessionId)
                        window.open(`${process.env.REACT_APP_PAYMENT_URL_APP}/Stripe?sessionId=${sessionId}`, '_blank')
                        navigate(`/WaitingTopup?checkout=library&token=${firebase_token}${queryParams.no_close_button ? '&no_close_button=true' : null}`)
                    } else {
                        window.snap.pay(response.data.transactions.token,{
                            onSuccess: () => {
                                navigate(`/SuccessCheckoutBundle?checkout=library${queryParams.no_close_button ? '&no_close_button=true' : null}`)
                            },
                            onPending: () => {
                                navigate(`/Waiting?checkout=library`+"&token="+firebase_token)
                            }
                        })
                    }
                    if (newsletter) {
                        const {REACT_APP_AIRTABLE_API_KEY, REACT_APP_AIRTABLE_BASE} = process.env
                        let base = new Airtable({apiKey: REACT_APP_AIRTABLE_API_KEY}).base(REACT_APP_AIRTABLE_BASE)
                        let {email, user_id} = jwt_decode(token)
                        base('Table 1').create([
                            {
                                "fields": {
                                    "UUID": user_id,
                                    "Notes": email,
                                    "Lang": "ID",
                                    "Name": `User-${user_id}`,
                                    "product": ""
                                }
                            }
                        ], function(err, records) {
                            if (err) {
                            return;
                            }
                            records.forEach(function (record) {
                            });
                        });
                    }
                // }
            }).catch((error) => {
                setLoading(false)
                switch (error.response.data.error_message) {
                    case 'You Have Plans Educations':
                        setPopup(`Checkout failed. You already have Premium Plans!`)
                        break;
                    case 'firebase_token: This field is required.':
                        setPopup(`Checkout failed, invalid token!`)
                        break;
                    default:
                        setPopup(`Checkout failed, please try again later!`)
                        break;
                }
            })
        } else {
            let {cancel_url, firebase_token, payments, plans, quantity, success_url, api_url} = payload
            if (voucher && samePlan && sameDuration) {
                instance.post(api_url, {
                    cancel_url,
                    firebase_token,
                    payments,
                    plans,
                    quantity,
                    success_url,
                    code_voucher: voucher
                }).then(async (response) => {
                    setLoading(false)
                    if (voucherDetail.benefit === 100) {
                        navigate('/SuccessCheckoutBundle')
                    } else {
                        localStorage.setItem("order_id", response.data.order_id)
                        if (payments === '1' || payments === '3') {
                            const stripe = await stripePromise;
                            const sessionId = response.data.transactions.id
                            const queryParams = queryString.parse(window.location.search)
                            // if (window.closeButton === true) {
                                window.open(`${process.env.REACT_APP_PAYMENT_URL_APP}/Stripe?sessionId=${sessionId}`, '_blank')
                            // } else {
                            //     await stripe.redirectToCheckout({
                            //         sessionId
                            //     })
                            // }
                        } else {
                            window.snap.pay(response.data.transactions.token,{
                                onSuccess: () => {
                                    navigate(`/SuccessCheckoutBundle?checkout=library`)
                                },
                                onPending: () => {
                                    navigate(`/Waiting?checkout=library`+"&token="+firebase_token)
                                }
                            })
                        }
                        if (newsletter) {
                            const {REACT_APP_AIRTABLE_API_KEY, REACT_APP_AIRTABLE_BASE} = process.env
                            let base = new Airtable({apiKey: REACT_APP_AIRTABLE_API_KEY}).base(REACT_APP_AIRTABLE_BASE)
                            let {email, user_id} = jwt_decode(token)
                            base('Table 1').create([
                                {
                                    "fields": {
                                        "UUID": user_id,
                                        "Notes": email,
                                        "Lang": "ID",
                                        "Name": `User-${user_id}`,
                                        "product": ""
                                    }
                                }
                            ], function(err, records) {
                                if (err) {
                                return;
                                }
                                records.forEach(function (record) {
                                });
                            });
                        }
                    }
                }).catch((error) => {
                    setLoading(false)
                    switch (error.response.data.error_message) {
                        case 'You Have Plans Educations':
                            setPopup(`Checkout failed. You already have Premium Plans!`)
                            break;
                        case 'firebase_token: This field is required.':
                            setPopup(`Checkout failed, invalid token!`)
                            break;
                        default:
                            setPopup(`Checkout failed, please try again later!`)
                            break;
                    }
                })
            } else {
                instance.post(api_url, {
                    cancel_url,
                    firebase_token,
                    payments,
                    plans,
                    quantity,
                    success_url
                }).then(async (response) => {
                    setLoading(false)
                    localStorage.setItem("order_id", response.data.order_id)
                    if (payments === '1' || payments === '3') {
                        const stripe = await stripePromise;
                        const sessionId = response.data.transactions.id
                        const queryParams = queryString.parse(window.location.search)
                        // if (window.closeButton === true) {
                            window.open(`${process.env.REACT_APP_PAYMENT_URL_APP}/Stripe?sessionId=${sessionId}`, '_blank')
                        // } else {
                        //     await stripe.redirectToCheckout({
                        //         sessionId
                        //     })
                        // }
                    } else {
                        window.snap.pay(response.data.transactions.token,{
                            onSuccess: () => {
                                navigate(`/SuccessCheckoutBundle?checkout=library`)
                            },
                            onPending: () => {
                                navigate(`/Waiting?checkout=library`+"&token="+firebase_token)
                            }
                        })
                    }
                    if (newsletter) {
                        const {REACT_APP_AIRTABLE_API_KEY, REACT_APP_AIRTABLE_BASE} = process.env
                        let base = new Airtable({apiKey: REACT_APP_AIRTABLE_API_KEY}).base(REACT_APP_AIRTABLE_BASE)
                        let {email, user_id} = jwt_decode(token)
                        base('Table 1').create([
                            {
                                "fields": {
                                    "UUID": user_id,
                                    "Notes": email,
                                    "Lang": "ID",
                                    "Name": `User-${user_id}`,
                                    "product": ""
                                }
                            }
                        ], function(err, records) {
                            if (err) {
                            return;
                            }
                            records.forEach(function (record) {
                            });
                        });
                    }
                }).catch((error) => {
                    setLoading(false)
                    switch (error.response.data.error_message) {
                        case 'You Have Plans Educations':
                            setPopup(`Checkout failed. You already have Premium Plans!`)
                            break;
                        case 'firebase_token: This field is required.':
                            setPopup(`Checkout failed, invalid token!`)
                            break;
                        default:
                            setPopup(`Checkout failed, please try again later!`)
                            break;
                    }
                })
            }
        }
    }

    useEffect(() => {
        setFirst(true)
        window.dataLayer = window.dataLayer || [];
        window.scrollTo(0, 0)
        const script = document.createElement("script");
        window.parent.postMessage('hide-close', '*')
        script.src = (process.env.NODE_ENV==='production' && !window.location.hostname.includes('192.168'))?process.env.REACT_APP_MIDTRANS_SCRIPT:process.env.REACT_APP_MIDTRANS_SCRIPT_SANDBOX;
        script.async = true;
        script.setAttribute("data-client-key", (process.env.NODE_ENV==='production' && !window.location.hostname.includes('192.168'))?process.env.REACT_APP_MIDTRANS_KEY:process.env.REACT_APP_MIDTRANS_KEY_DEV)
        document.getElementById("checkout").appendChild(script)
        const queryParams = queryString.parse(window.location.search)
        if (queryParams.embed === 'true') {
            setEmbed(true)
        }
        if (queryParams.flow === 'redeem') {
            setFlow('redeem')
            // setFirstLoading(true)
        }
        if (queryParams.voucher && queryParams.voucher !== 'undefined') {
            setVoucher(queryParams.voucher)
            // checkVoucher()
        }
        if (queryParams.voucher) {
            setVoucher(queryParams.voucher)
            // checkVoucher()
        }
        window.addEventListener('message', function (e) {
            if (e.data === 'checkout') {
                setClose(true)
            }
        });

        const params = queryString.parse(location.search)
        if (params?.name && params?.item && params?.thumb) {
            const bundleData = {
                name: params.name,
                item: params.item,
                thumbnail: params.thumb
            }
            localStorage.setItem('purchase-data', JSON.stringify(bundleData))
        }
        
    }, [])

    useEffect(() => {
        const queryParams = queryString.parse(window.location.search)
        if (queryParams.embed === 'true') {
            setEmbed(true)
        }
        if (queryParams.voucher && queryParams.voucher !== 'undefined') {
            // checkVoucher()
        }
        if (queryParams.count && queryParams.count !== 'undefined' && first) {
            setCount(+queryParams.count)
            setAmount(+queryParams.count)
            setFirst(false)
        }
        if (queryParams.token) {
            setToken(queryParams.token)
            setUID(jwt_decode(queryParams.token).user_id)
            localStorage.setItem("UUID", jwt_decode(queryParams.token).user_id)
        }
        if (queryParams.is_bundle === 'true') {
            setIsBundle(true)
            setIdBundle(queryParams.id_bundle)
            setOwnerBundle(queryParams.owner_bundle)
        }

        let newUrl = ''
        let durationUrl = ''
        let methodUrl = ''
        let schemeUrl = ''
        let type = ''
        let code_voucher = queryParams.voucher
        let tempPayload = {
            cancel_url : '',
            firebase_token : token,
            payments: '',
            plans: '',
            quantity: '',
            success_url: '',
            api_url: ''
        }
        
        if (scheme === 0) {
            schemeUrl +='&scheme=subscription'
        } else if (scheme === 1) {
            schemeUrl +='&scheme=onetime'
        }
        if (isBundle) {
            if (method === 0) {
                methodUrl = `&method=credit_card`
                tempPayload.payments = 4
            } else if (method === 1) {
                methodUrl = `&method=bank_transfer`
                tempPayload.payments = 3
            } else if (method === 2) {
                methodUrl = `&method=qris`
                tempPayload.payments = 3
            }
        } else {
            if (method === 0) {
                methodUrl = `&method=credit_card`
                tempPayload.payments = !scheme ? '1' : '3'
            } else if (method === 1) {
                methodUrl = `&method=bank_transfer`
                tempPayload.payments = '2'
            } else if (method === 2) {
                methodUrl = `&method=qris`
                tempPayload.payments = '2'
            }
        }
        
        // if (queryParams.type === 'astu') {
            window.dataLayer.push({
                open_from: "https://studio.assemblrworld.com"
            })
            type = 'astu'
            tempPayload.cancel_url = 'https://studio.assemblrworld.com/'
            tempPayload.success_url = 'https://studio.assemblrworld.com/'
        // } else if (queryParams.type === 'edu') {
        //     window.dataLayer.push({
        //         open_from: "https://app-edu.assemblrworld.com"
        //     })
        //     type = 'edu'
        //     tempPayload.cancel_url = 'https://app-edu.assemblrworld.com/Account?status=failed'
        //     tempPayload.success_url = 'https://app-edu.assemblrworld.com/Account?status=success'
        // } else {
        //     type = 'default'
        //     tempPayload.cancel_url = 'https://payment.edu.assemblrworld.com' + `/Failed`
        // }

        if (queryParams.edu) {
            newUrl += count ? `?count=${count}&embed=true&edu=true&token=${token}&type=${type}&voucher=${code_voucher}` + durationUrl + methodUrl + schemeUrl : `?embed=true&edu=true&token=${token}&type=${type}&voucher=${code_voucher}` + durationUrl + methodUrl + schemeUrl
        } else {
            if (queryParams.is_bundle === "true") {
                newUrl += count ? `?count=${count}&embed=true&token=${token}&type=${type}&is_bundle=true&id_bundle=${queryParams.id_bundle}&owner_bundle=${queryParams.owner_bundle}&voucher=${code_voucher}` + durationUrl + methodUrl + schemeUrl : `?embed=true&token=${token}&type=${type}&is_bundle=true&id_bundle=${queryParams.id_bundle}&owner_bundle=${queryParams.owner_bundle}&voucher=${code_voucher}` + durationUrl + methodUrl + schemeUrl
            } else {
                newUrl += count ? `?count=${count}&embed=true&token=${token}&type=${type}&is_bundle=fals&id_bundle=${queryParams.id_bundle}&owner_bundle=${queryParams.owner_bundle}e&voucher=${code_voucher}` + durationUrl + methodUrl + schemeUrl : `?embed=true&token=${token}&type=${type}&is_bundle=false&id_bundle=${queryParams.id_bundle}&owner_bundle=${queryParams.owner_bundle}&voucher=${code_voucher}` + durationUrl + methodUrl + schemeUrl
            }
        }
        if (queryParams.flow === 'redeem') {
            newUrl += '&flow=redeem'
        }
        if (isBundle) {
            tempPayload.api_url = `api/v2/transactions/library/checkout`
            tempPayload.bundle = bundleDetail.bundle
        }
        setPayload(tempPayload)
        window.history.pushState({}, '', `/CheckoutBundle/${newUrl}`)
    }, [period, duration, scheme, method, customDuration, token, amount])
    

    useEffect(() => {
        const queryParams = queryString.parse(window.location.search)
        if (!tempVoucher && (!queryParams.voucher || typeof queryParams.voucher === 'undefined') && samePlan) {
            setVoucher('')
            setErrorVoucher('')
        } else if (!samePlan) {
            setErrorVoucher('Voucher is not eligible for this plan')
        }
    }, [tempVoucher, samePlan])

    useEffect(() => {
        if (params.id === '20') {
            setScheme(1)
            setDuration(0)
        }
    }, [params, location.search])

    useEffect(() => {
        if (!voucher) {
            setVoucherDetail({})
        }

        if (voucher === 'undefined') {
            setVoucher('')
        }

        if (voucher === currentVoucher && voucher !== 'undefined' && voucher) {
            // checkVoucher()
        } else {
            setVoucherDetail({})
        }
    }, [voucher, currentVoucher])

    useEffect(() => {
        // let qty = quantity ? 
    }, [quantity, amount])

    const getBundleDetail = async (id) => {
        try {
            setFirstLoading(true)
            let { data } = await instance.get(`/api/v2/library/store/3d/?row=200`);
            
            if (data.bundle) {
                const dataBundle = data.bundle.find(data => data.sid === idBundle)
                const { name, editor_thumbnail, price_in_usd, id, type } = dataBundle;
                setBundleDetail({
                    name: name,
                    thumbnail: editor_thumbnail,
                    price: price_in_usd,
                    bundle: id,
                    type: type
                })
                setTimeout(() => {
                    setFirstLoading(false)
                }, 400)
                ViewPlanTracking(dataBundle)
            }
        } catch (error) {
            setFirstLoading(false)
            console.log(error);
        }
    }

    useEffect(() => {
        const queryParams = queryString.parse(window.location.search)
        if (isBundle) {
            getBundleDetail(idBundle);
        }
    }, [isBundle, idBundle])

    return (
        <>
        {firstLoading &&
        <div className='loader-container checkout-loading'>
            <div className={`class-scrim`} />
            {/* <div className='loader2'></div> */}
        </div>
        }
        <div id='checkout' className={`Main marketplace checkout ${!isMobile ? 'tablet' : ''}${isLandscape ? ' landscape' : ''}`}>
            <div className='nav'>
                <div className='header checkout'>
                    <p className='headerTitle'>
                        Purchase Bundle
                    </p>
                </div>
            </div>
            
            <div className={`contentContainer ${!isMobile ? 'tablet' : ''}`}>
                {/* <>
                    <div className='bundle mobile'>
                        <div className='content-data'>
                            <div className='image-container'>
                                <img src={bundleDetail.thumbnail} alt="thumbnail"/>
                            </div>
                            <div className='detail'>
                                <div className='bundle-container'>
                                    <div className='bundle-name'>{bundleDetail.name}</div>
                                    <img src={process.env.PUBLIC_URL + '/assets/pro_packs.png'} alt="pro packs"/>
                                </div>
                                {ownerBundle !== 'undefined' ?
                                <div className='bundle-owner'>by {ownerBundle}</div> : null
                                }
                            </div>
                        </div>
                        <div className='content-detail'>
                            
                            <div className='content-price'>
                                <p className='price'>{bundleDetail.price} USD</p>
                                <div className='button-remove'>Remove</div>
                            </div>
                        </div>
                    </div>
                </> */}
                <Card item={{
                    name: bundleDetail.name,
                    contents: [],
                    plan: "bundle",
                    thumbnail: bundleDetail.thumbnail,
                    owner: ownerBundle !== 'undefined' ? ownerBundle : 'Assemblr',
                    type: bundleDetail.type
                }} />
                <div className=''>
                     {/* Payment Method */}
                     <div className={`paymentScheme ${!isMobile ? 'tablet' : ''}`}>
                        <p className='grey'>
                            Choose Payment Method
                        </p>
                        <div className='paymentWrapper paymentMethod'>
                            <>
                                <div onClick={() => setMethod(0)} className={`method ${method === 0 ? 'active' : ''}`}>
                                    <p>
                                        <input type="radio" readOnly checked={method === 0}/>
                                    </p>
                                    <div>
                                        <p>
                                            Credit/Debit Card
                                        </p>
                                        <p className='desc method'>
                                            Mastercard, Visa, & JBC
                                        </p>
                                    </div>
                                </div>
                                <div onClick={() => setMethod(1)} className={`method ${method === 1 ? 'active' : ''}`}>
                                    <p>
                                        <input type="radio" readOnly checked={method === 1}/>
                                    </p>
                                    <div>
                                        <p>
                                            Bank Transfer
                                        </p>
                                        <p className='desc method'>
                                            BNI, Permata, & etc.
                                        </p>
                                    </div>
                                </div>
                                <div onClick={() => setMethod(2)} className={`method ${method === 2 ? 'active' : ''}`}>
                                    <p>
                                        <input type="radio" readOnly checked={method === 2}/>
                                    </p>
                                    <div>
                                        <p>
                                            QRIS
                                        </p>
                                        <p className='desc method'>
                                            GoPay, DANA, & LinkAja
                                        </p>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                </div>
                {/* <div className="paymentScheme">
                    <p className="grey">
                        Insert your voucher code to get discount...
                    </p>
                    <div className="voucher-container">
                        <input
                        className="voucher-input"
                        value={voucher}
                        type={"text"}
                        name="voucher"
                        placeholder="Voucher Code"
                        onChange={(e) => {
                            setVoucher(e.target.value)
                            setErrorVoucher("")
                        }}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") {
                            event.preventDefault();
                            checkVoucher();
                            }
                        }}
                        />
                        {errorVoucher ? (
                        <div
                            className={`voucher-view ${
                            !voucher ? "search-button-blue" : "search-button-red"
                            }`}
                        >
                            {Object.keys(voucherDetail).length && samePlan ? (
                            <>
                                <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <rect
                                    width="20"
                                    height="20"
                                    rx="10"
                                    fill="#AA0101"
                                />
                                <path
                                    d="M14.6517 14.6191C14.7827 14.4911 14.858 14.3166 14.861 14.1334C14.8641 13.9503 14.7948 13.7733 14.6681 13.641L10.9946 9.96745L14.5635 6.39854C14.6915 6.26547 14.7626 6.08766 14.7616 5.90298C14.7605 5.71829 14.6874 5.54132 14.5578 5.40972C14.4266 5.28089 14.2501 5.20871 14.0662 5.20871C13.8824 5.20871 13.7058 5.28089 13.5747 5.40972L10.0057 8.97863L6.32879 5.30167C6.19667 5.17402 6.0194 5.10386 5.8357 5.10651C5.65201 5.10916 5.47684 5.18441 5.34845 5.31582C5.21836 5.44349 5.14418 5.61753 5.14217 5.7998C5.14015 5.98207 5.21047 6.15771 5.33771 6.28823L9.01466 9.96518L5.44066 13.5392C5.31183 13.6704 5.23965 13.8469 5.23965 14.0308C5.23965 14.2146 5.31183 14.3912 5.44066 14.5223C5.57226 14.6519 5.74924 14.725 5.93392 14.7261C6.11861 14.7271 6.29641 14.6561 6.42948 14.528L10.0035 10.954L13.6793 14.6298C13.8098 14.7571 13.9855 14.8274 14.1677 14.8254C14.35 14.8234 14.524 14.7492 14.6517 14.6191Z"
                                    fill="white"
                                />
                                </svg>
                                <p>Invalid</p>
                            </>
                            ) : (
                            <>
                                {loading ? (
                                <div className="disable button-disable">
                                    <div className="loader"></div>
                                </div>
                                ) : (
                                <>
                                    {!voucher ? (
                                    <p>Validate</p>
                                    ) : (
                                    <div className="error-status-voucher">
                                        <svg
                                        width="20"
                                        height="20"
                                        viewBox="0 0 20 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                        >
                                        <rect
                                            width="20"
                                            height="20"
                                            rx="10"
                                            fill="#AA0101"
                                        />
                                        <path
                                            d="M14.6517 14.6191C14.7827 14.4911 14.858 14.3166 14.861 14.1334C14.8641 13.9503 14.7948 13.7733 14.6681 13.641L10.9946 9.96745L14.5635 6.39854C14.6915 6.26547 14.7626 6.08766 14.7616 5.90298C14.7605 5.71829 14.6874 5.54132 14.5578 5.40972C14.4266 5.28089 14.2501 5.20871 14.0662 5.20871C13.8824 5.20871 13.7058 5.28089 13.5747 5.40972L10.0057 8.97863L6.32879 5.30167C6.19667 5.17402 6.0194 5.10386 5.8357 5.10651C5.65201 5.10916 5.47684 5.18441 5.34845 5.31582C5.21836 5.44349 5.14418 5.61753 5.14217 5.7998C5.14015 5.98207 5.21047 6.15771 5.33771 6.28823L9.01466 9.96518L5.44066 13.5392C5.31183 13.6704 5.23965 13.8469 5.23965 14.0308C5.23965 14.2146 5.31183 14.3912 5.44066 14.5223C5.57226 14.6519 5.74924 14.725 5.93392 14.7261C6.11861 14.7271 6.29641 14.6561 6.42948 14.528L10.0035 10.954L13.6793 14.6298C13.8098 14.7571 13.9855 14.8274 14.1677 14.8254C14.35 14.8234 14.524 14.7492 14.6517 14.6191Z"
                                            fill="white"
                                        />
                                        </svg>
                                        <p>Invalid</p>
                                    </div>
                                    )}
                                </>
                                )}
                            </>
                            )}
                        </div>
                        ) : (
                        <div
                            onClick={
                            voucher && !Object.keys(voucherDetail).length
                                ? () => checkVoucher()
                                : null
                            }
                            className={`${
                            voucher === currentVoucher
                                ? "voucher-view "
                                : "voucher-view search-button-blue "
                            }${voucher ? " button-search" : " button-disable"}`}
                        >
                            {Object.keys(voucherDetail).length && samePlan ? (
                            <>
                                <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                >
                                <rect width="20" height="20" rx="10" fill="#0583A8" />
                                <path
                                    d="M15.6 5.99997L8.60002 14L4.40002 10.3636"
                                    stroke="white"
                                    stroke-width="1.14039"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                                </svg>
                                <p>{voucherDetail.benefit}% Off</p>
                            </>
                            ) : (
                            <>
                                {loading ? (
                                <div className="disable button-disable">
                                    <div className="loader"></div>
                                </div>
                                ) : (
                                <>
                                    {!voucher ? (
                                    <p class="disable button-disable">
                                        Apply
                                    </p>
                                    ) : (
                                    <p
                                        onClick={() => checkVoucher()}
                                        class=""
                                    >
                                        Apply
                                    </p>
                                    )}
                                </>
                                )}
                            </>
                            )}
                        </div>
                        )}
                    </div>
                    {errorVoucher && voucher && (
                        <p className="error-voucher">{errorVoucher}</p>
                    )}
                </div> */}
            </div>
            <div className="button-container">
                <div>
                    <div className={`end-user-container`}>
                        <div className="desc-end-user-container">
                            <input
                            onClick={() => checkboxInput("newsletter")}
                            type="checkbox"
                            id="subcribe"
                            />
                            <label className="desc-end-user" for="subcribe">
                            Send me e-mails about product updates, surveys, and offers
                            </label>
                        </div>
                    </div>
                    <>
                        {loading ? (
                        <div className="button disable">
                            <div className="loader"></div>
                        </div>
                        ) : (
                        <>
                            {typeof method === 'number' ?
                            <div className="button right-button" onClick={() => {
                                sendAPI()
                                if(navigator.userAgent.toLowerCase().includes("assemblr")) window.parent.postMessage("close popup", "*");
                            }}>
                                Purchase
                                <div className={`period total-price`}>
                                {/* {Object.keys(voucherDetail).length &&
                                samePlan &&
                                voucherDetail.duration_qty === +customDuration ? ( */}
                                    <div className="custom-price-discount">
                                    <p className="final-price price-button">
                                        ${" "}
                                        {isBundle ? (+bundleDetail.price).toFixed(2) : (+total).toFixed(2)}
                                    </p>
                                    </div>
                                {/* // ) : (
                                //     <p className="total">
                                //     ${" "}{`${(+total).toFixed(2)}`}
                                //     </p>
                                // )} */}
                                </div>
                            </div> :
                            <div className="button right-button disable">
                                Purchase
                                <div className={`period total-price`}>
                                {/* {Object.keys(voucherDetail).length &&
                                samePlan &&
                                voucherDetail.duration_qty === +customDuration ? ( */}
                                    {/* <div className="custom-price-discount">
                                    <p className="final-price price-button">
                                        ${" "}
                                        {isBundle ? (+bundleDetail.price).toFixed(2) : (+total).toFixed(2)}
                                    </p>
                                    </div> */}
                                {/* // ) : (
                                //     <p className="total">
                                //     ${" "}{`${(+total).toFixed(2)}`}
                                //     </p>
                                // )} */}
                                </div>
                            </div>
                            }
                        </>
                        )}
                    </>
                    <p className="default-button-desc">By pressing the button above, you’re agree to <a href="https://www.assemblrworld.com/pages/eula" target={"_blank"}>Assemblr's terms and conditions</a></p>
                </div>
            </div>
            {popup &&
            <div className='popup'>
                <div className='popupContent'>
                    <div className='popupHeader'>
                        <span className="material-icons">
                            info
                        </span>
                        <div className='error'>
                            <p>
                            {popup}
                            </p>
                        </div>
                    </div>
                    <div className='switch'>
                        <p className='confirm' onClick={() => {
                            setPopup('')
                            }}>
                            Try Again
                        </p>
                    </div>
                </div>
            </div>
            }
        </div>
        </>
    )
}

export default CheckoutBundle;