import React, { useEffect, useState } from 'react'
import { IconFree, IconBasic, IconMedium, IconPremium, IconIndividual, IconSchool, IconCustomMarker, IconBlrCoin } from '../icons';

export default function Card(props) {
    const [duration, setDuration] = useState()
    const [subcribePeriod, setSubcribePeriod] = useState('')
    const [subcribePrice, setSubcribePrice] = useState(0)
    const [firstCheckout, setFirstCheckout] = useState(false)
    const [priceMarker, setPriceMarker] = useState(false)
    const [fetch, setFetch] = useState(false)
    const [cardCount, setCardCount] = useState(props.item.plan === 'marker' && props.quantity === 0 ? props.amount : props.quantity)

    useEffect(() => {
        // if (props.item.plan === 'marker' && props.quantity === 0) {
            let qty = props.amount ? props.amount : props.quantity
            setCardCount(qty)
        // } else {
            // setCardCount(props.quantity)
        // }
    }, [props.quantity, props.amount, props.checkout])

    useEffect(() => {
        setFetch(true)
        console.log(props.item, 'props.item, ');
    }, [props.item.thumbnail])

    useEffect(() => {
        if (props.item.plan !== "bundle") {
            if (props.item.plan === 'marker') {
                switch (cardCount) {
                    case 1:
                        setPriceMarker(3)
                        break;
                
                    case 5:
                        setPriceMarker(15)
                        break;
                
                    case 10:
                        setPriceMarker(30)
                        break;
                
                    default:
                        setPriceMarker((3 * cardCount) - (2 * Math.floor(cardCount / 12) * (3)))
                        break;
                }
            }
            if (props.item.plan !== 'marker') {
                setDuration(+props.period.split(" ")[0])
            }
        }
        if (!props.scheme && typeof props.scheme !== 'undefined') {
            props.planId === props.item.year_id ? setSubcribePeriod('Yearly') : setSubcribePeriod('Monthly')
            props.planId === props.item.year_id ? setSubcribePrice(props.item.annually_price) : setSubcribePrice(props.item.price)
        } else {
            setSubcribePrice(0)
            setSubcribePeriod(`${props.period}`)
        }
        if (typeof props.scheme !== 'undefined') {
            setFirstCheckout(true)
        }
    }, [props, cardCount, subcribePrice, subcribePeriod])
  return (
    <div className={`cardWrapper ${props.isMobile ? ' mobile-card' : ''}${props.checkout ? ' redeem' : ''}${props.discountBenefit === 100 && !props.checkout ? ' redeem free-voucher' : ''}`}>
        <div className={`card card-redeem`}>
            {/* <div className='card-section card-header'>
                <div className='plan-cube'>
                    {props.item.name === 'FREE' &&
                    <IconFree className='image edu' />
                    }
                    {props.item.name === 'BASIC' &&
                    <IconBasic className='image edu' />
                    }
                    {props.item.name === 'MEDIUM' &&
                    <IconMedium className='image edu' />
                    }
                    {props.item.name === 'PREMIUM' &&
                    <IconPremium className='image edu' />
                    }
                    {props.item.name === 'Individual' &&
                    <IconIndividual className={`image edu${props.isMobile ? ' mobile' : ''}`} />
                    }
                    {props.item.name === 'School/Institutional' &&
                    <IconSchool className='image edu' />
                    }
                    {props.item.name === 'Custom Marker' &&
                    <IconCustomMarker className='image edu' />
                    }
                    {props.item.name === 'Single Plan' &&
                    <img src={process.env.PUBLIC_URL+'/assets/Single.png'} className='image edu width'/>
                    }
                </div>
                <div className='card-name-redeem'>
                    {props.item.name === 'Custom Marker' && props.samePlan ?
                    <p className="title">{props.checkout ? cardCount : cardCount} {props.item.name}</p> :
                    <p className="title">{props.item.name}</p>
                    }
                    { (props.item.plan !== 'marker' && (props.samePlan || subcribePeriod)) &&
                    <p className='period'>{props.scheme ? props.period : subcribePeriod}</p>
                    }
                </div>
                {!props.checkout &&
                <div>
                    {props.discountBenefit === 100 ?
                    <p className="title free">{props.item.currency === 'USD' ? '$0' : 'Rp 0.00'}</p> :
                    <>
                        {typeof cardCount !== 'undefined' ?
                        <p className="title free">${(props.item.plan === 'marker' ? priceMarker * (props.discountBenefit / 100) : ((props.item.price * cardCount) - ((props.item.price * cardCount) * (props.discountBenefit / 100)))).toFixed(2)}</p> :
                        <p className="title free">${(props.item.plan === 'marker' ? priceMarker * (props.discountBenefit / 100) : ((props.item.price) - ((props.item.price) * (props.discountBenefit / 100)))).toFixed(2)}</p>
                        }
                    </>
                    }
                    {props.discountBenefit === 100 ?
                    <div className='default-price'>
                        <div className='voucher-status'>
                            Free
                        </div>
                    </div> :
                    <div className='default-price'>
                        <div className='voucher-status'>
                            {props.discountBenefit}%
                        </div>
                        {typeof cardCount !== 'undefined' ?
                        <p className='start-price'>${(props.item.plan === 'marker' ? priceMarker : (props.item.price * cardCount).toFixed(2))}</p> :
                        <p className='start-price'>${(props.item.plan === 'marker' ? priceMarker : (props.item.price).toFixed(2))}</p>
                        }
                    </div>
                    }
                </div>
                }
                <img className='plan-design' src={process.env.PUBLIC_URL + '/assets/planDesign.png'}></img>
            </div> */}
            
            <div id='card-container' className={`cardBody card-redeem-body`}>
                <div>
                    {props.item.plan === "bundle" ?
                    <>
                        { props.item.thumbnail ?
                            <div className='plan-cube'>
                                {props.item.plan === "bundle" &&
                                <img src={props.item.thumbnail}/>
                                }
                            </div> :
                            <div className='plan-cube skeleton'></div>
                        }
                    </> :
                    <div className='plan-cube'>
                        {props.item.name.toLowerCase().includes('FREE'.toLowerCase()) &&
                        <IconFree className='image edu' />
                        }
                        {props.item.name.toLowerCase().includes('BASIC'.toLowerCase()) &&
                        <IconBasic className='image edu' />
                        }
                        {props.item.name.toLowerCase().includes('MEDIUM'.toLowerCase()) &&
                        <IconMedium className='image edu' />
                        }
                        {props.item.name.toLowerCase().includes('PREMIUM'.toLowerCase()) &&
                        <IconPremium className='image edu' />
                        }
                        {props.item.name.toLowerCase().includes('individual') &&
                        <IconIndividual className={`image edu${props.isMobile ? ' mobile' : ''}`} />
                        }
                        {props.item.name.toLowerCase() === 'School/Institutional' ||  props.item.name.includes("School") || props.item.name.toLowerCase().includes("school premium") || props.item.name.toLowerCase().includes("group plan") &&
                        <IconSchool className='image edu' />
                        }
                        {props.item.name.toLowerCase().includes('custom marker') &&
                        <IconCustomMarker className='image edu' />
                        }
                        {(props.item.name).toLowerCase().includes('blr') &&
                        <IconBlrCoin className='image edu' />
                        }
                        {props.item.name.toLowerCase().includes("single") &&
                        <img src={process.env.PUBLIC_URL+'/assets/Single.png'} className='image edu width'/>
                        }
                    </div>
                    }
                    <div className='plan-name'>
                        {props.item.plan === "bundle" ?
                            <>
                                <div className='detail'>
                                    <div className='bundle-container'>
                                        { props.item.name ?
                                            <p className='plan-name-title bundle-title'>{`${props.item.name}`}</p> :
                                            <div className='plan-name-title bundle-title skeleton'></div>
                                        }
                                        <div className={`emblem-container ${props.item.type === "Exclusive" ? 'special' : ''}`}>
                                            <img src={process.env.PUBLIC_URL + `${props.item.type === "Exclusive" ? '/assets/icon_star.svg' : '/assets/ic_crown2.svg'}`} alt="pro packs"/>
                                            <p>{props.item.type === "Exclusive" ? 'Special Pack' : 'Pro Pack'}</p>
                                        </div>
                                        {/* <img src={process.env.PUBLIC_URL + '/assets/pro_packs.png'} alt="pro packs"/> */}
                                    </div>
                                    {typeof props.item.owner !== 'undefined' ?
                                    <div className='bundle-owner'>by <span>{props.item.owner}</span></div> : null
                                    }
                                </div>
                            </>:
                            <p className='plan-name-title'>{`${props.item.name} ${props.item.name === 'Custom Marker' || (props.item.name).toLowerCase().includes('blr') || (props.item.name).toLowerCase().includes('plan') ? '' : 'Plan'}`}</p>
                        }
                        { props.item.contents.map((content, index) => {
                            return <div key={index}>
                                {/* <img src={process.env.PUBLIC_URL + '/assets/check.svg'}></img> */}
                                {/* {(props.item.plan === 'marker' && content[0] === '5 ') ? */}
                                {/* <div className='description'>
                                    <strong>{cardCount} </strong>
                                    {content[1]}
                                </div> : */}
                                {(props.item.name.toLowerCase().includes("blr")) ?
                                <div className='description'>
                                    <strong>{content} </strong>
                                </div>
                                :
                                <>
                                    {index < 2 ?
                                    <>
                                    {(props.item.name.includes("School") && content[1].includes("Member Seats")) ?
                                    <div className='description'>
                                        <strong>{props.amount ? props.amount : content[0]} </strong>
                                        {content[1]}
                                    </div> :
                                    <div className='description'>
                                        <strong>{content[0]}</strong>
                                        {content[1]}
                                    </div>
                                    }
                                    </> : null
                                    }
                                </>
                                }
                                {/* } */}
                            </div>
                        })}
                    </div>
                    {!(props.item.name).toLowerCase().includes('blr') && (
                        <>
                        {props.item.plan === "bundle" ?
                        null :
                        <div className='plan-cta-dropdown'>
                            <p id='cta-text' onClick={() => {
                                document.getElementById('card-container').classList.toggle('open')
                                document.getElementById('cta-text').innerHTML = document.getElementById('cta-text').innerHTML === 'Details' ? 'Close Details' : 'Details'
                            }}>
                                Details
                            </p>
                        </div>
                        }
                        </>
                    )}
                </div>
                {!(props.item.name).toLowerCase().includes('blr') ?
                <div className='drop-down'>
                    { props.item.contents.map((content, index) => {
                        return <div key={index}>
                            <img src={process.env.PUBLIC_URL + '/assets/check.svg'}></img>
                            {(props.item.plan === 'marker' && content[0] === '5 ') ?
                            <div className='description'>
                                <strong>{cardCount} </strong>
                                {content[1]}
                            </div> :
                            // {index < 2 ?
                            <>
                            {(props.item.name.includes("School") && content[1].includes("Member Seats")) ?
                            <div className='description'>
                                <strong>{props.amount ? props.amount : content[0]} </strong>
                                {content[1]}
                            </div> :
                            <div className='description'>
                                <strong>{content[0]}</strong>
                                {content[1]}
                            </div>
                            }
                            </>
                            // }
                            }
                        </div>
                    })}
                </div> : null
                }
            </div>
            {/* {props.item.name === 'School/Institutional' ?
            <div className='card-section card-footer'>
                <div>
                </div>
                <div>
                    <p className='title free checkout-price'>${props.totalCustomMarker}</p>
                </div>
            </div> :
            <>
            {props.scheme ?
            <>
            {props.checkout &&
            <>
            {(((duration === cardCount || props.voucherDetail.quantity === cardCount) && props.voucher && !props.error) && props.samePlan) ?
            <div className='card-section card-footer'>
                <div>
                    {props.discountBenefit === 100 ?
                    <div className='default-price'>
                        <div className='voucher-status'>
                            Free
                        </div>
                    </div> :
                    <div className='default-price'>
                        <div className='voucher-status checkout-detail-voucher'>
                            {props.discountBenefit}% OFF
                        </div>
                    </div>
                    }
                    <p className='voucher-applied'>Voucher Applied</p>
                </div>
                <div>
                    {props.discountBenefit === 100 ?
                    <p className="title free">$0</p> :
                    <p className="title free checkout-price">${(props.item.plan === 'marker' ? priceMarker * (props.discountBenefit / 100) : ((props.item.price * cardCount) - ((props.item.price * cardCount) * (props.discountBenefit / 100)))).toFixed(2)}</p>
                    }
                    {props.discountBenefit === 100 ?
                    <div className='default-price'>
                        <div className='voucher-status'>
                            Free
                        </div>
                        <p className='start-price checkout-start'>${(props.item.plan === 'marker' ? priceMarker : (props.item.price * cardCount).toFixed(2))}</p>
                    </div> :
                    <div className='default-price footer'>
                        <p className='start-price checkout-start'>${(props.item.plan === 'marker' ? priceMarker : (props.item.price * cardCount).toFixed(2))}</p>
                    </div>
                    }
                </div>
            </div> :
            <div className='card-section card-footer'>
                <div>
                </div>
                <div>
                    {props.item.name === 'Custom Marker' ?
                    <>
                    <p className='title free checkout-price'>${props.totalCustomMarker}</p>
                    </> :
                    <>
                    {!props.scheme && !subcribePrice ?
                    <p className='title free checkout-price'>${(props.item.price * duration).toFixed(2)}</p> :
                    <p className='title free checkout-price'>${(!props.scheme ? subcribePrice : ((props.item.price) * duration) - (2 * Math.floor(duration / 12) * (props.item.price))).toFixed(2)}</p>
                    }
                    </>
                    }
                </div>
            </div>
            }
            </>
            }
            </> :
            <>
            {typeof props.scheme !== 'undefined' ?
            <>
            {props.checkout &&
            <>
            {(((props.voucher || !props.error) && (props.voucher && subcribePeriod === 'Monthly')) && props.samePlan) ?
            <div className='card-section card-footer'>
                <div>
                    {props.discountBenefit === 100 ?
                    <div className='default-price'>
                        <div className='voucher-status'>
                            Free
                        </div>
                    </div> :
                    <div className='default-price'>
                        <div className='voucher-status checkout-detail-voucher'>
                            {props.discountBenefit}% OFF
                        </div>
                    </div>
                    }
                    <p className='voucher-applied'>Voucher Applied</p>
                </div>
                <div>
                    {props.discountBenefit === 100 ?
                    <p className="title free">$0</p> :
                    <p className="title free checkout-price">${(((props.item.price * cardCount) - (props.item.price * cardCount) * (props.discountBenefit / 100))).toFixed(2)}</p>
                    }
                    {props.discountBenefit === 100 ?
                    <div className='default-price'>
                        <div className='voucher-status'>
                            Free
                        </div>
                        <p className='start-price checkout-start'>${(props.item.price * cardCount).toFixed(2)}</p>
                    </div> :
                    <div className='default-price footer'>
                        <p className='start-price checkout-start'>${(props.item.price * cardCount).toFixed(2)}</p>
                    </div>
                    }
                </div>
            </div> :
            <div className='card-section card-footer'>
                <div>
                </div>
                <div>
                    {!props.scheme && !subcribePrice ?
                    <p className='title free checkout-price'>${(props.item.price * duration).toFixed(2)}</p> :
                    <p className='title free checkout-price'>${(!props.scheme ? subcribePrice : ((props.item.price) * duration) - (2 * Math.floor(duration / 12) * (props.item.price))).toFixed(2)}</p>
                    }
                </div>
            </div>
            }
            </>
            }
            </> :
            <>
            {props.checkout &&
            <div className='card-section card-footer'>
                <div>
                </div>
                <div>
                    <p className='title free checkout-price'>${props.item.plan === 'marker' ? 3 : props.item.price}</p>
                </div>
            </div>
            }
            </>
            }
            </>
            }
            </>
            } */}
        </div>
        {/* {(props.discountBenefit === 100 && !props.checkout) &&
        <div className={`paymentScheme`}>
            <p>
                End-User Agreements
            </p>
            <div className='desc-end-user-container'>
                <input onClick={() => props.checkboxInput('term')} type="checkbox" id='terms'/>
                <label className='desc_redeem_voucher_bundle' for='terms'>
                    I agree to the Assemblr's terms and conditions
                </label>
            </div>
            <br></br>
            <div className='desc-end-user-container'>
                <input onClick={() => props.checkboxInput('newsletter')} type="checkbox" id='subcribe'/>
                <label className='desc_redeem_voucher_bundle' for='subcribe'>
                    Send me e-mails about product updates, surveys, and offers
                </label>
            </div>
        </div>
        } */}
    </div>
  )
}
