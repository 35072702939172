import "./index.scss";
import "../Payment/index.scss";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import PLANS from "../../configs/Plans";
import { useMediaQuery } from "react-responsive";
import instance from "../../configs/axios";
import { loadStripe } from "@stripe/stripe-js";
import queryString from "query-string";
import jwt_decode from "jwt-decode";
import * as Airtable from "airtable";
import { useDebounce } from "use-debounce";
import Card from "../../components/Card";
import OrderSummary from "../../components/OrderSummary";
import { IconBlrCoin } from "../../icons";
import {isMobileOnly} from 'react-device-detect';

function NewCheckout() {
  const stripePromise = loadStripe((process.env.NODE_ENV==='production' && !window.location.hostname.includes('192.168'))?process.env.REACT_APP_STRIPE_TOKEN:process.env.REACT_APP_STRIPE_TOKEN_DEV);
  const isMobile = useMediaQuery({ maxWidth: 760 });
  const isLandscape = useMediaQuery({ orientation: "landscape" });
  let params = useParams();
  let location = useLocation();
  let navigate = useNavigate();
  const [token, setToken] = useState("");
  const [planDetail, setPlanDetail] = useState(PLANS[params.id]);
  const [period, setPeriod] = useState();
  const [duration, setDuration] = useState();
  const [method, setMethod] = useState();
  const [scheme, setScheme] = useState();
  const [amount, setAmount] = useState(0);
  const [total, setTotal] = useState(`0`);
  const [customDuration, setCustomDuration] = useState(1);
  const [customQty, setCustomQty] = useState(100);
  const [coin, setCoin] = useState()
  const [embed, setEmbed] = useState(false);
  const [uid, setUID] = useState("");
  const [popup, setPopup] = useState("");
  const [term, setTerm] = useState(false);
  const [newsletter, setNewsLetter] = useState(false);
  const [payload, setPayload] = useState({
    cancel_url: "",
    firebase_token: token,
    payments: "",
    plans: "",
    quantity: "",
    success_url: "",
    api_url: "",
  });
  const [loading, setLoading] = useState(false);
  const [voucher, setVoucher] = useState("");
  const [currentVoucher, setCurrentVoucher] = useState("");
  const [voucherDetail, setVoucherDetail] = useState({});
  const [errorVoucher, setErrorVoucher] = useState("");
  const [samePlan, setSamePlan] = useState(false);
  const [sameDuration, setSameDuration] = useState(false);
  const [first, setFirst] = useState(true);
  const [priceTotal, setPriceTotal] = useState("0");
  const [planPrice, setPlanPrice] = useState("0");
  const [count, setCount] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [planDuration, setPlanDuration] = useState("");
  const [flow, setFlow] = useState("");
  const [tempAmount] = useDebounce(amount, 1500);
  const [tempVoucher] = useDebounce(voucher, 1000);
  const [firstLoading, setFirstLoading] = useState(false);
  const [close, setClose] = useState(false);
  const [isBundle, setIsBundle] = useState(false);
  const [idBundle, setIdBundle] = useState("");
  const [ownerBundle, setOwnerBundle] = useState("");
  const [bundleDetail, setBundleDetail] = useState({
    name: "",
    thumbnail: "",
    price: "0",
  });
  const [coinPlanList, setCoinPlanList] = useState([])
  const [hideClose, setHideClose] = useState(false)
  const isFromApp = navigator.userAgent.toLowerCase().includes("assemblr")
  const checkoutTitle = params.id === '20' ? 'Purchase Custom Marker' : 
                          (params.id === '150' || params.id === '104') ? 'Topup BLR Coin' : 'Checkout'

  const changeAmount = (type) => {
    if (type) {
      setAmount(+amount + 1);
    } else if (!type && amount) {
      if (planDetail.name === "Custom Marker" && amount > 1) {
        setAmount(+amount - 1);
      } else if (planDetail.name === "School/Institutional" && amount > 10) {
        setAmount(+amount - 1);
      }
    }
  };

  const ViewPlanTracking = (payload) => {
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "view_item",
      ecommerce: {
        items: [
          {
            item_id: planDetail.id,
            item_name: planDetail.name,
            currency: "USD",
            item_category: planDetail.plan,
            item_period: planDetail.type,
            item_list_id: "plan_list",
            item_list_name: "Plan List",
            price: planDetail.price,
            price_annually: planDetail.annually_price,
            quantity: 1,
          },
        ],
      },
    });
  };

  const CheckoutTracking = () => {
    let durationLong = 0;
    switch (duration) {
      case 0:
        durationLong = 1;
        break;

      case 1:
        durationLong = 3;
        break;

      case 2:
        durationLong = 6;
        break;

      case 3:
        durationLong = customDuration;
        break;

      default:
        break;
    }
    let currentPrice =
      (!scheme && !period) || scheme
        ? planDetail.price
        : planDetail.annually_price;
    let totalPrice = 0;
    if (scheme && duration === 3) {
      totalPrice = (+total).toFixed(2);
    } else if (scheme && duration !== 3) {
      totalPrice = (durationLong * planDetail.price).toFixed(2);
    } else if (!scheme) {
      totalPrice = period ? planDetail.annually_price : planDetail.price;
    }
    let payment_method = "";
    let payment_service = "";
    switch (method) {
      case 0:
        payment_method = "credit card";
        payment_service = "stripe";
        break;

      case 1:
        payment_method = "bank transfer";
        payment_service = "midtrans";
        break;

      case 2:
        payment_method = "qris";
        payment_service = "midtrans";
        break;

      default:
        break;
    }
    window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
    window.dataLayer.push({
      event: "begin_checkout",
      ecommerce: {
        items: [
          {
            item_id: planDetail.id,
            item_name: planDetail.name,
            currency: "USD",
            discount: Object.keys(voucherDetail).length
              ? voucherDetail.benefit
              : 0,
            item_category: planDetail.plan,
            item_duration: scheme ? durationLong : 1,
            item_period: period ? "annualy" : "monthly",
            item_list_id: "plan_list",
            item_list_name: "Plan List",
            price: currentPrice,
            total: +totalPrice,
            payment_method,
            payment_service,
            quantity: 1,
          },
        ],
      },
    });
  };

  const checkboxInput = (type) => {
    switch (type) {
      case "term":
        setTerm(!term);
        break;

      case "newsletter":
        setNewsLetter(!newsletter);
        break;

      default:
        break;
    }
  };

  const checkVoucher = async (type) => {
    try {
      setLoading(true);
      setCurrentVoucher(voucher);
      if (flow === "redeem" && type !== "same" && type !== "different") {
        setTimeout(() => {
          setFirstLoading(false);
        }, 2000);
      }
      switch (type) {
        case "same":
          setErrorVoucher("");
          setLoading(false);
          break;

        case "different":
          setErrorVoucher(
            `Voucher is only eligible for ${voucherDetail.duration_qty} months plan.`
          );
          setLoading(false);
          break;

        case "retry":
          let result = await instance.post("api/voucher/check", {
            code_voucher: voucher,
            firebase_token: token,
          });
          setTimeout(() => {
            setLoading(false);
            setVoucherDetail(result.data);
            setErrorVoucher("");
            if (result.data.duration_qty === 0) {
              setScheme(0);
            } else {
              setScheme(1);
              setQuantity(result.data.product_qty);
              switch (result.data.duration_qty) {
                case 1:
                  setDuration(0);
                  break;

                case 3:
                  setDuration(1);
                  break;

                case 6:
                  setDuration(2);
                  break;

                default:
                  setDuration(3);
                  setCustomDuration(result.data.duration_qty);
                  break;
              }
            }
          }, 1000);
          break;

        default:
          let { data } = await instance.post("api/voucher/check", {
            code_voucher: voucher,
            firebase_token: token,
          });
          setTimeout(() => {
            setLoading(false);
            setVoucherDetail(data);
            setErrorVoucher("");
              if (data.duration_qty === 0) {
                setScheme(0);
              } else {
                setScheme(1);
                setQuantity(data.product_qty);
                switch (data.duration_qty) {
                  case 1:
                    setDuration(0);
                    break;

                  case 3:
                    setDuration(1);
                    break;

                  case 6:
                    setDuration(2);
                    break;

                  default:
                    setDuration(3);
                    setCustomDuration(data.duration_qty);
                    break;
                }
              }
              // setFirst(false);
            // }
          }, 1000);
          break;
      }
    } catch (error) {
      setTimeout(() => {
        setLoading(false);
        let errorMsg = "";
        let errorStatus = error.response.data.error_message;
        if (errorStatus.includes("code_voucher")) {
          if (errorStatus.includes("activated")) {
            setErrorVoucher("Voucher has been activated");
          } else {
            setErrorVoucher("Voucher Not Found");
          }
        } else {
          setErrorVoucher("There something error, please refresh the page.");
        }
      }, 1000);
    }
  };

  const currencyFormat = (number, type)=>{
    if (type === 'idr') {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR"
      }).format(number);
    } else {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD"
      }).format(number);
    }
  }

  const sendAPI = () => {
    setLoading(true);
    let {
      cancel_url,
      firebase_token,
      payments,
      plans,
      quantity,
      success_url,
      api_url,
    } = payload;
    if (planDetail.name === "Blr Coin") {
      switch (coin) {
        case 0:
          plans = payments == 3 ? 153 : payments == 2 ? 150 : 103
          break;
      
        case 1:
          plans = payments == 3 ? 154 : payments == 2 ? 151 : 104
          break;
      
        case 2:
          plans = payments == 3 ? 155 : payments == 2 ? 152 : 105
          break;
      
        default:
          break;
      }
    }
    if (!token) {
      window.parent.postMessage("show popup login", "*")
      setLoading(false);
      return
    }
    if (voucher && sameDuration && samePlan) {
      if (voucherDetail.benefit === 100) {
        instance.post('api/voucher/redeem', {
          code_voucher: voucher,
          firebase_token
        }).then((response) => {
          if (location.search.includes("embed")) {
              navigate('/SuccessRedeem')
          } else {
              (params.id === '104' || params.id === '150') ? navigate('/SuccessTopupBlr?checkout=library') : navigate('/Success?checkout=library')
          }
        }).catch((error) => {
          setLoading(false);
          switch (error.response.data.error_message) {
            case "You Have Plans Educations":
              setPopup(`Checkout failed. You already have Premium Plans!`);
              break;
            case "firebase_token: This field is required.":
              setPopup(`Checkout failed, invalid token!`);
              break;
            default:
              setPopup(`Checkout failed, please try again later!`);
              break;
          }
        })
      } else {
        instance
          .post(api_url, {
            cancel_url,
            firebase_token,
            payments,
            plans,
            quantity,
            success_url,
            code_voucher: voucher,
            measurement_id: 'G-0H33LJSKQY'
          })
          .then(async (response) => {
            setLoading(false);
            if (payments === "1" || payments === "3") {
              const stripe = await stripePromise;
              const sessionId = response.data.id;
              const queryParams = queryString.parse(window.location.search);
              // if (window.closeButton === true) {
                window.open(
                  `${process.env.REACT_APP_PAYMENT_URL_APP}/Stripe?sessionId=${sessionId}`,
                  "_blank"
                );
                navigate("/WaitingPlan?checkout=library"+"&token="+firebase_token)
              // } else {
              //   await stripe.redirectToCheckout({
              //     sessionId,
              //   });
              // }
            } else {
              window.snap.pay(response.data.token, {
                onSuccess: () => {
                  (params.id === '104' || params.id === '150') ? navigate('/SuccessTopupBlr?checkout=library') : navigate('/Success?checkout=library')
                },
                onPending: () => {
                  navigate("/WaitingPlan?checkout=library"+"&token="+firebase_token)
                },
              });
            }
            if (newsletter) {
              const { REACT_APP_AIRTABLE_API_KEY, REACT_APP_AIRTABLE_BASE } =
                process.env;
              let base = new Airtable({
                apiKey: REACT_APP_AIRTABLE_API_KEY,
              }).base(REACT_APP_AIRTABLE_BASE);
              let { email, user_id } = jwt_decode(token);
              base("Table 1").create(
                [
                  {
                    fields: {
                      UUID: user_id,
                      Notes: email,
                      Lang: "ID",
                      Name: `User-${user_id}`,
                      product: planDetail.name,
                    },
                  },
                ],
                function (err, records) {
                  if (err) {
                    return;
                  }
                  records.forEach(function (record) {});
                }
              );
            }
          })
          .catch((error) => {
            setLoading(false);
            switch (error.response.data.error_message) {
              case "You Have Plans Educations":
                setPopup(`Checkout failed. You already have Premium Plans!`);
                break;
              case "firebase_token: This field is required.":
                setPopup(`Checkout failed, invalid token!`);
                break;
              default:
                setPopup(`Checkout failed, please try again later!`);
                break;
            }
          });
      }
    } else {
      instance
        .post(api_url, {
          cancel_url,
          firebase_token,
          payments,
          plans,
          quantity,
          success_url,
          measurement_id: 'G-0H33LJSKQY'
        })
        .then(async (response) => {
          setLoading(false);
          const queryParams = queryString.parse(location.search);
          if (payments === "1" || payments === "3") {
            const stripe = await stripePromise;
            const sessionId = response.data.id;
            if (window.closeButton === true) {
              window.open(
                `${process.env.REACT_APP_PAYMENT_URL_APP}/Stripe?sessionId=${sessionId}`,
                "_blank"
              );
              navigate("/WaitingPlan?checkout=library"+"&token="+firebase_token)
            } else {
              localStorage.setItem("session-id", sessionId)
              localStorage.setItem("order_id", "")
              window.open(`${process.env.REACT_APP_PAYMENT_URL_APP}/Stripe?sessionId=${sessionId}`, "_blank");
              navigate(`/WaitingTopup?checkout=library&token=${firebase_token}${queryParams.no_close_button ? '&no_close_button=true' : null}`)
            }
          } else {
            window.snap.pay(response.data.token, {
              onSuccess: () => {
                (params.id === '104' || params.id === '150') ? navigate(`/SuccessTopupBlr?checkout=library${queryParams.no_close_button ? '&no_close_button=true' : null}`) : navigate('/Success?checkout=library')
              },
              onPending: () => {
                navigate("/WaitingPlan?checkout=library"+"&token="+firebase_token)
              },
            });
          }
          if (newsletter) {
            const { REACT_APP_AIRTABLE_API_KEY, REACT_APP_AIRTABLE_BASE } =
              process.env;
            let base = new Airtable({
              apiKey: REACT_APP_AIRTABLE_API_KEY,
            }).base(REACT_APP_AIRTABLE_BASE);
            let { email, user_id } = jwt_decode(token);
            base("Table 1").create(
              [
                {
                  fields: {
                    UUID: user_id,
                    Notes: email,
                    Lang: "ID",
                    Name: `User-${user_id}`,
                    product: planDetail.name,
                  },
                },
              ],
              function (err, records) {
                if (err) {
                  return;
                }
                records.forEach(function (record) {});
              }
            );
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          switch (error.response.data.error_message) {
            case "You Have Plans Educations":
              setPopup(`Checkout failed. You already have Premium Plans!`);
              break;
            case "firebase_token: This field is required.":
              setPopup(`Checkout failed, invalid token!`);
              break;
            default:
              setPopup(`Checkout failed, please try again later!`);
              break;
          }
        });
    }
  };

  // const getCustomToken = async () => {
  //   const token = await fetch(`https://us-central1-assemblr-1ff3f.cloudfunctions.net/app/v2/token/${uid}`, {
  //     method: 'GET'
  //   })
  //   // const token = await Axios.get(`https://us-central1-assemblr-1ff3f.cloudfunctions.net/app/v2/token/${auth.currentUser.uid}`)
  //   const {results} = await token.json();
  //   console.log(results, uid, 'tokenresultbutton');
  //   return results;
  // }

  useEffect(() => {
    const privatePlanFetch = async () => {
      let privatePlanList = await instance.get(`/api/plans/filter/public`)
      if (params.id == 103 || params.id == 104 || params.id == 105 || params.id == 150 || params.id == 151 || params.id == 152 || params.id == 153 || params.id == 154 || params.id == 155) {
        let coinPlan = privatePlanList.data.plans.filter((item) => item.id === 103 || item.id === 104 || item.id === 105 || item.id === 150 || item.id === 151 || item.id === 152 || item.id === 153 || item.id === 154 || item.id === 155)
        let list = []
        coinPlan.forEach((item) => {
          list.push({
            id: item.id,
            month_id: item.id,
            name: "Blr Coin",
            coin: item.name.split(" ")[0],
            plan: item.plans_type,
            type: 'one-time',
            currency: 'USD',
            priceIAP: item.id === 103 || item.id === 104 || item.id === 105 ? item.price.unlimited.price[0] : null,
            priceMidtrans: item.id === 103 || item.id === 104 || item.id === 105 ? null : item.price.unlimited.price[0],
            priceStripe: item.id === 103 || item.id === 104 || item.id === 105 ? null : item.price.unlimited.price[1],
            idIAP: item.id === 103 || item.id === 104 || item.id === 105 ? item.price.unlimited.code[1][0] : null,
            idMidtrans: item.id === 103 || item.id === 104 || item.id === 105 ? null : item.price.unlimited.code[1][0],
            idStripe: item.id === 103 || item.id === 104 || item.id === 105 ? null : item.price.unlimited.code[1][1],
            contents: [
                ['Can be used to purchase various items in Assemblr ecosystem'],
            ],
          })
        })
        setCoinPlanList(list)
        setPlanDetail(list[0])
      }
    }
    const queryParams = queryString.parse(window.location.search);
    if (queryParams.token) {
      setToken(queryParams.token);
      setUID(jwt_decode(queryParams.token).user_id);
      localStorage.setItem("UUID", jwt_decode(queryParams.token).user_id);
    }
    if (queryParams.hide_close) {
      setHideClose(true)
    }
    var button2 = document.createElement('button')
    button2.style.position = 'fixed'
    button2.style.top = 0
    button2.value = ''
    button2.id ='get-token'
    button2.style.display = 'none'
    button2.onclick = async function(){
        button2.value = queryParams.token
    }

    document.body.appendChild(button2);
    privatePlanFetch()

    if (queryParams?.name && queryParams?.item && queryParams?.thumb) {
      const bundleData = {
        name: queryParams.name,
        item: queryParams.item,
        thumbnail: queryParams.thumb
      }
      localStorage.setItem('purchase-data', JSON.stringify(bundleData))
    }
  }, [])

  useEffect(() => {
    setFirst(true);
    window.dataLayer = window.dataLayer || [];
    window.scrollTo(0, 0);
    const script = document.createElement("script");
    window.parent.postMessage("hide-close", "*");
    script.src = (process.env.NODE_ENV==='production' && !window.location.hostname.includes('192.168'))?process.env.REACT_APP_MIDTRANS_SCRIPT:process.env.REACT_APP_MIDTRANS_SCRIPT_SANDBOX;
    script.async = true;
    script.setAttribute("data-client-key", (process.env.NODE_ENV==='production' && !window.location.hostname.includes('192.168'))?process.env.REACT_APP_MIDTRANS_KEY:process.env.REACT_APP_MIDTRANS_KEY_DEV)
    document.getElementById("checkout").appendChild(script);
    ViewPlanTracking();
    const queryParams = queryString.parse(window.location.search);
    if (queryParams.count && planDetail.name === "Custom Marker") {
      setAmount(+queryParams.count);
    }
    if (queryParams.embed === "true") {
      setEmbed(true);
    }
    if (queryParams.flow === "redeem") {
      setFlow("redeem");
      setFirstLoading(true);
    }
    if (queryParams.close === "true") {
      setClose(true);
    }
    if (queryParams.voucher && queryParams.voucher !== "undefined") {
      setVoucher(queryParams.voucher);
      checkVoucher();
    }
    if ((queryParams.duration !== 'undefined' || queryParams.duration !== '10') && planDetail.name === 'School/Institutional') {
      setAmount(queryParams.duration)
      if (planDetail.name === "School/Institutional") {
        setAmount(+queryParams.duration)
        setAmount(10)
      }
      window.addEventListener("message", function (e) {
        if (e.data === "checkout") {
          setClose(true);
        }
      });
    }
  }, []);

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    if (queryParams.embed === "true") {
      setEmbed(true);
    }
    if (queryParams.voucher && queryParams.voucher !== "undefined") {
      checkVoucher();
    }
    if (queryParams.count && queryParams.count !== "undefined" && first) {
      setCount(+queryParams.count);
      setAmount(+queryParams.count);
      setFirst(false);
    }
    if (queryParams.token) {
      setToken(queryParams.token);
      if (queryParams.token !== "undefined") {
        setUID(jwt_decode(queryParams.token).user_id);
        localStorage.setItem("UUID", jwt_decode(queryParams.token).user_id);
      }
    }
    if (queryParams.is_bundle === "true") {
      setIsBundle(true);
      setIdBundle(queryParams.id_bundle);
      setOwnerBundle(queryParams.owner_bundle);
    }

    let newUrl = "";
    let durationUrl = "";
    let methodUrl = "";
    let schemeUrl = "";
    let type = "";
    let code_voucher = queryParams.voucher;
    let tempPayload = {
      cancel_url: "",
      firebase_token: token,
      payments: "",
      plans: "",
      quantity: "",
      success_url: "",
      api_url: "",
    };
    if (typeof period === "undefined" || period === 0) {
      newUrl = `${planDetail.month_id}`;
      if (period === 0) {
        durationUrl = `&duration=1`;
        tempPayload.quantity = "1";
        tempPayload.plans = planDetail.month_id;
        tempPayload.api_url = "api/plans/request";
        tempPayload.success_url =
          "https://payment.edu.assemblrworld.com" + "/Success";
      }
    } else {
      newUrl = `${planDetail.year_id}`;
      durationUrl = `&duration=12`;
      tempPayload.quantity = "1";
      tempPayload.plans = planDetail.year_id;
      tempPayload.api_url = "api/plans/request";
      tempPayload.success_url =
        "https://payment.edu.assemblrworld.com" + "/Success";
    }

    if (scheme === 0) {
      schemeUrl += "&scheme=subscription";
    } else if (scheme === 1) {
      schemeUrl += "&scheme=onetime";
    }

    if (method === 0) {
      methodUrl = `&method=credit_card`;
      tempPayload.payments = !scheme ? "1" : "3";
    } else if (method === 1) {
      methodUrl = `&method=bank_transfer`;
      tempPayload.payments = "2";
    } else if (method === 2) {
      methodUrl = `&method=qris`;
      tempPayload.payments = "2";
    }
    if (planDetail.month_id === 100) {
      durationUrl = `&duration=${amount}`;
      tempPayload.quantity = `${amount}`;
      tempPayload.plans =
        +amount >= 60
          ? 102
          : +amount >= 30 && +amount < 60
          ? 101
          : planDetail.month_id;
      tempPayload.api_url = "api/plans/request";
      tempPayload.success_url =
        "https://payment.edu.assemblrworld.com" + `/Success`;
    } else {
      if (!scheme) {
        setCustomDuration(1);
      }
      if (duration === 0) {
        durationUrl = `&duration=1`;
        tempPayload.quantity = "1";
        tempPayload.plans = planDetail.month_id;
        tempPayload.api_url = "api/plans/request";
        tempPayload.success_url =
          "https://payment.edu.assemblrworld.com" + "/Success";
      } else if (duration === 1) {
        durationUrl = `&duration=3`;
        tempPayload.quantity = "3";
        tempPayload.plans = planDetail.month_id;
        tempPayload.api_url = "api/plans/request";
        tempPayload.success_url =
          "https://payment.edu.assemblrworld.com" + `/Success`;
      } else if (duration === 2) {
        durationUrl = `&duration=6`;
        tempPayload.quantity = "6";
        tempPayload.plans = planDetail.month_id;
        tempPayload.api_url = "api/plans/request";
        tempPayload.success_url =
          "https://payment.edu.assemblrworld.com" + `/Success`;
      } else if (duration === 3) {
        durationUrl = `&duration=${customDuration}`;
        tempPayload.quantity = `${
          customDuration % 12 === 0 ? customDuration / 12 : customDuration
        }`;
        tempPayload.plans =
          customDuration % 12 === 0 ? planDetail.year_id : planDetail.month_id;
        tempPayload.api_url = "api/plans/request";
        tempPayload.success_url =
          "https://payment.edu.assemblrworld.com" + `/Success`;
      }
    }
    if (queryParams.type === "astu") {
      window.dataLayer.push({
        open_from: "https://studio.assemblrworld.com"
      })
      type = "astu";
      tempPayload.cancel_url =
        "https://studio.assemblrworld.com/failed";
      tempPayload.success_url =
        "https://studio.assemblrworld.com/success";
    } else if (queryParams.type === "edu") {
      window.dataLayer.push({
        open_from: "https://app-edu.assemblrworld.com"
      })
      type = "edu";
      tempPayload.cancel_url =
        "https://app-edu.assemblrworld.com/Account?status=failed";
      tempPayload.success_url =
        "https://app-edu.assemblrworld.com/Account?status=success";
    } else {
      type = "default";
      tempPayload.cancel_url =
        "https://payment.edu.assemblrworld.com" + `/Failed`;
    }

    if (queryParams.edu) {
      newUrl += count
        ? `?count=${count}&embed=true&edu=true&token=${token}&type=${type}&voucher=${code_voucher}` +
          durationUrl +
          methodUrl +
          schemeUrl
        : `?embed=true&edu=true&token=${token}&type=${type}&voucher=${code_voucher}` +
          durationUrl +
          methodUrl +
          schemeUrl;
    } else {
      if (queryParams.is_bundle === "true") {
        newUrl += count
          ? `?count=${count}&embed=true&token=${token}&type=${type}&is_bundle=true&id_bundle=${queryParams.id_bundle}&owner_bundle=${queryParams.owner_bundle}&voucher=${code_voucher}` +
            durationUrl +
            methodUrl +
            schemeUrl
          : `?embed=true&token=${token}&type=${type}&is_bundle=true&id_bundle=${queryParams.id_bundle}&owner_bundle=${queryParams.owner_bundle}&voucher=${code_voucher}` +
            durationUrl +
            methodUrl +
            schemeUrl;
      } else {
        newUrl += count
          ? `?count=${count}&embed=true&token=${token}&type=${type}&is_bundle=fals&id_bundle=${queryParams.id_bundle}&owner_bundle=${queryParams.owner_bundle}e&voucher=${code_voucher}` +
            durationUrl +
            methodUrl +
            schemeUrl
          : `?embed=true&token=${token}&type=${type}&is_bundle=false&id_bundle=${queryParams.id_bundle}&owner_bundle=${queryParams.owner_bundle}&voucher=${code_voucher}` +
            durationUrl +
            methodUrl +
            schemeUrl;
      }
    }
    if (queryParams.flow === "redeem") {
      newUrl += "&flow=redeem";
    }
    if (planDetail.name === "Custom Marker") {
      tempPayload.quantity = `${amount}`;
      tempPayload.api_url = `api/plans/request`;
      // tempPayload.payments = "3";
    }
    if (planDetail.name === "Blr Coin") {
      tempPayload.api_url = `api/v2/plans/request`;
      tempPayload.success_url = "https://payment.edu.assemblrworld.com" + "/SuccessTopupBlr?checkout=library";
      // tempPayload.payments = "3";
    }
    window.history.pushState({}, "", `/NewCheckout/${newUrl}`);
    setPayload(tempPayload);
  }, [period, duration, scheme, method, customDuration, token, amount]);

  useEffect(() => {
    switch (planDetail.name) {
      case "Custom Marker":
        if (+amount === 1) {
          setTotal(3);
        } else if (+amount === 5) {
          setTotal(15);
        } else if (+amount === 10) {
          setTotal(30);
        } else {
          setTotal(3 * amount);
        }
        break;

      case "Blr Coin":
        setTotal(planDetail.price * (customQty / 100));
        setScheme(1)
        // setDuration(0)
        break;

      default:
        if (+amount >= 10 && +amount < 30) {
          setTotal(`${+amount * planDetail.price.tier1}`);
        } else if (+amount >= 30 && +amount < 60) {
          setTotal(`${+amount * planDetail.price.tier2}`);
        } else if (+amount >= 60) {
          setTotal(`${+amount * planDetail.price.tier3}`);
        } else {
          if (scheme === 0) {
            let totalPrice = period === 0 ? planDetail.price : planDetail.price * 10
            setTotal(totalPrice)
          } else {
            setTotal(`${planDetail.price}`);
          }
        }
        break;
    }
  }, [amount, customQty, scheme, period]);

  useEffect(() => {
    if (planDetail.name === "Custom Marker" && amount < 1 && !first) {
      setAmount(1);
    } else if (planDetail.name === "School/Institutional" && amount < 10) {
      setAmount(10);
    }
    if (planDetail.plan === "marker" && amount === voucherDetail.duration_qty) {
      setAmount(voucherDetail.duration_qty)
      checkVoucher('same')
    }
  }, [tempAmount]);

  // useEffect(() => {
  //   const queryParams = queryString.parse(window.location.search);
  //   if ((!queryParams.voucher || typeof queryParams.voucher === "undefined") && samePlan) {
  //     setVoucher("");
  //     setErrorVoucher("");
  //   } else if (!samePlan) {
  //     setErrorVoucher("Voucher is not eligible for this plan");
  //   }
  // }, [tempVoucher, samePlan]);

  useEffect(() => {
    setTotal(
      `${
        planDetail.price * customDuration -
        2 * Math.floor(customDuration / 12) * planDetail.price
      }`
    );
  }, [customDuration]);

  useEffect(() => {
    if (params.id === "20" || params.id === "105") {
      setScheme(1);
      setDuration(0);
    }
  }, [params, location.search]);

  useEffect(() => {
    if (!voucher) {
      setVoucherDetail({});
    }

    if (voucher === "undefined") {
      setVoucher("");
    }
    if (voucher === currentVoucher && voucher !== "undefined" && voucher) {
      checkVoucher();
    } else {
      setVoucherDetail({});
    }
  }, [voucher, currentVoucher]);

  useEffect(() => {
    if (Object.keys(voucherDetail).length) {
      let planId = 0;
      if (!scheme) {
        planId =
          period === 0 || period === undefined
            ? planDetail.month_id
            : planDetail.year_id;
      } else {
        planId = planDetail.month_id;
      }
      let {
        payments,
      } = payload;
      if (planDetail.name === "Blr Coin") {
        switch (coin) {
          case 0:
            planId = navigator.userAgent.toLowerCase().includes("assemblr") ? 103 : payments == 3 ? 153 : 150
            break;
            
          case 1:
            planId = navigator.userAgent.toLowerCase().includes("assemblr") ? 104 : payments == 3 ? 154 : 151
            break;

          case 2:
            planId = navigator.userAgent.toLowerCase().includes("assemblr") ? 105 : payments == 3 ? 155 : 152
            break;
        
          default:
            break;
        }
      }
      const isSamePlan = voucherDetail.plans.some(el => el.id === planId)
      if (isSamePlan) {
        setSamePlan(true);
        setErrorVoucher("");
      } else {
        setSamePlan(false);
        if (planDetail.name === "Blr Coin") {
          setErrorVoucher(`Voucher is only eligible for ${voucherDetail.plans[0]?.plans}.`);
        } else {
          setErrorVoucher("Voucher is not eligible for this plan.");
        }
      }
      if (voucherDetail.duration_qty) {
        let planDuration = 0;
        switch (duration) {
          case 0:
            planDuration = 1;
            break;

          case 1:
            planDuration = 3;
            break;

          case 2:
            planDuration = 6;
            break;

          case 3:
            planDuration = +customDuration;
            break;

          default:
            break;
        }
        if (planDetail.plan !== "marker" && samePlan) {
          if (scheme && planDuration === voucherDetail.duration_qty) {
            setSameDuration(true);
            setErrorVoucher("");
            checkVoucher("same");
          } else if (!scheme && period === 0) {
            setSameDuration(true);
            setErrorVoucher("");
            checkVoucher("same");
          } else {
            setSameDuration(false);
            checkVoucher("different");
            setErrorVoucher(
              `Voucher is only eligible for ${voucherDetail.duration_qty} months plan.`
            );
          }
        } else if (planDetail.plan === "marker" && samePlan) {
          if (amount === voucherDetail.duration_qty) {
            setSameDuration(true);
            setErrorVoucher("");
            checkVoucher("same");
          } else {
            setSameDuration(false);
            checkVoucher("different");
            setErrorVoucher(
              `Voucher is only eligible for ${voucherDetail.duration_qty} custom marker.`
            );
          }
        }
      }
    }
  }, [voucherDetail, scheme, period, duration, customDuration, samePlan, coin]);

  // useEffect(() => {
  //     switch (duration) {
  //         case 0:
  //             (Object.keys(voucherDetail).length && samePlan && sameDuration) ? setPriceTotal(((planDetail.price * 1) - ((planDetail.price * 1) * (voucherDetail.benefit / 100)).toFixed(2))) : setPriceTotal((planDetail.price * 1).toFixed(2))
  //             break;

  //         case 1:
  //             (Object.keys(voucherDetail).length && samePlan && sameDuration) ? setPriceTotal(((planDetail.price * 3) - ((planDetail.price * 3) * (voucherDetail.benefit / 100))).toFixed(2)) : setPriceTotal((planDetail.price * 3).toFixed(2))
  //             break;

  //         case 2:
  //             (Object.keys(voucherDetail).length && samePlan && sameDuration) ? setPriceTotal(((planDetail.price * 6) - ((planDetail.price * 6) * (voucherDetail.benefit / 100))).toFixed(2)) : setPriceTotal((planDetail.price * 6).toFixed(2))
  //             break;

  //         default:
  //             (Object.keys(voucherDetail).length && samePlan && sameDuration) ? setPriceTotal(((planDetail.price * customDuration) - ((planDetail.price * customDuration) * (voucherDetail.benefit / 100))).toFixed(2)) : setPriceTotal((planDetail.price * customDuration).toFixed(2))
  //             break;
  //     }
  //     switch (duration) {
  //         case 0:
  //             setPlanPrice((planDetail.price * 1).toFixed(2))
  //             break;

  //         case 1:
  //             setPlanPrice((planDetail.price * 3).toFixed(2))
  //             break;

  //         case 2:
  //             setPlanPrice((planDetail.price * 6).toFixed(2))
  //             break;

  //         default:
  //             setPlanPrice((planDetail.price * customDuration).toFixed(2))
  //             break;
  //     }
  //     switch (duration) {
  //         case 0:
  //             setPlanDuration(`1 Month`)
  //             break;

  //         case 1:
  //             setPlanDuration(`3 Months`)
  //             break;

  //         case 2:
  //             setPlanDuration(`6 Months`)
  //             break;

  //         default:
  //             if (customDuration === 1) {
  //                 setPlanDuration(`${customDuration} Month`)
  //             } else {
  //                 !customDuration ? setPlanDuration(``) : setPlanDuration(`${customDuration} Months`)
  //             }
  //             break;
  //     }
  // }, [duration, customDuration, samePlan, sameDuration])

  // useEffect(() => {
  //     switch (period) {
  //         case 0:
  //             (Object.keys(voucherDetail).length) ? setPriceTotal(((planDetail.price * 1) - ((planDetail.price * 1) * (voucherDetail.benefit / 100)).toFixed(2))) : setPriceTotal((planDetail.price * 1).toFixed(2))
  //             break;

  //         case 1:
  //             (Object.keys(voucherDetail).length) ? setPriceTotal(((planDetail.annually_price * 1) - ((planDetail.annually_price * 1) * (voucherDetail.benefit / 100))).toFixed(2)) : setPriceTotal((planDetail.annually_price * 1).toFixed(2))
  //             break;
  //     }
  //     switch (period) {
  //         case 0:
  //             (setPlanPrice((planDetail.price * 1).toFixed(2)))
  //             break;

  //         case 1:
  //             (setPlanPrice((planDetail.annually_price * 1).toFixed(2)))
  //             break;
  //     }
  // }, [period])

  useEffect(() => {
    switch (duration) {
      case 0:
        Object.keys(voucherDetail).length && samePlan && sameDuration
          ? setPriceTotal(
              planDetail.price * 1 -
                planDetail.price * 1 * (voucherDetail.benefit / 100)
            )
          : setPriceTotal(planDetail.price * 1);
        break;

      case 1:
        Object.keys(voucherDetail).length && samePlan && sameDuration
          ? setPriceTotal(
              planDetail.price * 3 -
                planDetail.price * 3 * (voucherDetail.benefit / 100)
            )
          : setPriceTotal(planDetail.price * 3);
        break;

      case 2:
        Object.keys(voucherDetail).length && samePlan && sameDuration
          ? setPriceTotal(
              planDetail.price * 6 -
                planDetail.price * 6 * (voucherDetail.benefit / 100)
            )
          : setPriceTotal(planDetail.price * 6);
        break;

      default:
        Object.keys(voucherDetail).length && samePlan && sameDuration
          ? setPriceTotal(
              planDetail.price * customDuration -
                planDetail.price *
                  customDuration *
                  (voucherDetail.benefit / 100)
            )
          : setPriceTotal(planDetail.price * customDuration);
        break;
    }
    switch (duration) {
      case 0:
        setPlanDuration(`1 Month`);
        break;

      case 1:
        setPlanDuration(`3 Months`);
        break;

      case 2:
        setPlanDuration(`6 Months`);
        break;

      default:
        if (customDuration === 1) {
          setPlanDuration(`${customDuration} Month`);
        } else {
          !customDuration
            ? setPlanDuration(``)
            : setPlanDuration(`${customDuration} Months`);
        }
        break;
    }
  }, [duration, customDuration, samePlan, sameDuration]);

  useEffect(() => {
    // let qty = quantity ?
  }, [quantity, amount]);

  const getBundleDetail = async (id) => {
    try {
      let { data } = await instance.get(`/api/library/store/3d/?row=200`);

      if (data.bundle) {
        const dataBundle = data.bundle.find((data) => data.sid === id);
        const { name, editor_thumbnail, price } = dataBundle;
        setBundleDetail({
          name: name,
          thumbnail: editor_thumbnail,
          price: price,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const queryParams = queryString.parse(window.location.search);
    if (isBundle) {
      getBundleDetail(idBundle);
    }
  }, [isBundle, idBundle]);

  // useEffect(() => {
  // }, [coin])

  return (
    <>
      {firstLoading && (
        <div className="loader-container checkout-loading">
          <div className="loader2"></div>
        </div>
      )}
      <div
        id="checkout"
        className={`Main checkout ${!isMobile ? "tablet" : ""}${
          isLandscape ? " landscape" : ""
        }${planDetail.id === 20 ? " marker" : ""}`}
      >
        <div className="nav">
          <div className="header checkout">
            {hideClose ?
            null :
            <>
              {window.closeButton !== true && (
                <>
                  {!close ? (
                    <span
                      onClick={() => {
                        flow === "redeem"
                          ? navigate(`/Redeem?embed=true&token=${token}`)
                          : window.parent.postMessage("close popup", "*");
                      }}
                      className="material-icons back"
                    >
                      {flow === "redeem" ? "arrow_back_ios_new" : "close"}
                    </span>
                  ) : null}
                </>
              )}
            </>
            }
            <p className="headerTitle">{checkoutTitle}</p>
          </div>
        </div>

        <div className={`contentContainer ${!isMobile ? "tablet" : ""} ${(isFromApp && planDetail.name === "Blr Coin") ? "app-container" : ""}`}>
          {isMobile ? (
            <>
              {!isBundle ? (
                <>
                  {/* <h4 className="left-title grey">Item List</h4> */}
                  <Card
                    scheme={scheme}
                    first={first}
                    planId={
                      period === 1 && typeof period !== "undefined"
                        ? planDetail.year_id
                        : planDetail.month_id
                    }
                    item={planDetail}
                    quantity={quantity}
                    voucherDetail={voucherDetail}
                    voucher={Object.keys(voucherDetail).length}
                    period={planDuration}
                    subscribePeriod={period}
                    discountBenefit={voucherDetail.benefit}
                    checkout={true}
                    isMobile={isMobile}
                    error={errorVoucher}
                    amount={amount}
                    totalCustomMarker={total}
                    samePlan={samePlan}
                  />
                  {/* Payment Scheme */}
                  {planDetail.name !== "Custom Marker" && planDetail.name !== "Blr Coin" && (
                    <div
                      className={`paymentScheme ${!isMobile ? "tablet" : ""}`}
                    >
                      <p className="grey">Your Billing Models</p>
                      <div className="paymentWrapper">
                        <div
                          onClick={() => {
                            setScheme(0);
                            setMethod();
                            setPeriod(0);
                            setDuration();
                          }}
                          className={`${
                            scheme === 0 ? "method active" : "method"
                          }`}
                        >
                          {/* <p>
                            <input
                              type="radio"
                              readOnly
                              checked={scheme === 0}
                            />
                          </p> */}
                          <div>
                            <p>Subscription</p>
                            <p className="desc">
                              Pay monthly or yearly
                            </p>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            setScheme(1);
                            setMethod();
                            setPeriod();
                            setDuration(0);
                          }}
                          className={`${
                            scheme === 1 ? "method active" : "method"
                          }`}
                        >
                          {/* <p>
                            <input
                              type="radio"
                              readOnly
                              checked={scheme === 1}
                            />
                          </p> */}
                          <div>
                            <p>One-Time</p>
                            <p className="desc">
                              Pay only once
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Duration / Amount / Seats */}
                  {planDetail.name === "School/Institutional" ||
                  planDetail.name === "Custom Marker"  ? (
                    <div
                      className={`paymentScheme ${!isMobile ? "tablet" : ""}`}
                    >
                      {planDetail.name !== "Custom Marker" && planDetail.name !== "Blr Coin" ? (
                        <p>Seats</p>
                      ) : (
                        <p>Amount</p>
                      )}
                      <div className="paymentWrapper seats">
                        <div
                          className={`period seats ${
                            (period === 0 && planDetail.name === "Blr Coin") ? "active" : ""
                          }`}
                        >
                          <span
                            onClick={() => changeAmount(0)}
                            className="material-icons"
                          >
                            remove
                          </span>
                          <input
                            value={amount}
                            placeholder="0"
                            onChange={(e) => setAmount(e.target.value)}
                            min={planDetail.name === "Custom Marker" ? 1 : planDetail.name === "Blr Coin" ? 100 : 10}
                            type="number"
                            name="amount"
                          />
                          <span
                            onClick={() => changeAmount(1)}
                            className="material-icons"
                          >
                            add
                          </span>
                          {planDetail.name !== "Custom Marker" && planDetail.name !== "Blr Coin" ? (
                            <>
                              <p>Seats</p>
                              <div>
                                {amount < 10 ? (
                                  <p>0 USD</p>
                                ) : (
                                  <p>{total} USD</p>
                                )}
                                <p className="desc">/ year</p>
                              </div>
                            </>
                          ) : (
                            <div>
                              <p>${total}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {(planDetail.name !== "Custom Marker" && planDetail.name !== "Blr Coin") && (
                        <>
                          <div className="prices">
                            <p
                              className={`${
                                amount >= 10 && amount < 30 ? "active" : ""
                              }`}
                            >
                              10 - 29 seats
                            </p>
                            <p
                              className={`${
                                amount >= 10 && amount < 30 ? "active" : ""
                              }`}
                            >
                              ${planDetail.price.tier1} / year / seats
                            </p>
                          </div>
                          <div className="prices">
                            <p
                              className={`${
                                amount >= 30 && amount < 60 ? "active" : ""
                              }`}
                            >
                              30 - 59 seats
                            </p>
                            <p
                              className={`${
                                amount >= 30 && amount < 60 ? "active" : ""
                              }`}
                            >
                              ${planDetail.price.tier2} / year / seats
                            </p>
                          </div>
                          <div className="prices">
                            <p className={`${amount >= 60 ? "active" : ""}`}>
                              &gt; 60 seats
                            </p>
                            <p className={`${amount >= 60 ? "active" : ""}`}>
                              ${planDetail.price.tier3} / year / seats
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <div
                      className={`paymentScheme ${!isMobile ? "tablet" : ""}`}
                    >
                      {(scheme === 0 || typeof scheme === "undefined") && planDetail.name !== 'Blr Coin' ? (
                        <>
                          <p className="grey">Duration</p>
                          <div className="paymentWrapper">
                            {typeof scheme !== "undefined" ? (
                              <>
                                <div
                                  onClick={() => setPeriod(0)}
                                  className={`period ${
                                    period === 0 ? "active" : ""
                                  }`}
                                >
                                  <p>Monthly</p>
                                  {Object.keys(voucherDetail).length ? (
                                    <>
                                      <p className="final-price free checkout-price">
                                        {(planDetail.price - planDetail.price * (voucherDetail.benefit / 100)).toFixed(2)}{" "}
                                        USD
                                      </p>
                                      <p className="start-price checkout-layout">
                                        {(planDetail.price).toFixed(2)}{" "}
                                        USD
                                      </p>
                                    </>
                                  ) : (
                                    <p className="desc duration">
                                      {planDetail.price} USD
                                    </p>
                                  )}
                                </div>
                                <div
                                  onClick={() => setPeriod(1)}
                                  className={`period ${
                                    period === 1 ? "active" : ""
                                  }`}
                                >
                                  <p>Yearly</p>
                                  <p className="desc">
                                    {(planDetail.price * 10).toFixed(2)} USD
                                  </p>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className={`period disable ${
                                    period === 0 ? "active" : ""
                                  }`}
                                >
                                  <p>Monthly</p>
                                  {Object.keys(voucherDetail).length &&
                                  samePlan ? (
                                    <>
                                      <p className="final-price free checkout-price">
                                        {(
                                          planDetail.price * voucherDetail.duration_qty -
                                          planDetail.price *
                                            voucherDetail.duration_qty *
                                            (voucherDetail.benefit / 100)
                                        ).toFixed(2)}{" "}
                                        USD
                                      </p>
                                      <p className="start-price checkout-layout">
                                        {(planDetail.price * voucherDetail.duration_qty).toFixed(
                                          2
                                        )}{" "}
                                        USD
                                      </p>
                                    </>
                                  ) : (
                                    <p className="desc duration">
                                      {planDetail.price} USD
                                    </p>
                                  )}
                                </div>
                                <div
                                  className={`period disable ${
                                    period === 1 ? "active" : ""
                                  }`}
                                >
                                  <p>Yearly</p>
                                  <p className="desc">
                                    {(planDetail.price * 10).toFixed(2)} USD
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          {planDetail.name === 'Blr Coin' ?
                          <div className="paymentWrapper blr-coin">
                            <div onClick={() => {
                                setCoin(0)
                                setDuration(0)
                                if (isFromApp) {
                                  setMethod(4)
                                  setScheme(0)
                                  localStorage.setItem('coin', coinPlanList[3]?.coin)
                                } else localStorage.setItem('coin', coinPlanList[0]?.coin)
                              }} className={`period ${coin === 0 ? 'active' : ''}`}>
                                <IconBlrCoin />
                                <p>
                                  + {isFromApp ? coinPlanList[3]?.coin : coinPlanList[0]?.coin} <span>BLR</span>
                                </p>
                                {(Object.keys(voucherDetail).length && samePlan && voucherDetail.quantity === 1) ?
                                    <>
                                        <p className="final-price free checkout-price">{((planDetail.price * quantity) - (planDetail.price * quantity) * (voucherDetail.benefit / 100)).toFixed(2)} USD</p>
                                        <p className='start-price checkout-layout'>{(planDetail.price * quantity).toFixed(2)} USD</p>
                                    </> :
                                    <>
                                      {coinPlanList.length ?
                                        <p className='desc'>
                                          {method == 1 || method == 2 ? `${currencyFormat((coinPlanList[0].priceMidtrans), 'idr')}` : isFromApp ? `${currencyFormat((coinPlanList[3].priceIAP), 'usd')}` : `${currencyFormat((coinPlanList[0].priceStripe), 'usd')}`}
                                        </p> : null
                                      }
                                    </>
                                }
                            </div>
                            <div onClick={() => {
                                setCoin(1)
                                setDuration(0)
                                if (isFromApp) {
                                  setMethod(4)
                                  setScheme(0)
                                  localStorage.setItem('coin', coinPlanList[4]?.coin)
                                } else localStorage.setItem('coin', coinPlanList[1]?.coin)
                              }} className={`period ${coin === 1 ? 'active' : ''}`}>
                                <IconBlrCoin />
                                <p>
                                  + {isFromApp ? coinPlanList[4]?.coin : coinPlanList[1]?.coin} <span>BLR</span>
                                </p>
                                {(Object.keys(voucherDetail).length && samePlan && voucherDetail.quantity === 1) ?
                                    <>
                                        <p className="final-price free checkout-price">{((planDetail.price * quantity) - (planDetail.price * quantity) * (voucherDetail.benefit / 100)).toFixed(2)} USD</p>
                                        <p className='start-price checkout-layout'>{(planDetail.price * quantity).toFixed(2)} USD</p>
                                    </> :
                                    <>
                                      {coinPlanList.length ?
                                        <p className='desc'>
                                          {method == 1 || method == 2 ? `${currencyFormat((coinPlanList[1].priceMidtrans), 'idr')}` : isFromApp ? `${currencyFormat((coinPlanList[4].priceIAP), 'usd')}` : `${currencyFormat((coinPlanList[1].priceStripe), 'usd')}`}
                                        </p> : null
                                      }
                                    </>
                                }
                            </div>
                            <div onClick={() => {
                                setCoin(2)
                                setDuration(0)
                                if (isFromApp) {
                                  setMethod(4)
                                  setScheme(0)
                                  localStorage.setItem('coin', coinPlanList[5]?.coin)
                                } else localStorage.setItem('coin', coinPlanList[2]?.coin)
                              }} className={`period ${coin === 2 ? 'active' : ''}`}>
                                <IconBlrCoin />
                                <p>
                                  + {isFromApp ? coinPlanList[5]?.coin : coinPlanList[2]?.coin} <span>BLR</span>
                                </p>
                                {(Object.keys(voucherDetail).length && samePlan && voucherDetail.quantity === 1) ?
                                    <>
                                        <p className="final-price free checkout-price">{((planDetail.price * quantity) - (planDetail.price * quantity) * (voucherDetail.benefit / 100)).toFixed(2)} USD</p>
                                        <p className='start-price checkout-layout'>{(planDetail.price * quantity).toFixed(2)} USD</p>
                                    </> :
                                    <>
                                      {coinPlanList.length ?
                                        <p className='desc'>
                                          {method == 1 || method == 2 ? `${currencyFormat((coinPlanList[2].priceMidtrans), 'idr')}` : isFromApp ? `${currencyFormat((coinPlanList[5].priceIAP), 'usd')}` : `${currencyFormat((coinPlanList[2].priceStripe), 'usd')}`}
                                        </p> : null
                                      }
                                    </>
                                }
                            </div>
                          </div> : null
                          }
                          <>
                            {planDetail.name !== 'Blr Coin' ?
                            <p className="grey">Duration <span>(month)</span></p> :
                            // <p className="grey">Or, enter custom amount</p>
                            null
                            }
                            {planDetail.name !== 'Blr Coin' ?
                            <div className="paymentWrapper custom-blr-coin">
                              <div
                                onClick={() => setCoin(3)}
                                className={`period-custom period`}
                              >
                                <div className="custom">
                                  <div
                                    className="button-duration disable-select"
                                    onClick={() => {
                                      if (planDetail.name !== 'Blr Coin') {
                                        if (customDuration <= 1) return;
                                        setCustomDuration(customDuration - 1);
                                      } else {
                                        if (customQty <= 100) return;
                                        setCustomQty(customQty - 100);
                                      }
                                    }}
                                  >
                                    <div className="minus disable-select">
                                      <h3>-</h3>
                                    </div>
                                  </div>
                                  <div className="input-duration">
                                    <input
                                      className="blr-coin"
                                      value={planDetail.name !== 'Blr Coin' ? customDuration : customQty}
                                      disabled
                                      onChange={(e) => {
                                        if (planDetail.name !== 'Blr Coin') {
                                          setCustomDuration(e.target.value)
                                        } else {
                                          setCustomQty(e.target.value);
                                        }
                                      }}
                                      min={planDetail.name !== 'Blr Coin' ? 1 : 100}
                                      type="number"
                                      name="amount"
                                    />
                                    {planDetail.name !== 'Blr Coin' ?
                                    <p>
                                      {customDuration === 1 ? "Month" : "Months"}
                                    </p> : null
                                    }
                                  </div>
                                  <div
                                    className="button-duration disable-select"
                                    onClick={() => {
                                      if (planDetail.name !== 'Blr Coin') {
                                        if (customDuration < 1) return;
                                        setCustomDuration(customDuration + 1);
                                      } else {
                                        if (customQty < 100) return;
                                        setCustomQty(customQty + 100);
                                      }
                                    }}
                                  >
                                    <div className="plus disable-select">
                                      <h3>+</h3>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={`period total-price`}>
                                {Object.keys(voucherDetail).length &&
                                samePlan &&
                                voucherDetail.duration_qty === +customDuration ? (
                                  <div className="custom-price-discount">
                                    <p className="final-price free checkout-price custom-voucher">
                                      {(
                                        planDetail.price * voucherDetail.duration_qty -
                                        planDetail.price *
                                          voucherDetail.duration_qty *
                                          (voucherDetail.benefit / 100)
                                      ).toFixed(2)}{" "}
                                      USD
                                    </p>
                                    <p className="start-price checkout-layout">
                                      {(planDetail.price * voucherDetail.duration_qty).toFixed(2)}{" "}
                                      USD
                                    </p>
                                  </div>
                                ) : (
                                  <p className="total">
                                    {planDetail.name !== 'Blr Coin' ?
                                    <>
                                      {`${(+total).toFixed(2)}`} USD
                                    </> :
                                    <>
                                      {`${(+total).toFixed(2)}`} USD
                                    </>
                                    }
                                  </p>
                                )}
                              </div>
                            </div> : null
                            }
                          </>
                        </>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <>
                  <h4 className="left-title grey">Item List</h4>
                  <div className="bundle mobile">
                    <div className="content-data">
                      <div className="image-container">
                        <img src={bundleDetail.thumbnail} alt="thumbnail" />
                      </div>
                      <div className="detail">
                        <div className="bundle-container">
                          <div className="bundle-name">{bundleDetail.name}</div>
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/pro_packs.png"
                            }
                            alt="pro packs"
                          />
                        </div>
                        <div className="bundle-owner">by {ownerBundle}</div>
                      </div>
                    </div>
                    <div className="content-detail">
                      <div className="content-price">
                        <p className="price">{bundleDetail.price} USD</p>
                        <div className="button-remove">Remove</div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          ) : (
            <div className="left-container">
              {isBundle ? (
                <>
                  <h4 className="left-title grey">Item List</h4>
                  <div className="bundle">
                    <div className="content-data">
                      <div className="image-container">
                        <img src={bundleDetail.thumbnail} alt="thumbnail" />
                      </div>
                      <div className="detail">
                        <div className="bundle-container">
                          <div className="bundle-name">{bundleDetail.name}</div>
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/pro_packs.png"
                            }
                            alt="pro packs"
                          />
                        </div>
                        <div className="bundle-owner">by {ownerBundle}</div>
                      </div>
                    </div>
                    <div className="content-detail">
                      <div className="content-price">
                        <p className="price">{bundleDetail.price} USD</p>
                        <div className="button-remove">Remove</div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {!isMobile && (
                    <>
                      <h4 className="left-title grey">Item List</h4>
                      <Card
                        scheme={scheme}
                        first={first}
                        planId={
                          period === 1 && typeof period !== "undefined"
                            ? planDetail.year_id
                            : planDetail.month_id
                        }
                        item={planDetail}
                        quantity={quantity}
                        voucherDetail={voucherDetail}
                        voucher={Object.keys(voucherDetail).length}
                        period={planDuration}
                        subscribePeriod={period}
                        discountBenefit={voucherDetail.benefit}
                        checkout={true}
                        isMobile={isMobile}
                        error={errorVoucher}
                        amount={amount}
                        totalCustomMarker={total}
                        samePlan={samePlan}
                      />
                    </>
                  )}
                  {/* Payment Scheme */}
                  {(planDetail.name !== "Custom Marker" && planDetail.name !== "Blr Coin") && (
                    <div
                      className={`paymentScheme ${!isMobile ? "tablet" : ""}`}
                    >
                      <p className="grey">Your Billing Models</p>
                      <div className="paymentWrapper">
                        <div
                          onClick={() => {
                            setScheme(0);
                            setMethod();
                            setPeriod(0);
                            setDuration();
                          }}
                          className={`${
                            scheme === 0 ? "method active" : "method"
                          }`}
                        >
                          <p>
                            <input
                              type="radio"
                              readOnly
                              checked={scheme === 0}
                            />
                          </p>
                          <div>
                            <p>Subscription</p>
                            <p className="desc">
                              Pay monthly or yearly
                            </p>
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            setScheme(1);
                            setMethod();
                            setPeriod();
                            setDuration(0);
                          }}
                          className={`${
                            scheme === 1 ? "method active" : "method"
                          }`}
                        >
                          <p>
                            <input
                              type="radio"
                              readOnly
                              checked={scheme === 1}
                            />
                          </p>
                          <div>
                            <p>One-Time</p>
                            <p className="desc">
                              Pay only once
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {/* Duration / Amount / Seats */}
                  {planDetail.name === "School/Institutional" ||
                  planDetail.name === "Custom Marker" || planDetail.name === "Blr Coin" ? (
                    <div
                      className={`paymentScheme ${!isMobile ? "tablet" : ""}`}
                    >
                      {(planDetail.name !== "Custom Marker" && planDetail.name !== "Blr Coin") ? (
                        <p>Seats</p>
                      ) : (
                        <p>Amount</p>
                      )}
                      <div className="paymentWrapper seats">
                        <div
                          className={`period seats ${
                            period === 0 ? "active" : ""
                          }`}
                        >
                          <span
                            onClick={() => changeAmount(0)}
                            className="material-icons"
                          >
                            remove
                          </span>
                          <input
                            value={amount}
                            placeholder="0"
                            onChange={(e) => setAmount(e.target.value)}
                            min={planDetail.name === "Custom Marker" ? 1 : planDetail.name === "Blr Coin" ? 100 : 10}
                            type="number"
                            name="amount"
                          />
                          <span
                            onClick={() => changeAmount(1)}
                            className="material-icons"
                          >
                            add
                          </span>
                          {planDetail.name !== "Custom Marker" && planDetail.name !== "Blr Coin" ? (
                            <>
                              <p>Seats</p>
                              <div>
                                {amount < 10 ? (
                                  <p>0 USD</p>
                                ) : (
                                  <p>{total} USD</p>
                                )}
                                <p className="desc">/ year</p>
                              </div>
                            </>
                          ) : (
                            <div>
                              <p>${total}</p>
                            </div>
                          )}
                        </div>
                      </div>
                      {planDetail.name !== "Custom Marker" && planDetail.name !== "Blr Coin" && (
                        <>
                          <div className="prices">
                            <p
                              className={`${
                                amount >= 10 && amount < 30 ? "active" : ""
                              }`}
                            >
                              10 - 29 seats
                            </p>
                            <p
                              className={`${
                                amount >= 10 && amount < 30 ? "active" : ""
                              }`}
                            >
                              ${planDetail.price.tier1} / year / seats
                            </p>
                          </div>
                          <div className="prices">
                            <p
                              className={`${
                                amount >= 30 && amount < 60 ? "active" : ""
                              }`}
                            >
                              30 - 59 seats
                            </p>
                            <p
                              className={`${
                                amount >= 30 && amount < 60 ? "active" : ""
                              }`}
                            >
                              ${planDetail.price.tier2} / year / seats
                            </p>
                          </div>
                          <div className="prices">
                            <p className={`${amount >= 60 ? "active" : ""}`}>
                              &gt; 60 seats
                            </p>
                            <p className={`${amount >= 60 ? "active" : ""}`}>
                              ${planDetail.price.tier3} / year / seats
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <div
                      className={`paymentScheme ${!isMobile ? "tablet" : ""}`}
                    >
                      {scheme === 0 || typeof scheme === "undefined" ? (
                        <>
                          <p className="grey">Duration</p>
                          <div className="paymentWrapper">
                            {typeof scheme !== "undefined" ? (
                              <>
                                <div
                                  onClick={() => setPeriod(0)}
                                  className={`period ${
                                    period === 0 ? "active" : ""
                                  }`}
                                >
                                  <p>Monthly</p>
                                  {Object.keys(voucherDetail).length ? (
                                    <>
                                      <p className="final-price free checkout-price">
                                        {(
                                          planDetail.price * voucherDetail.duration_qty -
                                          planDetail.price *
                                            voucherDetail.duration_qty *
                                            (voucherDetail.benefit / 100)
                                        ).toFixed(2)}{" "}
                                        USD
                                      </p>
                                      <p className="start-price checkout-layout">
                                        {(planDetail.price * voucherDetail.duration_qty).toFixed(
                                          2
                                        )}{" "}
                                        USD
                                      </p>
                                    </>
                                  ) : (
                                    <p className="desc duration">
                                      {planDetail.price} USD
                                    </p>
                                  )}
                                </div>
                                <div
                                  onClick={() => setPeriod(1)}
                                  className={`period ${
                                    period === 1 ? "active" : ""
                                  }`}
                                >
                                  <p>Yearly</p>
                                  <p className="desc">
                                    {(planDetail.price * 10).toFixed(2)} USD
                                  </p>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className={`period disable ${
                                    period === 0 ? "active" : ""
                                  }`}
                                >
                                  <p>Monthly</p>
                                  {Object.keys(voucherDetail).length &&
                                  samePlan ? (
                                    <>
                                      <p className="final-price free checkout-price">
                                        {(
                                          planDetail.price * voucherDetail.duration_qty -
                                          planDetail.price *
                                            voucherDetail.duration_qty *
                                            (voucherDetail.benefit / 100)
                                        ).toFixed(2)}{" "}
                                        USD
                                      </p>
                                      <p className="start-price checkout-layout">
                                        {(planDetail.price * voucherDetail.duration_qty).toFixed(
                                          2
                                        )}{" "}
                                        USD
                                      </p>
                                    </>
                                  ) : (
                                    <p className="desc duration">
                                      {planDetail.price} USD
                                    </p>
                                  )}
                                </div>
                                <div
                                  className={`period disable ${
                                    period === 1 ? "active" : ""
                                  }`}
                                >
                                  <p>Yearly</p>
                                  <p className="desc">
                                    {(planDetail.price * 10).toFixed(2)} USD
                                  </p>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                            {planDetail.name !== 'Blr Coin' ?
                            <p className="grey">Duration <span>(month)</span></p> :
                            // <p className="grey">Or, enter custom amount</p>
                            null
                            }
                            {planDetail.name !== 'Blr Coin' ?
                            <div className="paymentWrapper custom-blr-coin">
                              <div
                                onClick={() => setCoin(3)}
                                className={`period-custom period`}
                              >
                                <div className="custom">
                                  <div
                                    className="button-duration disable-select"
                                    onClick={() => {
                                      if (planDetail.name !== 'Blr Coin') {
                                        if (customDuration <= 1) return;
                                        setCustomDuration(customDuration - 1);
                                      } else {
                                        if (customQty <= 100) return;
                                        setCustomQty(customQty - 100);
                                      }
                                    }}
                                  >
                                    <div className="minus disable-select">
                                      <h3>-</h3>
                                    </div>
                                  </div>
                                  <div className="input-duration">
                                    <input
                                      className="blr-coin"
                                      value={planDetail.name !== 'Blr Coin' ? customDuration : customQty}
                                      disabled
                                      onChange={(e) => {
                                        if (planDetail.name !== 'Blr Coin') {
                                          setCustomDuration(e.target.value)
                                        } else {
                                          setCustomQty(e.target.value);
                                        }
                                      }}
                                      min={planDetail.name !== 'Blr Coin' ? 1 : 100}
                                      type="number"
                                      name="amount"
                                    />
                                    {planDetail.name !== 'Blr Coin' ?
                                    <p>
                                      {customDuration === 1 ? "Month" : "Months"}
                                    </p> : null
                                    }
                                  </div>
                                  <div
                                    className="button-duration disable-select"
                                    onClick={() => {
                                      if (planDetail.name !== 'Blr Coin') {
                                        if (customDuration < 1) return;
                                        setCustomDuration(customDuration + 1);
                                      } else {
                                        if (customQty < 100) return;
                                        setCustomQty(customQty + 100);
                                      }
                                    }}
                                  >
                                    <div className="plus disable-select">
                                      <h3>+</h3>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className={`period total-price`}>
                                {Object.keys(voucherDetail).length &&
                                samePlan &&
                                voucherDetail.duration_qty === +customDuration ? (
                                  <div className="custom-price-discount">
                                    <p className="final-price free checkout-price custom-voucher">
                                      {(
                                        planDetail.price * voucherDetail.duration_qty -
                                        planDetail.price *
                                          voucherDetail.duration_qty *
                                          (voucherDetail.benefit / 100)
                                      ).toFixed(2)}{" "}
                                      USD
                                    </p>
                                    <p className="start-price checkout-layout">
                                      {(planDetail.price * voucherDetail.duration_qty).toFixed(2)}{" "}
                                      USD
                                    </p>
                                  </div>
                                ) : (
                                  <p className="total">
                                    {planDetail.name !== 'Blr Coin' ?
                                    <>
                                      {`${(+total).toFixed(2)}`} USD
                                    </> :
                                    <>
                                      {`${(+total).toFixed(2)}`} USD
                                    </>
                                    }
                                  </p>
                                )}
                              </div>
                            </div> : null
                            }
                          </>
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          <div className="right-container">
            {/* Payment Method */}
            {/* <h4 className="right-title grey">Payment</h4> */}
            {isFromApp ?
            null :
            <>
              <div className={`paymentScheme ${!isMobile ? "tablet" : ""}`}>
                <p className="grey">Choose Payment Method</p>
                {planDetail.name !== "School/Institutional" ? (
                  <div className="paymentWrapper paymentMethod">
                    {scheme ? (
                      <>
                        {typeof duration === "undefined" ? (
                          <>
                            <div
                              className={`method ${
                                typeof duration === "undefined" ? "disable" : ""
                              } ${method === 0 ? "active" : ""}`}
                            >
                              <p>
                                <input
                                  type="radio"
                                  readOnly
                                  checked={method === 0}
                                />
                              </p>
                              <div className='payment-text-container'>
                                <p>Credit/Debit Card</p>
                                <p className="desc method">
                                  Mastercard, Visa, & JBC
                                </p>
                              </div>
                            </div>
                            <div
                              className={`method ${
                                typeof duration === "undefined" ? "disable" : ""
                              } ${method === 1 ? "active" : ""}`}
                            >
                              <p>
                                <input
                                  type="radio"
                                  readOnly
                                  checked={method === 1}
                                />
                              </p>
                              <div className='payment-text-container'>
                                <p>Bank Transfer</p>
                                <p className="desc method">
                                  BNI, Permata, & etc.
                                </p>
                              </div>
                            </div>
                            <div
                              className={`method ${
                                typeof duration === "undefined" ? "disable" : ""
                              } ${method === 2 ? "active" : ""}`}
                            >
                              <p>
                                <input
                                  type="radio"
                                  readOnly
                                  checked={method === 2}
                                />
                              </p>
                              <div className='payment-text-container'>
                                <p>QRIS</p>
                                <p className="desc method">
                                  GoPay, DANA, & LinkAja
                                </p>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              onClick={() => setMethod(0)}
                              className={`method ${
                                typeof duration === "undefined" ? "disable" : ""
                              } ${method === 0 ? "active" : ""}`}
                            >
                              <p>
                                <input
                                  type="radio"
                                  readOnly
                                  checked={method === 0}
                                />
                              </p>
                              <div className='payment-text-container'>
                                <p>Credit/Debit Card</p>
                                <p className="desc method">
                                  Mastercard, Visa, & JBC
                                </p>
                              </div>
                            </div>
                            <div
                              onClick={() => setMethod(1)}
                              className={`method ${
                                typeof duration === "undefined" ? "disable" : ""
                              } ${method === 1 ? "active" : ""}`}
                            >
                              <p>
                                <input
                                  type="radio"
                                  readOnly
                                  checked={method === 1}
                                />
                              </p>
                              <div className='payment-text-container'>
                                <p>Bank Transfer</p>
                                <p className="desc method">
                                  BNI, Permata, & etc.
                                </p>
                              </div>
                            </div>
                            <div
                              onClick={() => setMethod(2)}
                              className={`method ${
                                typeof duration === "undefined" ? "disable" : ""
                              } ${method === 2 ? "active" : ""}`}
                            >
                              <p>
                                <input
                                  type="radio"
                                  readOnly
                                  checked={method === 2}
                                />
                              </p>
                              <div className='payment-text-container'>
                                <p>QRIS</p>
                                <p className="desc method">
                                  GoPay, DANA, & LinkAja
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {typeof period === "undefined" ? (
                          <div
                            className={`method ${
                              typeof period === "undefined" ? "disable" : ""
                            }`}
                          >
                            <p>
                              <input
                                type="radio"
                                readOnly
                                checked={method === 0}
                              />
                            </p>
                            <div className='payment-text-container'>
                              <p>Credit/Debit Card</p>
                              <p className="desc method">
                                Mastercard, Visa, & JBC
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div
                            onClick={() => setMethod(0)}
                            className={`method ${
                              typeof period === "undefined" ? "disable" : ""
                            } ${method === 0 ? "active" : ""}`}
                          >
                            <p>
                              <input
                                type="radio"
                                readOnly
                                checked={method === 0}
                              />
                            </p>
                            <div className='payment-text-container'>
                              <p>Credit/Debit Card</p>
                              <p className="desc method">
                                Mastercard, Visa, & JBC
                              </p>
                            </div>
                          </div>
                        )}
                        <div className={`method disable`}>
                          <p>
                            <input type="radio" readOnly checked={method === 1} />
                          </p>
                          <div className='payment-text-container'>
                            <p>Bank Transfer</p>
                            <p className="desc method">BNI, Permata, & etc.</p>
                          </div>
                        </div>
                        <div className={`method disable`}>
                          <p>
                            <input type="radio" readOnly checked={method === 2} />
                          </p>
                          <div className='payment-text-container'>
                            <p>QRIS</p>
                            <p className="desc method">GoPay, DANA, & LinkAja</p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  <div className="paymentWrapper paymentMethod">
                    {scheme ? (
                      <>
                        <div
                          onClick={() => setMethod(0)}
                          className={`method ${scheme === 0 ? "disable" : ""} ${
                            method === 0 ? "active" : ""
                          }`}
                        >
                          <p>
                            <input type="radio" readOnly checked={method === 0} />
                          </p>
                          <div className='payment-text-container'
                          >
                            <p>Credit/Debit Card</p>
                            <p className="desc method">Mastercard, Visa, & JBC</p>
                          </div>
                        </div>
                        <div
                          onClick={() => setMethod(1)}
                          className={`method ${scheme === 0 ? "disable" : ""} ${
                            method === 1 ? "active" : ""
                          }`}
                        >
                          <p>
                            <input type="radio" readOnly checked={method === 1} />
                          </p>
                          <div className='payment-text-container'
                          >
                            <p>Bank Transfer</p>
                            <p className="desc method">BNI, Permata, & etc.</p>
                          </div>
                        </div>
                        <div
                          onClick={() => setMethod(2)}
                          className={`method ${scheme === 0 ? "disable" : ""} ${
                            method === 2 ? "active" : ""
                          }`}
                        >
                          <p>
                            <input type="radio" readOnly checked={method === 2} />
                          </p>
                          <div className='payment-text-container'
                          >
                            <p>QRIS</p>
                            <p className="desc method">GoPay, DANA, & LinkAja</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div
                          onClick={() => setMethod(0)}
                          className={`method ${scheme === 1 ? "disable" : ""} ${method === 0 ? "active" : ""}`}
                        >
                          <p>
                            <input type="radio" readOnly checked={method === 0} />
                          </p>
                          <div className='payment-text-container'
                          >
                            <p>Credit/Debit Card</p>
                            <p className="desc method">Mastercard, Visa, & JBC</p>
                          </div>
                        </div>
                        <div className={`method disable`}>
                          <p>
                            <input type="radio" readOnly checked={method === 1} />
                          </p>
                          <div className='payment-text-container'
                          >
                            <p>Bank Transfer</p>
                            <p className="desc method">BNI, Permata, & etc.</p>
                          </div>
                        </div>
                        <div className={`method disable`}>
                          <p>
                            <input type="radio" readOnly checked={method === 2} />
                          </p>
                          <div className='payment-text-container'
                          >
                            <p>QRIS</p>
                            <p className="desc method">GoPay, DANA, & LinkAja</p>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className="paymentScheme">
                <p className="grey">
                  Insert your voucher code to get discount...
                </p>
                <div className="voucher-container">
                  <input
                    className="voucher-input"
                    value={voucher}
                    type={"text"}
                    name="voucher"
                    placeholder="Voucher Code"
                    onChange={(e) => {
                      setVoucher(e.target.value)
                      setErrorVoucher("")
                    }}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        checkVoucher();
                      }
                    }}
                  />
                  {errorVoucher ? (
                    <div
                      className={`voucher-view ${
                        !voucher ? "search-button-blue" : "search-button-red"
                      }`}
                    >
                      {Object.keys(voucherDetail).length && samePlan ? (
                        <>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="20"
                              height="20"
                              rx="10"
                              fill="#AA0101"
                            />
                            <path
                              d="M14.6517 14.6191C14.7827 14.4911 14.858 14.3166 14.861 14.1334C14.8641 13.9503 14.7948 13.7733 14.6681 13.641L10.9946 9.96745L14.5635 6.39854C14.6915 6.26547 14.7626 6.08766 14.7616 5.90298C14.7605 5.71829 14.6874 5.54132 14.5578 5.40972C14.4266 5.28089 14.2501 5.20871 14.0662 5.20871C13.8824 5.20871 13.7058 5.28089 13.5747 5.40972L10.0057 8.97863L6.32879 5.30167C6.19667 5.17402 6.0194 5.10386 5.8357 5.10651C5.65201 5.10916 5.47684 5.18441 5.34845 5.31582C5.21836 5.44349 5.14418 5.61753 5.14217 5.7998C5.14015 5.98207 5.21047 6.15771 5.33771 6.28823L9.01466 9.96518L5.44066 13.5392C5.31183 13.6704 5.23965 13.8469 5.23965 14.0308C5.23965 14.2146 5.31183 14.3912 5.44066 14.5223C5.57226 14.6519 5.74924 14.725 5.93392 14.7261C6.11861 14.7271 6.29641 14.6561 6.42948 14.528L10.0035 10.954L13.6793 14.6298C13.8098 14.7571 13.9855 14.8274 14.1677 14.8254C14.35 14.8234 14.524 14.7492 14.6517 14.6191Z"
                              fill="white"
                            />
                          </svg>
                          <p>Invalid</p>
                        </>
                      ) : (
                        <>
                          {loading ? (
                            <div className="disable button-disable">
                              <div className="loader"></div>
                            </div>
                          ) : (
                            <>
                              {!voucher ? (
                                <p>Validate</p>
                              ) : (
                                <div className="error-status-voucher">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      width="20"
                                      height="20"
                                      rx="10"
                                      fill="#AA0101"
                                    />
                                    <path
                                      d="M14.6517 14.6191C14.7827 14.4911 14.858 14.3166 14.861 14.1334C14.8641 13.9503 14.7948 13.7733 14.6681 13.641L10.9946 9.96745L14.5635 6.39854C14.6915 6.26547 14.7626 6.08766 14.7616 5.90298C14.7605 5.71829 14.6874 5.54132 14.5578 5.40972C14.4266 5.28089 14.2501 5.20871 14.0662 5.20871C13.8824 5.20871 13.7058 5.28089 13.5747 5.40972L10.0057 8.97863L6.32879 5.30167C6.19667 5.17402 6.0194 5.10386 5.8357 5.10651C5.65201 5.10916 5.47684 5.18441 5.34845 5.31582C5.21836 5.44349 5.14418 5.61753 5.14217 5.7998C5.14015 5.98207 5.21047 6.15771 5.33771 6.28823L9.01466 9.96518L5.44066 13.5392C5.31183 13.6704 5.23965 13.8469 5.23965 14.0308C5.23965 14.2146 5.31183 14.3912 5.44066 14.5223C5.57226 14.6519 5.74924 14.725 5.93392 14.7261C6.11861 14.7271 6.29641 14.6561 6.42948 14.528L10.0035 10.954L13.6793 14.6298C13.8098 14.7571 13.9855 14.8274 14.1677 14.8254C14.35 14.8234 14.524 14.7492 14.6517 14.6191Z"
                                      fill="white"
                                    />
                                  </svg>
                                  <p>Invalid</p>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  ) : (
                    <div
                      onClick={
                        voucher && !Object.keys(voucherDetail).length
                          ? () => checkVoucher()
                          : null
                      }
                      className={`${
                        voucher === currentVoucher
                          ? "voucher-view "
                          : "voucher-view search-button-blue "
                      }${voucher ? " button-search" : " button-disable"}`}
                    >
                      {Object.keys(voucherDetail).length && samePlan ? (
                        <>
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="20" height="20" rx="10" fill="#0583A8" />
                            <path
                              d="M15.6 5.99997L8.60002 14L4.40002 10.3636"
                              stroke="white"
                              stroke-width="1.14039"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          <p>{voucherDetail.benefit}% Off</p>
                        </>
                      ) : (
                        <>
                          {loading ? (
                            <div className="disable button-disable">
                              <div className="loader"></div>
                            </div>
                          ) : (
                            <>
                              {!voucher ? (
                                <p class="disable button-disable">
                                  Apply
                                </p>
                              ) : (
                                <p
                                  onClick={() => checkVoucher()}
                                  class=""
                                >
                                  Apply
                                </p>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
                {errorVoucher && voucher && (
                  <p className="error-voucher">{errorVoucher}</p>
                )}
              </div>
            </>
            }
          </div>
        </div>
        {isMobile && (
          <div className={`button-container ${isMobileOnly ? 'mobile-only' : ''} ${token ? "" : "not-login"}`}>
            {token ?
            <div className={`end-user-container`}>
              <div className="desc-end-user-container">
                <input
                  onClick={() => checkboxInput("newsletter")}
                  type="checkbox"
                  id="subcribe"
                />
                <label className="desc-end-user" for="subcribe">
                  Send me e-mails about product updates, surveys, and offers
                </label>
              </div>
            </div> : null
            }
            {typeof scheme === "undefined" ? (
              <div className="button disable">{token ? "Purchase" : "Please Login or Register first to Checkout"}</div>
            ) : (
              <>
                {typeof method === "undefined" ? (
                  <div className="button disable">{token ? "Purchase" : "Please Login or Register first to Checkout"}</div>
                ) : (
                  <>
                    {loading ? (
                      <div className="button disable">
                        <div className="loader"></div>
                      </div>
                    ) : (
                      <>
                        <div className="button right-button" onClick={() => {
                          CheckoutTracking();
                          if (isFromApp) {
                            let params = ''
                            switch (coin) {
                                case 0:
                                    params = 'coin01'
                                    break;
                            
                                case 1:
                                    params = 'coin06'
                                    break;
                            
                                case 2:
                                    params = 'coin14'
                                    break;
                            
                                default:
                                    break;
                            }
                            window.location = `assemblr://iap?uid=${uid}&productid=com.assemblrworld.${params}&platform=0`
                            setTimeout(() => {
                              window.parent.postMessage("close-scrim", "*");
                              window.parent.postMessage("close popup", "*")
                            }, 1000)
                          } else {
                            sendAPI()
                          }
                          }}>
                            {token ? "Purchase" : "Please Login or Register first to Checkout"}
                            {token ?
                            <div className={`period total-price`}>
                              {Object.keys(voucherDetail).length &&
                              samePlan &&
                              voucherDetail.duration_qty === +customDuration ? (
                                <div className="custom-price-discount">
                                  <p className="final-price price-button">
                                    {planDetail.name === "Blr Coin" && samePlan ?
                                    <>
                                     {method == 1 || method == 2 ? `Rp ${(0).toFixed(2)}` : `$ ${(0).toFixed(2)}`}
                                    </> :
                                    <>
                                      ${" "}
                                      {(
                                        planDetail.price * voucherDetail.duration_qty -
                                        planDetail.price *
                                          voucherDetail.duration_qty *
                                          (voucherDetail.benefit / 100)
                                      ).toFixed(2)}
                                    </>
                                    }
                                  </p>
                                </div>
                              ) : (
                                <>
                                {planDetail.name === "Blr Coin" ?
                                <>
                                  {isFromApp ?
                                    <p className="total">
                                      {method == 1 || method == 2 ? `${currencyFormat((coin === 0 ? coinPlanList[3].priceIAP : coin === 1 ? coinPlanList[4].priceIAP : coinPlanList[5].priceIAP), 'idr')}` : `${currencyFormat((coin === 0 ? coinPlanList[3].priceIAP : coin === 1 ? coinPlanList[4].priceIAP : coinPlanList[5].priceIAP), 'usd')}`}
                                    </p> :
                                    <p className="total">
                                      {method == 1 || method == 2 ? `${currencyFormat((coin === 0 ? coinPlanList[0].priceMidtrans : coin === 1 ? coinPlanList[1].priceMidtrans : coinPlanList[2].priceMidtrans), 'idr')}` : `${currencyFormat((coin === 0 ? coinPlanList[0].priceStripe : coin === 1 ? coinPlanList[1].priceStripe : coinPlanList[2].priceStripe), 'usd')}`}
                                    </p>
                                  }
                                </> :
                                <p className="total">
                                  {samePlan ?
                                  <>
                                    ${" "}{`${(0).toFixed(2)}`}
                                  </> :
                                  <>
                                    ${" "}{`${(+total).toFixed(2)}`}
                                  </>
                                  }
                                </p>
                                }
                                </>
                              )}
                            </div> : null
                            }
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {token ?
            <p className="default-button-desc">By pressing the button above, you’re agree to <a href="https://www.assemblrworld.com/pages/eula" target={"_blank"}>Assemblr's terms and conditions</a></p>
            : null
            }
          </div>
        )}
        {!isMobile && (
          <div className="button-container">
            {token ?
            <div className={`end-user-container`}>
              <div className="desc-end-user-container">
                <input
                  onClick={() => checkboxInput("newsletter")}
                  type="checkbox"
                  id="subcribe"
                />
                <label className="desc-end-user" for="subcribe">
                  Send me e-mails about product updates, surveys, and offers
                </label>
              </div>
            </div> : null
            }
            {typeof scheme === "undefined" ? (
              <div className="button disable">{token ? "Purchase" : "Please Login or Register first to Checkout"}</div>
            ) : (
              <>
                {typeof method === "undefined" ? (
                  <div className="button disable">{token ? "Purchase" : "Please Login or Register first to Checkout"}</div>
                ) : (
                  <>
                    {loading ? (
                      <div className="button disable">
                        <div className="loader"></div>
                      </div>
                    ) : (
                      <>
                        <div className="button right-button" onClick={() => {
                            localStorage.removeItem("order_id")
                            sendAPI()
                            if (navigate.userAgent.toLowerCase().includes("assemblr")) {
                              window.parent.postMessage("close popup", "*");
                              document.getElementById("show-after-purchase").click()
                            } 
                          }}>
                            {token ? "Purchase" : "Please Login or Register first to Checkout"}
                            {token ?
                            <div className={`period total-price`}>
                              {Object.keys(voucherDetail).length &&
                              samePlan &&
                              voucherDetail.duration_qty === +customDuration ? (
                                <div className="custom-price-discount">
                                  <p className="final-price price-button">
                                    ${" "}
                                    {(
                                      planDetail.price * voucherDetail.duration_qty -
                                      planDetail.price *
                                        voucherDetail.duration_qty *
                                        (voucherDetail.benefit / 100)
                                    ).toFixed(2)}
                                  </p>
                                </div>
                              ) : (
                                <>
                                {planDetail.name === "Blr Coin" ?
                                <>
                                  {isFromApp ?
                                    <p className="total">
                                      {method == 1 || method == 2 ? `${currencyFormat((coin === 0 ? coinPlanList[3].priceIAP : coin === 1 ? coinPlanList[4].priceIAP : coinPlanList[5].priceIAP), 'idr')}` : `${currencyFormat((coin === 0 ? coinPlanList[3].priceIAP : coin === 1 ? coinPlanList[4].priceIAP : coinPlanList[5].priceIAP), 'usd')}`}
                                    </p> :
                                    <p className="total">
                                      {method == 1 || method == 2 ? `${currencyFormat((coin === 0 ? coinPlanList[0].priceMidtrans : coin === 1 ? coinPlanList[1].priceMidtrans : coinPlanList[2].priceMidtrans), 'idr')}` : `${currencyFormat((coin === 0 ? coinPlanList[0].priceStripe : coin === 1 ? coinPlanList[1].priceStripe : coinPlanList[2].priceStripe), 'usd')}`}
                                    </p>
                                  }
                                </> :
                                <p className="total">
                                  ${" "}{`${(+total).toFixed(2)}`}
                                </p>
                                }
                                </>
                              )}
                            </div> : null
                            }
                        </div>
                      </>
                    )}
                  </>
                )}
              </>
            )}
            {token ?
            <p className="default-button-desc">By pressing the button above, you’re agree to <a href="https://www.assemblrworld.com/pages/eula" target={"_blank"}>Assemblr's terms and conditions</a></p>
            : null
            }
          </div>
        )}
        {popup && (
          <div className="popup">
            <div className="popupContent">
              <div className="popupHeader">
                <span className="material-icons">info</span>
                <div className="error">
                  <p>{popup}</p>
                </div>
              </div>
              <div className="switch">
                <p
                  className="confirm"
                  onClick={() => {
                    setPopup("");
                  }}
                >
                  Try Again
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default NewCheckout;
