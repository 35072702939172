/* eslint-disable react-hooks/exhaustive-deps */
import './index.scss';
import { useState, useEffect, useCallback, Fragment } from 'react';
import {IconFree, IconBasic, IconMedium, IconPremium, IconIndividual, IconSchool, IconTicket, IconAdd, IconGreenCheck} from '../../icons';
import { useLocation, useNavigate } from 'react-router-dom';
import PLANS from '../../configs/Plans.js';
import { useMediaQuery } from 'react-responsive';
import instance from '../../configs/axios';
import queryString from 'query-string'
import jwt_decode from "jwt-decode";
import axios from 'axios';

function Payment() {
    const isMobile = useMediaQuery({maxWidth : 760});
    const isDesktop = useMediaQuery({minWidth : 1224});
    const initTab = useLocation()
    const [showTab, setShowTab] = useState()
    const [planSlider, setPlanSlider] = useState(0)
    const [customMarker, setCustomMarker] = useState(0)
    const [usage, setUsage] = useState({
        projects: 0,
        markerUse: 0,
        markerLimit: 10,
        customUse: '0MB',
        customLimit: '1120MB'
    })
    const [plans, setPlans] = useState()
    const [activePlans, setActivePlans] = useState('FREE')
    const [edu, setEdu] = useState()
    const [embed, setEmbed] = useState(0)
    const [embedEdu, setEmbedEdu] = useState(0)
    const [token, setToken] =useState('')
    const [assemblrApp, setAssemblrApp] = useState(false)
    const [uid, setUID] = useState('')
    const [until, setUntil] = useState('')
    const [params, setParams] = useState()
    const [barCustom, setBarCustom] = useState('')
    const [barMarker, setBarMarker] = useState('')
    const [loading, setLoading] = useState(false)
    const [popup, setPopup] = useState(false)
    const [, updateState] = useState()
	const [selectedPlan, setSelectedPlan] = useState({ id: 2, params: 'basic001month', data: PLANS['2'] })
    const [selectedEdu, setSelectedEdu] = useState({ id: 21, params: 'individual1month', data: PLANS['21'] })
	let navigate = useNavigate()
    let profile = [
        {},
        {},
        {},
        {}
    ]

    let arMarkerDescription = [
        {
            title: '1 Custom Markers',
            price: '$2.99',
            contents: [
                'One-Time Purchase',
                'No Expiration Date'
            ],
            qty: 1
        },
        {
            title: '5 Custom Markers',
            price: '$13.99',
            contents: [
                'One-Time Purchase',
                'No Expiration Date'
            ],
            qty: 5
        },
        {
            title: '10 Custom Markers',
            price: '$24.99',
            contents: [
                'One-Time Purchase',
                'No Expiration Date'
            ],
            qty: 10
        },
    ]

    const changeCustomMarker = (type) => {
        if (type) {
            setCustomMarker(+customMarker + 1)
        } else if (!type && customMarker) {
            setCustomMarker(+customMarker - 1)
        }
    }

    const ViewPlanListTracking = (payload) => {
        let planList = Object.keys(PLANS).map(item => {
            if (((PLANS[item].plan === 'edu') && PLANS[item].id <= 100) && payload === 'plan' && embedEdu) {
                return PLANS[item]
            }
            if (((PLANS[item].plan === 'personal' || PLANS[item].plan === 'edu') && PLANS[item].id <= 100) && payload === 'plan' && !embedEdu) {
                return PLANS[item]
            }
            if ((PLANS[item].plan === 'marker' && PLANS[item].id <= 100) && payload === 'marker') {
                return PLANS[item]
            }
        })
        planList = planList.filter(item => typeof item !== 'undefined')
        window.dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        window.dataLayer.push({
            event: "view_item_list",
            ecommerce: {
                item_list_id: payload === 'plan' ? "plan_list" : "marker_list",
                item_list_name: payload === 'plan' ? "Plan List" : "Marker List",
                items: planList
            }
        });
    }

    const forceUpdate = useCallback(() => updateState({}), [])

    const fetchFirebaseLambda = async (uid) => {
        try {
            let projects = await axios.get(`https://sj44aza183.execute-api.ap-southeast-1.amazonaws.com/default/assemblr-lambda-api?id=${uid}`, {})
            
            let markerUse = await axios.get(`https://sj44aza183.execute-api.ap-southeast-1.amazonaws.com/default/assemblr-marker-api?id=${uid}`, {})
            
            let result = {
                projects: projects.data.result,
                markerUse: markerUse.data.result_marker_count
            }

            return result
        } catch (error) {
            fetchFirebaseLambda(uid)
        }
    }

    const fetchAPI = async () => {
        try {
            const queryParams = queryString.parse(window.location.search)
            setParams(queryString.parse(window.location.search))
            setLoading(true)
            if (queryParams.uid) {
                let { uid } = queryParams
                let tempPayload = {
                    projects: 0,
                    markerUse: 0,
                    markerLimit: 10,
                    customUse: '0MB',
                    customLimit: '1120MB'
                }
                let apiData = await instance.get(`api/features/total/${uid}`, {})
                tempPayload.markerLimit = apiData.data.marker
                
                let activePlan = await instance.get(`api/features/active/${uid}`, {})
                setActivePlans(activePlan.data.active_plan.plans_name)
                setUntil(activePlan.data.active_plan.expire_date)
                setUID(activePlan.data.uid)
    
                let lambdaResult = await fetchFirebaseLambda(uid)
                if (Object.keys(lambdaResult).length) {
                    tempPayload.projects = lambdaResult.projects
                    tempPayload.markerUse = lambdaResult.markerUse
                    let customBar = `${((tempPayload.customUse/1000000).toFixed(2))/(tempPayload.customLimit/1000000) * 100}%`
                    let customMarker = `${tempPayload.markerUse/tempPayload.markerLimit * 100}%`
                    setUsage(tempPayload)
                    setBarCustom(customBar)
                    setBarMarker(customMarker)
                    setTimeout(() => {
                        forceUpdate()
                        setLoading(false)
                        tempPayload.customUse = `${(apiData.data.quota_used/1000000).toFixed(2)} MB`
                        tempPayload.customLimit = `${apiData.data.quota/1000000} MB`
                        document.getElementById('custom-progress-bar').style.width = customBar
                        document.getElementById('marker-progress-bar').style.width = customMarker
                    }, 100)
                }
            }
    
            if (queryParams.embed === 'true') {
                setEmbed(1)
            }
    
            if (queryParams.edu === 'true') {
                setEmbedEdu(1)
            }
    
            if (queryParams.token) {
                setToken(queryParams.token)
                setUID(jwt_decode(queryParams.token).user_id)
                localStorage.setItem("UUID", jwt_decode(queryParams.token).user_id)
            }

            if (queryParams.hide_close === 'true') {
                setTimeout(() => {
                    window.closeButton = true
                }, 2000)
            }

            if (queryParams.type === 'astu') {
                window.dataLayer.push({
                    open_from: "https://studio.assemblrworld.com"
                })
            } else {
                window.dataLayer.push({
                    open_from: "https://app-edu.assemblrworld.com"
                })
            }
            
        } catch (error) {
            setPopup(true)
        }
    }

    const handlePlanSlider = () => {
        if (planSlider) {
            setPlanSlider(0)
        } else {
            setPlanSlider(1)
        }
    }

    const AssemblrPlanOption = () => {
		const list = plans?.filter(v => v.name !== 'FREE') || []
		const selected = plans.find(v => v.id === selectedPlan.id)
		const isAnnual = (selected.type === 'annually')

		return list.length > 0 ? (
		<Fragment>
			<div className='plan-wrapper'>
				{list.map((item, i) => (
					<div 
						className={`tab-option ${(selectedPlan.id === item.id) ? item.name.toLowerCase() + ' selected' : '' }`} 
						key={i} 
						onClick={() => setSelectedPlan({
							id: item.id,
							params: `${item.name.toLowerCase()}001month`,
							data: item
							})}
					>
						<div className='icon-wrapper'>
							{item.name === 'FREE' && <IconFree className='image' />}
							{item.name === 'BASIC' && <IconBasic className='image' />}
							{item.name === 'MEDIUM' && <IconMedium className='image' />}
							{item.name === 'PREMIUM' && <IconPremium className='image' />}
						</div>
						<p className='name'>{item.name[0] + item.name.slice(1).toLowerCase()}</p>
						{ item.type === 'annually' 
							? <p className="price">${item.annually_price}</p>
							: <p className="price">${item.price}</p>
						}
					</div>
				))}
			</div>
			<div className={`tab-desc ${selected?.name.toLowerCase()}`} >
			{selected?.contents.map((content, index) => (
                <div key={index} className='desc-wrapper'>
					<div className='circle'><IconGreenCheck /></div>
                    <div className='description'>
					<strong>{content[0]}</strong>
                    {content[1]}
                    </div>
                </div>
            ))}
			</div>
			<div className='sticky-button'>
				<div 
					className='button'
					onClick={() => {
                        if (assemblrApp) {
                            window.location = `assemblr://iap?uid=${uid}&productid=com.assemblrworld.subscription.${selectedPlan.params}&platform=0&redirect_uri=/myplan`
                        } else {
                            navigate(`/NewCheckout/${selected.month_id}?token=${params.token}&uid=${uid}`)
                        }
                    }}
				>
					<span>Subscribe to <strong>{selected.name[0] + selected.name.slice(1).toLowerCase()}</strong> plan</span>
					<span>${isAnnual ? selected.annually_price : selected.price} / {isAnnual ? 'Year' : 'Month'}</span>
				</div>
			</div>
		</Fragment>
	) : null}

	const EduPlanOption = () => {
		const selected = edu.find(v => v.id === selectedEdu.id)
        const isAnnual = (selected.type === 'annually')

		return edu.length > 0 ? (
		<Fragment>
			<div className='plan-wrapper'>
				{edu.map((item, i) => (
					<div 
						className={`tab-option ${(selectedEdu.id === item.id) ? (item.name.toLowerCase().replace('/', '-')) + ' selected' : '' }`} 
						key={i} 
						onClick={() => {
                            setSelectedEdu({
                                id: item.id,
                                params: item.name === 'Individual' ? 'individual1month' : '',
                                data: item
							})
                            if (item.name === 'School/Institutional') setPlanSlider(1)
                        }}
					>
						<div className='icon-wrapper'>
							{item.name === 'Individual' && <IconIndividual className='imageEdu'/>}
							{item.name === 'School/Institutional' && <IconSchool className='imageEdu'/>}
						</div>
						<p className='name'>{item.name}</p>
						<p className="price">{item.name === 'School/Institutional' && 'From'} ${(item.type === 'annually') ? item.annually_price : item.priceEachMonth}</p>
						<p className={`${ item.name === 'Individual' ? 'note-2' : 'hidden' }`}>Per {(item.type === 'annually') ? "Year" : "Month"}</p>
						<p className={item.name === 'Individual' ? 'hidden' : ''}>Per Seat</p>
                        <p className={(item.name !== 'Individual' && item.type === 'annually' && selectedEdu.id !== item.id) ? 'warn-text' : 'hidden'}>Only Available Yearly</p>
					</div>
				))}
			</div>
			<div className={`tab-desc ${selected?.name.toLowerCase().replace('/', '-')}`} >
			{selected?.contents.map((content, index) => (
                <div key={index} className='desc-wrapper'>
					<div className='circle'><IconGreenCheck /></div>
                    <div className='description'>
					<strong>{content[0]}</strong>
                    {content[1]}
                    </div>
                </div>
            ))}
			</div>
            <div className='sticky-button'>
				<div 
					className='button'
					onClick={() => {
						if (assemblrApp) {
							window.location = `assemblr://iap?uid=${uid}&productid=com.assemblrworld.subscription.${selectedEdu.params}&platform=0&redirect_uri=/myplan`
						} else {
							if ((!planSlider && selected.name === 'School/Institutional') === false) {
								const url = embedEdu ? `/NewCheckout/${selected.month_id}?embed=false&edu=true&uid=${uid}&token=${params.token}&type=edu` : `/NewCheckout/${selected.month_id}?embed=false&uid=${uid}&token=${params.token}&type=edu`
								navigate(url)
							}
						}
					}}
				>
					<span>Subscribe to <strong>{selected.name[0] + selected.name.slice(1).toLowerCase()}</strong> plan</span>
					<span>${isAnnual ? selected.annually_price : selected.price} / {isAnnual ? 'Year' : 'Month'}</span>
				</div>
			</div>
		</Fragment>
	) : null}

    useEffect(() => {
        window.scrollTo(0, 0)
        window.dataLayer = window.dataLayer || [];
        if(navigator.userAgent.toLowerCase().includes('assemblr')) setAssemblrApp(true)
        fetchAPI()
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
        switch (initTab.pathname) {
            case '/Overview':
                setLoading(true)
                setShowTab(0)
                setTimeout(() => {
                    setLoading(false)
                    document.getElementById('custom-progress-bar').style.width = barCustom
                    document.getElementById('marker-progress-bar').style.width = barMarker
                }, 5000)
                break;
        
            case '/Plan':
                setLoading(true)
                setShowTab(1)
                ViewPlanListTracking('plan')
                setTimeout(() => {
                    setLoading(false)
                }, 4000);
                break;
        
            case '/Marker':
                setLoading(true)
                setShowTab(2)
                setTimeout(() => {
                    setLoading(false)
                }, 4000);
                
                ViewPlanListTracking('marker')
                break;

            default:
                break;
        }
    }, [initTab.pathname])
    
    useEffect(() => {
        if (assemblrApp) {
            setPlans([
                PLANS['2'],
                PLANS['3'],
                PLANS['4'],
            ])
            setEdu([])
        } else {
            if (!planSlider) {
                setPlans([
                    PLANS['1'],
                    PLANS['2'],
                    PLANS['3'],
                    PLANS['4'],
                ])
                setEdu([
                    PLANS['17'],
                    PLANS['100'],
                ])
            } else {
                setPlans([
                    PLANS['5'],
                    PLANS['6'],
                    PLANS['7'],
                    PLANS['8'],
                ])
                setEdu([
                    PLANS['21'],
                    PLANS['100'],
                ])
            }
            
        }
		if (planSlider) {
			setSelectedPlan({ id: 6, params: 'basic001month', data: PLANS['6'] })
			if (selectedEdu.id !== 100) setSelectedEdu({ id: 21, params: 'individual1month', data: PLANS['21'] })
		}
		else {
			setSelectedPlan({ id: 2, params: 'basic001month', data: PLANS['2'] })
			setSelectedEdu({ id: 17, params: 'individual1month', data: PLANS['17'] })
		}
    }, [planSlider, assemblrApp])

    console.log({ embedEdu, assemblrApp })

    return (
        <>
        {loading ?
            <div className='loading-container'>
                <div className='loader2'></div> 
            </div> :
            <div className={`Main ${!isMobile ? 'tablet-overview ' : ''}${showTab === 0 ? 'overview' : ''}${showTab === 2 ? 'arMarker' : ''}`}>
                <div className='nav'>
                    <div className={`header ${assemblrApp ? '' : ''}`}>
                        {window.closeButton !== true &&
                        <span onClick={() => {
                            window.parent.postMessage("close popup", "*")
                        }}
                        className='material-icons back'>
                            close
                        </span>
                        }
                        {!assemblrApp &&
                        <>
                        <p className='headerTitle'>
                            Upgrade Plan
                        </p>
                        {/* {isMobile &&
                        <div className='redeemCode'>
                            <IconTicket onClick={() => {
                                navigate(`/Redeem?token=${params.token}`)
                            }} className='ticket'/>
                        </div>
                        } */}
                        </>
                        }
                    </div>
                    {!embed &&
                    <div className={`tabSwitch ${!isMobile ? 'tablet' : ''}`}>
                        <p
                        onClick = {() => {
                            navigate('/Overview')
                            setShowTab(0)
                        }}
                        className={`${showTab === 0 ? "active" : ""} ${!isMobile ? 'tablet' : ''}`}>
                            Overview
                        </p>
                        <p
                        onClick = {() => {
                            navigate('/Plan')
                            setShowTab(1)
                        }}
                        className={`${showTab === 1 ? "active-plan plan" : "plan"} ${!isMobile ? 'tablet-plan' : ''}`}>
                            Plan
                        </p>
                        <p
                        onClick = {() => {
                            navigate('/Marker')
                            setShowTab(2)
                        }}
                        className={`${showTab === 2 ? "active-marker" : ""} ${!isMobile ? 'tablet' : ''}`}>
                            AR Marker
                        </p>
                        {!isMobile &&
                        <p
                        onClick = {() => {
                            navigate(`/Redeem?token=${params.token}`)
                        }}
                        className={`${showTab === 2 ? "active-redeem" : ""} ${!isMobile ? 'tablet' : ''}`}>
                            Redeem
                        </p>
                        }
                    </div>
                    }
                </div>
                { !showTab && // Overview Tab
                <>
                    <div className={`payHeader ${!isMobile ? 'tablet' : ''} ${activePlans !== 'FREE' ? 'paymentWaiting' : ''}`}>
                        {(activePlans === 'FREE' && !isMobile) &&
                        <IconFree className='image-overview' />
                        }
                        {(activePlans === 'BASIC' && !isMobile) &&
                        <IconBasic className='image-overview' />
                        }
                        {(activePlans === 'MEDIUM' && !isMobile) &&
                        <IconMedium className='image-overview' />
                        }
                        {(activePlans === 'PREMIUM' && !isMobile) &&
                        <IconPremium className='image-overview' />
                        }
                        {(activePlans === 'Individual' && !isMobile) &&
                        <IconIndividual className='image-overview' />
                        }
                        {activePlans !== 'FREE' ?
                        <>
                            <div>
                                <p className='first'>
                                    You active plan
                                </p>
                                <p className='second'>
                                    {activePlans} Plan
                                </p>
                            </div>
                            <div>
                                <p className='first left'>
                                    Until
                                </p>
                                <p className='second left'>
                                    {until}
                                </p>
                            </div>
                        </> :
                        <>
                            <div>
                                <p className='first'>
                                    You are now still on
                                </p>
                                <p className='second'>
                                    Free Plan
                                </p>
                            </div>
                            <p onClick={() => {
                                navigate('/Plan')
                            }} className="button">
                                Upgrade
                            </p>
                        </>
                        }
                    </div>
                    <div className='overviewContent'>
                        <div className={`bodyOverview ${!isMobile ? 'tablet' : ''} ${isDesktop ? 'desktop' : ''}`}>
                            {(isMobile && !isDesktop) &&
                            <>
                                <div className='bodyCard leftCard'>
                                    <h3 className='leftCard'>
                                        {usage.projects}
                                    </h3>
                                    <p className='leftCard'>
                                        Projects Created
                                    </p>
                                </div>
                                <div className='bodyCard rightCard'>
                                    <div>
                                        <h3 className='rightCard'>
                                            {usage.markerUse}/{usage.markerLimit}
                                        </h3>
                                        <p className='rightCard'>
                                            AR Marker Usage
                                        </p>
                                    </div>
                                    <div>
                                        <h3 className='rightCard'>
                                            {usage.customUse} / {usage.customLimit}
                                        </h3>
                                        <p className='rightCard'>
                                            Custom 3D Object
                                        </p>
                                    </div>
                                </div>
                            </>
                            }
                            {(!isMobile && !isDesktop) &&
                            <>
                                <div className='bodyCard leftCard tablet'>
                                    <div>
                                        <h3 className='leftCard'>
                                            {usage.projects}
                                        </h3>
                                        <p className='leftCard'>
                                            Projects Created
                                        </p>
                                    </div>
                                </div>
                                <div className='bodyCard leftCard tablet'>
                                    <div>
                                        <IconAdd onClick={() => {
                                            navigate('/Marker')
                                        }}
                                        className='icon' />
                                        <h3 className='leftCard'>
                                            {usage.markerUse}/{usage.markerLimit}
                                        </h3>
                                        <p className='leftCard'>
                                            AR Marker Usage
                                        </p>
                                        <div className={`progress-border ${!isMobile ? 'tablet' : ''}`}>
                                            <div id='marker-progress-bar' className='progress-bar'></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bodyCard leftCard tablet'>
                                    <div>
                                        <h3 className='leftCard tablet custom'>
                                            {usage.customUse} / {usage.customLimit}
                                        </h3>
                                        <p className='leftCard'>
                                            Custom 3D Object
                                        </p>
                                        <div className={`progress-border ${!isMobile ? 'tablet' : ''}`}>
                                            <div id='custom-progress-bar' className='progress-bar'></div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            }
                            {(!isMobile && isDesktop) &&
                            <>
                                <div className='bodyCard leftCard tablet'>
                                    <div>
                                        <h3 className='leftCard'>
                                            {usage.projects}
                                        </h3>
                                        <p className='leftCard'>
                                            Projects Created
                                        </p>
                                    </div>
                                </div>
                                <div className='bodyCard leftCard tablet'>
                                    <div>
                                        <IconAdd onClick={() => {
                                            navigate('/Marker')
                                        }}
                                        className='icon' />
                                        <h3 className='leftCard'>
                                            {usage.markerUse}/{usage.markerLimit}
                                        </h3>
                                        <p className='leftCard'>
                                            AR Marker Usage
                                        </p>
                                        <div className={`progress-border ${!isMobile ? 'tablet' : ''}`}>
                                            <div id='marker-progress-bar' className='progress-bar'></div>
                                        </div>
                                    </div>
                                </div>
                                <div className='bodyCard leftCard tablet'>
                                    <div>
                                        <h3 className='leftCard tablet custom'>
                                            {usage.customUse} / {usage.customLimit}
                                        </h3>
                                        <p className='leftCard'>
                                            Custom 3D Object
                                        </p>
                                        <div className={`progress-border ${!isMobile ? 'tablet' : ''}`}>
                                            <div id='custom-progress-bar' className='progress-bar'></div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            }
                        </div>
                        {edu === 3 &&
                        <div className='bodyOverview seats'>
                            <div className='bodyCard seats'>
                                <div className='seatsHeader'>
                                    <p className='seats'>Manage Seat</p>
                                    <div className='seatsInfo'>
                                        <div className='profile'>
                                        {profile.map((item, i) => {
                                            return <div key={i} className='circle'></div>
                                        })}
                                        </div>
                                        <p className='seatsAvail'>65/80 people</p>
                                        <div onClick={() => {
                                            navigate('/Seat')
                                            }} className='goToDetail'>
                                            <span className="material-icons">
                                                arrow_forward_ios
                                            </span>
                                        </div>
                                    </div>
                                    {/* <div></div> */}
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </>
                }
                { showTab === 1 && // Plan Tab
                    <>
                    <div className='sliderSection'>
                        {/* <h1 className="mainText">Choose the plan that fits you best</h1> */}
                        {!assemblrApp &&
                        <Fragment>
                        <div className="planSlider">
                            <p className={planSlider === 0 ? "active" : ""} onClick={handlePlanSlider}>Monthly</p>
                            <p className={planSlider === 1 ? "active" : ""} onClick={handlePlanSlider}>Yearly</p>
                            <div className={planSlider === 0 ? "monthly slider" : "annually slider"} />
                        </div>
                        <div className='slider-badge'>2 months free</div>
                        </Fragment>
                        }
                    </div>
                        {!embedEdu &&
                        <>
                            <div 
								className={`cardWrapper plan cardflex ${(!isMobile && !isDesktop) ? 'tablet' : ''} ${isDesktop ? 'tablet' : ''}`}
							>
                                <AssemblrPlanOption />
                            </div>
                        </>
                        }
                        {}
                        <>
                        <div className={`cardWrapper plan cardflex ${(!isMobile && !isDesktop) ? 'edu-tablet' : ''} ${isDesktop ? 'edu-tablet' : ''}`} style={{ marginBottom: '6rem' }}>
                        {(!assemblrApp && embedEdu) ? <EduPlanOption /> : assemblrApp ? 
                            <div className={`card eduCardIn`}>
                                <div className='cardBody plans'>
                                    <IconIndividual className='imageEdu'/>
                                    <p className="title">{PLANS[17].name}</p>
                                    <p className={`price eduPrice ind`}>${(PLANS[17].type === 'annually' && PLANS[17].name === 'Individual') ? PLANS[17].annually_price : PLANS[17].priceEachMonth}</p>
                                    <p className={`${ PLANS[17].name === 'Individual' ? 'note-2' : 'note' }`}>Per {(PLANS[17].type === 'annually' && PLANS[17].name === 'Individual') ? "Year" : "Month"}</p>
                                    { PLANS[17].contents.map((content, index) => {
                                        return <div key={index}>
                                            <div className='circle edu'></div>
                                            <div className='description'>
                                                <strong>{content[0]}</strong>
                                                {content[1]}
                                            </div>
                                        </div>
                                    })}
                                </div>
                                <div onClick={() => {
                                    window.location = `assemblredu://iap?uid=${uid}&productid=com.assemblr.education.subscription.individual1month&platform=1&redirect_uri=/myplan`
                                }}
                                className={`button eduCardIn`}>
                                    Buy Plan
                                </div>
                            </div> : null }
                        </div>
                        </>
                    </>
                }
                { showTab === 2 && // AR Marker Tab
                    <>
                        <div className={`cardWrapper arMarker ${(!isMobile && !isDesktop) ? 'edu-tablet' : ''} ${isDesktop ? 'edu-tablet' : ''}`}>
                            {arMarkerDescription.map((item, i) => {
                                return <div className="card" key={i}>
                                    <div className='cardBody plans'>
                                        <p className="title arMarker">{item.title}</p>
                                        <p className="price arMarker">{item.price}</p>
                                        { item.contents.map((content, index) => {
                                            return <div key={index}>
                                                <div className='circle plan'></div>
                                                <div className='description'>
                                                    {content}
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                    <div onClick={() => {
                                        if (assemblrApp) {
                                            window.parent.postMessage("close popup", "*")
                                            let params = ''
                                            switch (item.qty) {
                                                case 1:
                                                    params = '01'
                                                    break;
                                            
                                                case 5:
                                                    params = '05'
                                                    break;
                                            
                                                case 10:
                                                    params = '10'
                                                    break;
                                            
                                                default:
                                                    break;
                                            }
                                            const queryParams = queryString.parse(window.location.search)
                                            let redirect_uri = ''
                                            if (queryParams.back_url === '/profile/markers') {
                                                redirect_uri = queryParams.back_url
                                            } else {
                                                redirect_uri = 'library3d/actived'
                                            }
                                            window.location = `assemblr://iap?uid=${uid}&productid=com.assemblrworld.marker${params}&platform=0&redirect_uri=${redirect_uri}`
                                        } else {
                                            navigate(`/Checkout/20?count=${item.qty}&token=${params.token}&uid=${uid}`)
                                        }
                                    }} className={`button arMarker`}>
                                        Buy
                                    </div>
                                </div>
                            })}
                            {!assemblrApp &&
                            <div className="card">
                                <div className='cardBody plans'>
                                    <p className="title arMarker">Custom Your Purchase</p>
                                    <p className="price arMarker">${customMarker * 3}</p>
                                    <div className='containerInput'>
                                        <span onClick={() => changeCustomMarker(0)} className="material-icons">
                                            remove
                                        </span>
                                        <input value={customMarker} className='inputMarker' placeholder='0' onChange={e => setCustomMarker(e.target.value)} min={0} type="number" name="seats" />
                                        <span onClick={() => changeCustomMarker(1)} className="material-icons">
                                            add
                                        </span>
                                    </div>
                                </div>
                                <div onClick={() => {
                                    navigate(`/Checkout/20?count=${customMarker}&token=${params.token}&uid=${uid}`)
                                }} className={`button arMarker ${!isMobile ? 'freeTablet' : ''}`}>
                                    Buy
                                </div>
                            </div>
                            }
                        </div>
                    </>
                }
            </div>
        }
        {/* {popup &&
        <div className='popup'>
            <div className='popupContent popup-retry'>
                <span onClick={() => {
                    window.parent.postMessage("close popup", "*")
                }} className="material-icons close-button-retry">
                    close
                </span>
                <div className='popupHeader'>
                    <span className="material-icons">
                        info
                    </span>
                    <div className='error'>
                    <img src={process.env.PUBLIC_URL+'/assets/Failedblr.png'} className='image-retina popup-image'/>
                        <p>
                        There something wrong...
                        </p>
                    </div>
                </div>
                <div className='switch'>
                    <p className='confirm' onClick={() => {
                        setPopup(false)
                        fetchAPI()
                        }}>
                        Retry
                    </p>
                </div>
            </div>
        </div>
        } */}
        </>
    )
}

export default Payment;